import Vue from 'vue';
import ConsentBannerIframe from '~/components/common/cookies/consent-banner-iframe';

export default (context, inject) => {
  const cookies = {
    modal: false,
    consent: false,
    enabled: [],
    enabledList: [],
    necessary: [
      {
        name: {
          ar: 'ملفات تعريف الارتباط الافتراضية',
          zh: '默认 Cookie',
          en: 'Default Cookies',
          es: 'Cookies predeterminadas',
          it: 'Cookie predefiniti',
          fr: 'Cookies par défaut',
          ru: 'Файлы cookie по умолчанию',
          nl: 'Standaard cookies'
        },
        description: {
          ar: 'تستخدم لموافقة التحكم في ملفات تعريف الارتباط',
          zh: '用于 cookie 控制同意',
          en: 'Used for cookie control consent.',
          es: 'Utilizado para el consentimiento del control de cookies',
          it: 'Utilizzato per il consenso al controllo dei cookie',
          fr: 'Utilisé pour le consentement au contrôle des cookies',
          ru: 'Используется для согласия на управление файлами cookie',
          nl: 'Gebruikt voor toestemming voor cookiebeheer'
        },
        identifier: 'functional',
        layout: ['gouv', 'troov']
        // cookies: ['cc_consent', 'cc_enabled_cookies']
      },
      {
        name: {
          ar: 'رمز CSRF',
          zh: 'CSRF 代币',
          en: 'CSRF Token',
          es: 'Token CSRF',
          it: 'Token CSRF',
          fr: 'Jeton CSRF',
          ru: 'Токен CSRF',
          nl: 'CSRF-token'
        },
        description: {
          ar: "رمز CSRF's Token Troov لحماية CSRF. التزوير عبر الموقع ، وهو اختصار لـ CSRF (يُطلق عليه أحيانًا تصفح البحر) أو XSRF ، هو نوع من الثغرات الأمنية في خدمات مصادقة الويب.",
          zh: 'CSRF 的 Token Troov 用于 CSRF 保护。 跨站点请求伪造，CSRF（有时发音为 sea-surfing）或 XSRF 的缩写，是 Web 身份验证服务中的一种漏洞。',
          en: "CSRF's Token Troov for CSRF protection. Cross-Site Request Forgery, short for CSRF (sometimes pronounced sea-surfing) or XSRF, is a type of vulnerability in web authentication services.",
          es: 'Token Troov de CSRF para protección CSRF. La falsificación de solicitudes entre sitios, abreviatura de CSRF (a veces pronunciada navegación en el mar) o XSRF, es un tipo de vulnerabilidad en los servicios de autenticación web.',
          it: 'Token Troov di CSRF per la protezione CSRF. Cross-Site Request Forgery, abbreviazione di CSRF (a volte pronunciato sea-surfing) o XSRF, è un tipo di vulnerabilità nei servizi di autenticazione web.',
          fr: "Token Troov pour protection anti CSRF. Cross-Site Request Forgery, abrégé CSRF (parfois prononcé sea-surfing en anglais) ou XSRF, est un type de vulnérabilité des services d'authentification web.",
          ru: 'Token Troov от CSRF для защиты CSRF. Подделка межсайтовых запросов, сокращение от CSRF (иногда произносится как морской серфинг) или XSRF, представляет собой тип уязвимости в службах веб-аутентификации.',
          nl: "CSRF's Token Troov voor CSRF-bescherming. Cross-Site Request Forgery, een afkorting van CSRF (soms uitgesproken als sea-surfing) of XSRF, is een type kwetsbaarheid in webauthenticatieservices."
        },
        identifier: 'functional',
        layout: ['gouv', 'troov']
      }
    ],
    optional: [
      {
        name: {
          ar: 'التخصيص',
          zh: '定制化',
          en: 'Customisation',
          es: 'Personalización',
          it: 'Personalizzazione',
          fr: 'Personnalisation',
          ru: 'Персонализация',
          nl: 'Maatwerk'
        },
        identifier: 'customisation',
        description: {
          ar: 'نحن نستخدم أدوات التخصيص لتحسين جودة المعلومات والخدمات التي نقدمها على الموقع من أجل تلبية توقعاتك على أفضل وجه.',
          zh: '我们使用个性化工具来提高我们在网站上提供的信息和服务的质量，以便最好地满足您的期望。',
          en: 'We use personalisation tools to improve the quality of the information and services we provide on the site in order to best meet your expectations.',
          es: 'Utilizamos herramientas de personalización para mejorar la calidad de la información y los servicios que ofrecemos en el sitio con el fin de satisfacer mejor sus expectativas.',
          it: 'Utilizziamo strumenti di personalizzazione per migliorare la qualità delle informazioni e dei servizi che forniamo sul sito al fine di soddisfare al meglio le vostre aspettative.',
          fr: 'Nous utilisons des outils de personnalisation dans le but d’améliorer la qualité de l’information et des services que nous fournissons sur le site afin de répondre au mieux à vos attentes.',
          nl: 'We gebruiken personalisatietools om de kwaliteit van de informatie en diensten die we op de site aanbieden te verbeteren om zo goed mogelijk aan uw verwachtingen te voldoen.',
          ru: 'Мы используем инструменты персонализации для улучшения качества информации и услуг, которые мы предоставляем на сайте, чтобы наилучшим образом соответствовать вашим ожиданиям.'
        },
        initialState: true,
        async: true,
        accepted: () => {}, // AT internet
        declined: () => {},
        layout: ['gouv', 'troov']
      },
      {
        name: {
          ar: 'تدابير السمع',
          zh: '听力措施',
          en: 'Hearing measures',
          es: 'Medidas de audición',
          it: "Misure per l'udito",
          fr: "Mesures d'audience",
          ru: 'Меры слуха',
          nl: 'Publieksmetingen'
        },
        identifier: 'ga',
        description: {
          ar: 'نحن نستخدم أدوات قياس الجمهور لتتبع جميع زوار الموقع لتحسين تجربة المستخدم الخاصة بك.',
          zh: '我们使用受众测量工具来跟踪所有的网站访问者，以改善您的用户体验。',
          en: 'We use audience measurement tools to track all site visitors to improve your user experience.',
          es: 'Utilizamos herramientas de medición de la audiencia para hacer un seguimiento de todos los visitantes del sitio para mejorar su experiencia de usuario.',
          it: 'Utilizziamo strumenti di misurazione del pubblico per monitorare tutti i visitatori del sito per migliorare la vostra esperienza utente.',
          fr: 'Nous utilisons des outils de mesures d’audience afin d’effectuer un suivi de l’ensemble des visiteurs du site pour améliorer votre expérience utilisateur.',
          nl: 'We gebruiken tools voor het meten van het publiek om alle bezoekers van de site te volgen om uw gebruikerservaring te verbeteren.',
          ru: 'Мы используем инструменты измерения аудитории для отслеживания всех посетителей сайта, чтобы улучшить ваш пользовательский опыт.'
        },
        initialState: true,
        src: 'https://www.googletagmanager.com/gtag/js?id=UA-120813374-1',
        async: true,
        accepted: () => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          if (window.self === window.top) {
            window.localStorage.setItem('LinkedInCookiesPref', 1);
          }
        },
        declined: () => {
          if (window.self === window.top) {
            window.localStorage.setItem('LinkedInCookiesPref', 0);
          }
        },
        layout: ['troov', 'gouv']
      },
      {
        name: {
          ar: 'الإعلانات',
          zh: '广告',
          en: 'Advertisements',
          es: 'Anuncios',
          it: 'Annunci',
          fr: 'Publicités',
          ru: 'Объявления',
          nl: 'Adverteren'
        },
        identifier: 'advertisment',
        description: {
          ar: 'تُستخدم ملفات تعريف الارتباط الإعلانية لتزويدك بإعلانات مصممة وفقًا لاهتماماتك على موقعنا أو عند تصفح الإنترنت. من خلال رفض استخدام ملفات تعريف الارتباط الإعلانية ، سيتم عرض الإعلانات غير المستهدفة فقط.',
          zh: '广告cookies用于在我们的网站上或当您浏览互联网时，向您展示根据您的兴趣定制的广告。如果您拒绝使用广告cookies，我们将只显示非目标广告。',
          en: 'Advertising cookies are used to present you with advertisements tailored to your interests on our site or when you browse the Internet. By refusing the use of advertising cookies, only non-targeted advertisements will be displayed.',
          es: 'Las cookies de publicidad se utilizan para presentarle anuncios adaptados a sus intereses en nuestro sitio o cuando navega por Internet. Al rechazar el uso de cookies de publicidad, sólo se mostrarán anuncios no dirigidos.',
          it: "I cookie pubblicitari vengono utilizzati per presentare annunci pubblicitari su misura per i vostri interessi sul nostro sito o quando navigate in Internet. Rifiutando l'uso dei cookie pubblicitari, verranno visualizzati solo annunci pubblicitari non mirati.",
          fr: 'Les cookies publicitaires sont utilisés pour vous présenter des publicités adaptées à vos centres d’intérêts sur notre site ou lors de votre navigation sur Internet. En refusant l’utilisation de Cookies publicitaires, seules les publicités non ciblées pourront s’afficher.',
          nl: 'Advertentiecookies worden gebruikt om u op onze site of wanneer u op internet surft, advertenties te tonen die zijn afgestemd op uw interesses. Door het gebruik van advertentiecookies te weigeren, kunnen alleen niet-gerichte advertenties worden weergegeven.',
          ru: 'Рекламные куки-файлы используются для того, чтобы предоставлять вам рекламные объявления в соответствии с вашими интересами на нашем сайте или при просмотре Интернета. При отказе от использования рекламных cookie-файлов будут отображаться только нецелевые рекламные объявления.'
        },
        initialState: true,
        async: true,
        accepted: () => {},
        declined: () => {},
        layout: ['troov']
      },
      {
        name: {
          ar: 'الشبكات الاجتماعية',
          zh: '社交网络',
          en: 'Social networks',
          es: 'Las redes sociales',
          it: 'I social network',
          fr: 'Réseaux sociaux',
          ru: 'Социальные сети',
          nl: 'Sociale netwerken'
        },
        identifier: 'social_networks',
        description: {
          ar: 'نقدم في الموقع امكانية متابعتنا على مواقع التواصل الاجتماعي لتتمكن من متابعة اخبار الشركة.',
          zh: '我们在网站上提供了在社交网络上关注我们的可能性，这样您就可以关注公司的新闻。',
          en: "We offer on the site the possibility to follow us on social networks so that you can follow the company's news.",
          es: 'Ofrecemos en el sitio la posibilidad de seguirnos en las redes sociales para que puedas seguir las noticias de la compañía.',
          it: "Offriamo sul sito la possibilità di seguirci sui social network in modo da poter seguire le notizie dell'azienda.",
          fr: "Nous proposons sur le site la possibilité de nous suivre sur les réseaux sociaux afin de vous permettre de suivre les actualités de l'entreprise.",
          nl: 'We bieden op de site de mogelijkheid om ons op sociale netwerken te volgen, zodat u het nieuws van het bedrijf kunt volgen.',
          ru: 'Мы предлагаем на сайте возможность следить за нами в социальных сетях, чтобы вы могли следить за новостями компании.'
        },
        initialState: true,
        async: true,
        accepted: () => {
          if (window.self === window.top) {
            window.localStorage.setItem('LinkedInCookiesPref', 1);
          }
        },
        declined: () => {
          if (window.self === window.top) {
            window.localStorage.setItem('LinkedInCookiesPref', 0);
          }
        },
        layout: ['troov']
      }
    ]
  };

  const methods = {
    get: (cookie) => {
      if (process.browser) {
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        const name = `${cookie}=`;
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
      }
      return '';
    },
    set: ({ name, value = '', expires = '', path = '/', domain }) => {
      const domainName = domain || (cookies.domain ? `.${cookies.domain}` : domain);
      if (process.browser) {
        document.cookie = `${name}=${value};expires=${expires};path=${path}${domainName !== undefined ? `;domain=${domainName}` : ';'}`;
      } else if (process.server) {
        context.res.setHeader('Set-Cookie', [
          `${name}=${value}; Expires=${expires}; Path=${path}${domainName !== undefined ? `; Domain=${domainName}` : ';'}`
        ]);
      }
    },
    isEnabled: (identifier) => {
      return cookies.enabledList.includes(identifier) || cookies.enabledList.includes(cookies.slugify(identifier));
    },
    setBlockedIframes: (content) => {
      const type = (typeof content).toLowerCase();
      let c = type !== 'string' ? JSON.stringify(content) : content;
      c = c.replace(/&lt;/g, '<');
      c = c.replace(/&gt;/g, '>');
      if (
        context.app.$cookies.enabled.filter((c) => {
          return c.name === 'functional';
        }).length === 0
      ) {
        c = c.replace(/<iframe/g, `<div class='p-4 border-light cc-b-i '`);
        c = c.replace(
          /<\/iframe/g,
          `<p>${
            context.app.$cookies.text.blockedIframe !== undefined ? context.app.$cookies.text.blockedIframe : ''
          } <a href='#' onclick='event.preventDefault(); $${cookies.globalName}.$cookies.modal = true'>${
            context.app.$cookies.text.here !== undefined ? context.app.$cookies.text.here : ''
          }</a></p></div`
        );
      }
      return type !== 'string' ? JSON.parse(c) : c;
    },
    slugify: (str = '') => {
      str = str.replace(/^\s+|\s+$/g, '');
      str = str.toLowerCase();
      const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
      const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
      str = str
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
      return str;
    },
    remove: (name) => {
      if (process.browser) {
        const domain = window.location.hostname;
        cookies.set({ name, expires: 'Thu, 01 Jan 1970 00:00:00 GMT', domain });
        for (let j = domain.split('.'); j.length; ) {
          const o = j.join('.');
          cookies.set({ name, expires: 'Thu, 01 Jan 1970 00:00:00 GMT', domain: `.${o}` });
          j.shift();
        }
      }
    },
    setConsent: (isInit = false) => {
      cookies.consent = cookies.get('cc_consent') === 'true';
      cookies.enabled = [];
      cookies.enabledList = [];
      if (cookies.consent === true) {
        const enabledFromCookie = cookies.get('cc_enabled_cookies');
        cookies.enabled.push(
          ...cookies.optional.filter((c) => {
            const cookieName = typeof c.name === 'string' ? c.name : c.name[Object.keys(c.name)[0]];
            return enabledFromCookie.includes(c.identifier || cookies.slugify(cookieName));
          })
        );
        cookies.enabledList =
          cookies.enabled.length > 0
            ? cookies.enabled.map((c) => {
                const cookieName = typeof c.name === 'string' ? c.name : c.name[Object.keys(c.name)[0]];
                return c.identifier || cookies.slugify(cookieName);
              })
            : [];
      }

      if (cookies.necessary)
        cookies.enabled.push(
          ...cookies.necessary.filter((c) => {
            return c.src || c.accepted;
          })
        );

      if (process.client && !isInit) {
        setHead();
        clearCookies();
        callAcceptedFunctions();
      }
    }
  };

  Object.assign(cookies, methods);

  const clearCookies = () => {
    const disabled = cookies.optional.filter((c) => {
      const cookieName = typeof c.name === 'string' ? c.name : c.name[Object.keys(c.name)[0]];
      return !cookies.enabledList.includes(c.identifier || cookies.slugify(cookieName));
    });
    if (disabled.length > 0) {
      disabled.forEach((c) => {
        if (c.declined) c.declined();
        if (c.cookies && c.cookies.length > 0) {
          c.cookies.forEach((i) => {
            cookies.remove(i);
          });
        }
        // if(c.src){
        //   for(let s of [...document.head.querySelectorAll(`script[src="${c.src}"]`)]){
        //     s.parentNode.removeChild(s)
        //   }
        // }
      });
    }
  };

  const setHead = () => {
    if (cookies.enabled.length > 0) {
      const head = document.getElementsByTagName('head')[0];
      cookies.enabled.forEach((c) => {
        if (c.src) {
          const script = document.createElement('script');
          script.src = c.src;
          head.appendChild(script);
          script.addEventListener('load', () => {
            if (c.accepted) c.accepted();
          });
        }
      });
    }
  };

  const callAcceptedFunctions = () => {
    if (cookies.enabled.length > 0) {
      cookies.enabled.forEach((c) => {
        if (c.accepted) c.accepted();
      });
    }
  };

  cookies.setConsent(true);

  if (process.client) {
    window &&
      window.onTroovReady &&
      window.onTroovReady(() => {
        cookies.setConsent();
      });
  }

  inject('cookies', cookies);
  Vue.component('ConsentBannerIframe', ConsentBannerIframe);
};
