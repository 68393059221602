import https from 'https';
import qs from 'qs';

const onRequest =
  ({ isDev, route, $axios, store }) =>
  (config) => {
    if (isDev) {
      config.httpsAgent = new https.Agent({
        rejectUnauthorized: false
      });
    }
    config.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'comma', encode: false });
    const query = route.query || {};
    const token = query.token || store.state.user.token;
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
      $axios.setToken(token, 'Bearer');
    }
    if (store.state.user['x-gouv-app-id']) config.headers.common['x-gouv-app-id'] = `fr.gouv$+${store.state.user['x-gouv-app-id']}-meae-ttc`;
    return config;
  };

export default onRequest;
