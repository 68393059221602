import onRequest from './onRequest';
import onError from './onError';
import onResponseError from './onResponseError';

export default function ({ $axios, redirect, route, store, error, i18n, getRouteBaseName, $config, $sentry }) {
  const routeBaseName = getRouteBaseName();
  $axios.onRequest(onRequest({ isDev: /\.test$/.test($config.HOST_NUXT) || /\.test$/.test($config.API_HOST), route, $axios, store }));

  $axios.onError(onError({ $config, $sentry, error, redirect, store, routeBaseName, i18n, route }));

  $axios.onResponseError(onResponseError({ store, i18n, route, redirect }));
}
