import formatForChartWeek from '~/helpers/formatForChartWeek';

export default {
  getStatsMatch({ commit, state }, { startDate, endDate, teamIds, state: stateItem }) {
    const start = startDate ? this.$dayjs(startDate).toISOString() : this.$dayjs().subtract(30, 'd').toISOString();
    const end = endDate ? this.$dayjs(endDate).toISOString() : this.$dayjs().toISOString();

    const options = {
      params: {
        start,
        end,
        teamIds: teamIds.toString() ? teamIds : this.state.user.teamId,
        state: stateItem
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/statsmatch`, options).then(({ data }) => {
      const result = formatForChartWeek(data.items_match, this.state);
      data.items_match = result.res;
      commit('SET_STATS_ITEMS_MATCH', data);
      commit('SET_XAXIS_ITEMS_STATS_MATCH', result.xaxisWeek);
    });
  },
  getStatsItemFound({ commit, state }, { startDate, endDate, teamIds, state: stateItem }) {
    const start = startDate ? this.$dayjs(startDate).toISOString() : this.$dayjs().subtract(30, 'd').toISOString();
    const end = endDate ? this.$dayjs(endDate).toISOString() : this.$dayjs().toISOString();
    const options = {
      params: {
        start,
        end,
        teamIds: teamIds.toString() ? teamIds : this.state.user.teamId,
        state: stateItem
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/statsitemfound`, options).then(({ data }) => {
      const result = formatForChartWeek(data.items_found, this.state);
      data.items_found = result.res;
      commit('SET_STATS_ITEMS_FOUND', data);
      commit('SET_XAXIS_STATS_ITEMS_FOUND', result.xaxisWeek);
    });
  },
  getStatsItemLost({ commit, state }, { start, end, startDate, endDate, teamIds, state: stateItem }) {
    const startValue = startDate || start ? this.$dayjs(startDate || start).toISOString() : this.$dayjs().subtract(30, 'd').toISOString();
    const endValue = endDate || end ? this.$dayjs(endDate || end).toISOString() : this.$dayjs().toISOString();
    const options = {
      params: {
        start: startValue,
        end: endValue,
        teamIds: teamIds.toString() ? teamIds : this.state.user.teamId,
        state: stateItem
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/statsitemlost`, options).then(({ data }) => {
      const result = formatForChartWeek(data.items_lost, this.state);
      data.items_lost_raw = data.items_lost;
      data.items_lost = result.res;
      commit('SET_STATS_ITEMS_LOST', data);
      commit('SET_XAXIS_STATS_ITEMS_LOST', result.xaxisWeek);
    });
  },

  getEcologStats({ commit, state }, params) {
    if (params.start && params.end) {
      params.start = params.start ? this.$dayjs(params.start).toISOString() : this.$dayjs().subtract(30, 'd').toISOString();
      params.end = params.end ? this.$dayjs(params.end).toISOString() : this.$dayjs().toISOString();
    }

    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/ecologstats`, {
        params: {
          ...params
        }
      })
      .then(({ data }) => {
        commit('SET_ECOLOG_COUNT', data.total);
        return data;
      });
  },
  getStatsReservations({ commit, state }, { startDate, endDate, teamIds, groupBy = 'week', status, type = 'reservations', zones }) {
    const start = this.$dayjs(startDate).format('YYYY-MM-DD') || this.$dayjs().subtract(30, 'd').format('YYYY-MM-DD');
    const end = this.$dayjs(endDate).format('YYYY-MM-DD') || this.$dayjs().toDate().format('YYYY-MM-DD');
    const options = {
      params: {
        start,
        end,
        teamIds,
        groupBy,
        status,
        type,
        zones,
        team: this.state.user.teamId
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/stats.reservations`, options).then(({ data }) => {
      return data;
    });
  },
  getStatsOic({ commit, state }, { start, end, startDate, endDate, teamIds }) {
    const startValue = startDate || start ? this.$dayjs(startDate || start).toISOString() : this.$dayjs().subtract(30, 'd').toISOString();
    const endValue = endDate || end ? this.$dayjs(endDate || end).toISOString() : this.$dayjs().toISOString();
    const options = {
      params: {
        start: startValue,
        end: endValue,
        teamIds: teamIds.toString() ? teamIds : this.state.user.teamId,
        state: 3
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/statsitemlost`, options).then(({ data }) => {
      const result = formatForChartWeek(data.items_lost, this.state);
      data.items_oic_raw = data.items_lost;
      data.items_oic = result.res;
      commit('SET_STATS_OIC', data);
      commit('SET_XAXIS_STATS_OIC', result.xaxisWeek);
    });
  },
  getStatsMatchManual({ commit, state }, { startDate, endDate, teamIds, service }) {
    const start = startDate ? this.$dayjs(startDate).toISOString() : this.$dayjs().subtract(30, 'd').toISOString();
    const end = endDate ? this.$dayjs(endDate).toISOString() : this.$dayjs().toISOString();

    const options = {
      params: {
        start,
        end,
        teamIds: teamIds.toString() ? teamIds : this.state.user.teamId,
        deliveryMode: ['manual'],
        groupBy: 'day',
        service
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/home/match`, options).then(({ data }) => {
      const result = formatForChartWeek(data.items_match, this.state);
      data.items_match = result.res;
      commit('SET_STATS_ITEMS_MATCH_MANUAL', data);
      commit('SET_XAXIS_ITEMS_STATS_MATCH_MANUAL', result.xaxisWeek);
    });
  },
  getStatsMatchDelivery({ commit, state }, { startDate, endDate, teamIds, service }) {
    const start = startDate ? this.$dayjs(startDate).toISOString() : this.$dayjs().subtract(30, 'd').toISOString();
    const end = endDate ? this.$dayjs(endDate).toISOString() : this.$dayjs().toISOString();

    const options = {
      params: {
        start,
        end,
        teamIds: teamIds.toString() ? teamIds : this.state.user.teamId,
        deliveryMode: ['delivery', 'express'],
        groupBy: 'day',
        service
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/home/match`, options).then(({ data }) => {
      const result = formatForChartWeek(data.items_match, this.state);
      data.items_match = result.res;
      commit('SET_STATS_ITEMS_MATCH_DELIVERY', data);
      commit('SET_XAXIS_ITEMS_STATS_MATCH_DELIVERY', result.xaxisWeek);
    });
  }
};
