import qa from './qa';
import dev from './dev';
import prod from './prod';
import desktop from './desktop';

/**
 * Il faut savoir que Nuxt.js utilise definePlugin de Webpack pour définir
 * les variables d'environnement.
 * Cela veut dire que les process ou process.env de Node.js ne sont ni disponibles ni définis.
 * Chaque propriété d'env définie dans nuxt.config.js est individuellement mappée
 * à un process.env.xxxx et convertie durant la compilation.
 *
 * Cela veut dire que console.log(process.env) va renvoyer {}
 * mais que console.log(process.env.notre_variable) va tout de même renvoyer notre valeur.
 * Lorsque Webpack compile notre code, il remplace toutes les instances
 * de process.env.your_variable par la valeur qui lui est attribuée.
 *
 * Exemple: env.test = 'testing123'. Si on utilise process.env.test quelque part dans notre code,
 * cela sera traduit en 'testing123'.
 */
/**
 * Dynamic config for an environment
 * @param host {string?} current hostname
 * @param env {string?} current environment
 * @return {{API_HOST: string, API_ROOT: string, env: string, HOST_NUXT: string, "x-gouv-web": string}}
 */
export default ({ host, env } = {}) => {
  const isTestTLD = /\.test$/.test(host);
  if (env === 'qa' || process.env.APP_CONFIG === 'qa' || (host && host.startsWith('qa.troov.'))) {
    return qa.config();
  }
  if (env === 'production' || process.env.APP_CONFIG === 'production' || (host && host.startsWith('troov.') && !isTestTLD)) {
    return prod.config();
  }
  if (env === 'desktop' || process.env.APP_CONFIG === 'desktop') {
    return desktop.config();
  }
  return dev.config();
};
