export default () => ({
  env: {},
  jsonLd: {
    breadcrumb: null,
    corporation: null,
    faq: null,
    howto: null,
    localbusiness: null,
    organization: null,
    rating: null,
    webpage: null,
    webvideo: null,
    website: null
  },
  navbar: {
    unseenMessage: 0,
    messages: [],
    logo: '/images/troov/logo-dark.png'
  },
  slugs: [],
  toggleNavbar: false,
  toggleLogo: false,
  goups: {},
  goup: {},
  publicTeam: null
});
