import dayjs from '~/helpers/dayjs-with-locales';

const formatForChartWeek = (items, state) => {
  const itemsParsed = [];
  const objArr = [];
  const res = [];
  const xaxisWeek = [];
  let result = {};
  const isSameYear = dayjs().subtract(30, 'day').isSame(dayjs(), 'year');
  items.sort();
  // Find id for each day
  for (const key in items) {
    itemsParsed.push(dayjs(items[key]).locale(state.lang.locale).weeks());
  }
  // Count the number of object per week
  itemsParsed.forEach((item) => {
    if (!objArr.some((elem) => elem[item])) objArr.push({ [item]: itemsParsed.filter((i) => i === item).length });
  });

  objArr.forEach((elements) => {
    for (const [key, value] of Object.entries(elements)) {
      if (!xaxisWeek.includes(key)) {
        xaxisWeek.push(key);
      }
      res.push([key, value]);
    }
  });
  if (res.length === 1) {
    let pastWeek = parseInt(res[0][0], 10) + 1;
    pastWeek = pastWeek > 52 ? 49 : pastWeek;
    res.push([pastWeek, 0]);
  }

  result = {
    res: res.map((tab) => {
      tab[0] = !isSameYear && parseInt(tab[0]) < 5 ? parseInt(tab[0]) + 52 : tab[0];
      return tab;
    }),
    xaxisWeek: xaxisWeek.map((a) => (!isSameYear && parseInt(a) < 5 ? parseInt(a) + 52 : a))
  };
  return result;
};

export default formatForChartWeek;
