export default {
  getPublicTeamBySlug({ commit, state }, { slug }) {
    const host = this.app.$config.API_HOST;
    const options = {
      params: {
        lang: this.$i18n.locale
      }
    };
    return this.$axios.get(`${host}/team/slug/${slug}`, options).then(({ data }) => {
      commit('SET_PUBLIC_TEAM', data);
      return data;
    });
  },

  testTurnstile({ commit, state }, { token }) {
    const host = this.app.$config.API_HOST;
    return this.$axios.post(`${host}/test-turnstile`, { captcha: token }).then(({ data }) => {
      return data;
    });
  },

  getPublicGroupsBySlug({ commit, state }, params) {
    const host = this.app.$config.API_HOST;
    return this.$axios.get(`${host}/group.all`, { params }).then(({ data }) => data);
  },
  getPublicGroupBySlug({ commit, state }, { slug }) {
    const host = this.app.$config.API_HOST;
    return this.$axios.get(`${host}/group.get`, { params: { lang: this.$i18n.locale, slug } }).then(({ data }) => {
      commit('SET_CURRENT_GROUP', data);
      return data;
    });
  },
  getItemByInventor({ commit, state }, { item, ref }) {
    const host = this.app.$config.API_HOST;
    const options = {
      params: {
        lang: this.$i18n.locale
      }
    };
    return this.$axios.get(`${host}/inventor/items/${item}/${ref}`, options).then(({ data }) => {
      return data;
    });
  },
  getSncfStations({ commit, state }, { station }) {
    const stationUrl = `https://data.sncf.com/api/records/1.0/search/?q=${station}&dataset=liste-des-gares&facet=fret&facet=voyageurs&facet=code_ligne&facet=departement`;
    const instance = this.$axios.create();
    delete instance.defaults.headers.common.Authorization;
    delete instance.defaults.headers.common.authorization;
    delete instance.defaults.headers.common['x-gouv-web'];

    return instance.get(stationUrl).then(({ data }) => {
      let records = data.records || [];
      const stations = [];
      records = records.map((rec) => ({ name: rec.fields.libelle.replace(new RegExp('-', 'g'), ' '), code: rec.fields.code_uic }));
      records.forEach((rec) => {
        if (stations.findIndex((s) => s.name === rec.name) === -1) {
          stations.push(rec);
        }
      });
      return stations;
    });
  },
  getSncfItems({ commit, state }, { label, station, date }) {
    const instance = this.$axios.create();
    delete instance.defaults.headers.common.Authorization;
    delete instance.defaults.headers.common.authorization;
    delete instance.defaults.headers.common['x-gouv-web'];
    const type = getSncfType(label);
    const nature = getSncfNature(label);

    const facets = `&sort=date&facet=gc_obo_date_heure_restitution_c&facet=gc_obo_nature_c&facet=gc_obo_type_c&facet=gc_obo_nom_recordtype_sc_c&facet=gc_obo_gare_origine_r_code_uic_c${
      station.code ? `&refine.gc_obo_gare_origine_r_code_uic_c=00${station.code}` : ''
    }&refine.date=${date}${type ? `&refine.gc_obo_type_c=${type}` : ''}${nature ? `&q=${nature}` : ''}`;
    const found_url = `https://data.sncf.com/api/records/1.0/search/?dataset=objets-trouves-restitution&${facets}`;
    return instance.get(found_url).then(({ data }) => data.records.map((rec) => rec.fields));
  },
  setDarkLogo({ commit }) {
    const prefix = 'gouv';
    commit('SET_NAVBAR_LOGO', `/images/${prefix}/logo-dark.png`);
  },
  setLightLogo({ commit }) {
    const prefix = 'gouv';
    commit('SET_NAVBAR_LOGO', `/images/${prefix}/logo-light-2.png`);
  }
};
const getSncfType = (type) => {
  let value = '';

  switch (type) {
    case 'id_card':
      value = "Pièces d'identités et papiers personnels";
      break;
    case 'passeport':
      value = "Pièces d'identités et papiers personnels";
      break;
    case 'driver_license':
      value = "Pièces d'identités et papiers personnels";
      break;
    case 'blue_card':
      value = 'Porte-monnaie / portefeuille, argent, titres';
      break;
    case 'other_card':
      value = "Pièces d'identités et papiers personnels";
      break;
    case 'wallet':
      value = 'Porte-monnaie / portefeuille, argent, titres';
      break;
    case 'bag':
      value = 'Bagagerie: sacs, valises, cartables';
      break;
    case 'suitecase':
      value = 'Bagagerie: sacs, valises, cartables';
      break;
    case 'school_bag':
      value = 'Bagagerie: sacs, valises, cartables';
      break;
    case 'key':
      value = 'Clés, porte-clés, badge magnétique';
      break;
    case 'mobile':
      value = 'Appareils électroniques, informatiques, appareils photo';
      break;
    case 'glasses':
      value = 'Optique';
      break;
    case 'doll':
      value = "Articles d'enfants, de puériculture";
      break;
    case 'hat':
      value = 'Vêtements, chaussures';
      break;
    case 'scarf':
      value = 'Vêtements, chaussures';
      break;
    case 'gloves':
      value = 'Vêtements, chaussures';
      break;
    case 'pullover':
      value = 'Vêtements, chaussures';
      break;
    case 'other_clothes':
      value = 'Vêtements, chaussures';
      break;
    case 'computer':
      value = 'Appareils électroniques, informatiques, appareils photo';
      break;
    case 'tablet':
      value = 'Appareils électroniques, informatiques, appareils photo';
      break;
    case 'jewelry':
      value = 'Bijoux, montres';
      break;
    case 'umbrella':
      value = 'Parapluies';
      break;
    case 'camera':
      value = 'Appareils électroniques, informatiques, appareils photo';
      break;
    case 'usb_key':
      value = 'Appareils électroniques, informatiques, appareils photo';
      break;
    case 'book':
      value = 'Livres, articles de papéterie';
      break;
    case 'earphone':
      value = 'Appareils électroniques, informatiques, appareils photo';
      break;
    case 'two_wheels':
      value = 'Vélos, trottinettes, accessoires 2 roues';
      break;
    case 'sport_item':
      value = 'Articles de sport, loisirs, camping';
      break;
    case 'baby_tools':
      value = "Articles d'enfants, de puériculture";
      break;
  }

  return value;
};

const getSncfNature = (type) => {
  let value = '';

  switch (type) {
    case 'id_card':
      value = "Carte d'identité, passeport, permis de conduire";
      break;
    case 'passeport':
      value = "Carte d'identité, passeport, permis de conduire";
      break;
    case 'driver_license':
      value = "Carte d'identité, passeport, permis de conduire";
      break;
    case 'blue_card':
      value = 'Carte de crédit';
      break;
    case 'wallet':
      value = 'Porte-monnaie, portefeuille';
      break;
    case 'suitecase':
      value = 'Valise, sac sur roulettes';
      break;
    case 'mobile':
      value = 'Téléphone portable';
      break;
    case 'doll':
      value = 'Doudou, peluche';
      break;
    case 'hat':
      value = 'Bonnet, chapeau';
      break;
    case 'scarf':
      value = 'Foulard, écharpe';
      break;
    case 'gloves':
      value = 'Gants';
      break;
    case 'pullover':
      value = 'Pull over, gilet';
      break;
    case 'computer':
      value = 'Ordinateur, ordinateur portable, notebook';
      break;
    case 'tablet':
      value = 'Tablette tactile';
      break;
    case 'earphone':
      value = 'Appareil audio portable (lecteur de CD, baladeur MP3, iPod, ... )';
      break;
    case 'bracelet':
      value = 'Bracelet';
      break;
    case 'ring':
      value = 'Bague';
      break;
    case 'earring':
      value = "Boucles d'oreilles";
      break;
    case 'necklace':
      value = 'Chaîne, collier';
      break;
    case 'watch':
      value = 'Montre';
      break;
    case 'camera':
      value = 'Appareil photo, caméscope/caméras et accessoires';
      break;
    case 'usb_key':
      value = 'Autres appareils et accessoires électroniques';
      break;
    case 'drugs':
      value = 'Médicaments';
      break;
    case 'tools':
      value = 'Outils, Accesoires';
      break;
    case 'pen':
      value = 'Article de papéterie, stylo, classeur';
      break;
    case 'stroller':
      value = "Landau, poussette d'enfants";
      break;
    case 'health_card':
      value = 'Carte Vitale';
      break;
    case 'transport_card':
      value = 'Billets SNCF';
      break;
    case 'letter':
      value = 'Autre pièce ou papier personnel (préciser)';
      break;
    case 'money':
      value = 'Argent liquide / Espèces';
      break;
    case 'case':
      value = "Cartable, trousse d'écolier, dossier scolaire";
      break;
    case 'hand_bag':
      value = 'Sac à main';
      break;
    case 'shoes':
      value = 'Chaussures (autre que chaussures de ski et patins)';
      break;
    case 'cosmetics':
      value = 'Produits cosmétiques (parfum, etc.)';
      break;
    case 'toy':
      value = 'Autres jeux/jouets';
      break;
  }

  return value;
};
