import MarkdownIt from 'markdown-it'

const handlePlugin = (plugin) => plugin.default || plugin

export default ({}, inject) => {
  const md = new MarkdownIt('default', {"linkify":true,"breaks":true,"langPrefix":"language-","html":true,"typographer":true,"injected":true,"quotes":"“”‘’"})

  md.use(handlePlugin(require('markdown-it-ins')))

  md.use(handlePlugin(require('markdown-it-footnote')))

  md.use(handlePlugin(require('markdown-it-deflist')))

  md.use(handlePlugin(require('markdown-it-attrs')))

  md.use(handlePlugin(require('markdown-it-plugin-underline')))

  inject('md', md)
}