import saveBlobFile from '~/helpers/saveBlobFile';

export default {
  getBlockedIps({ commit, state }, { page = 1, limit }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/blocked-ips`, {
        params: {
          page,
          limit
        }
      })
      .then(({ data }) => {
        commit('SET_BLOCKED_IPS', data);
        return data;
      });
  },
  getMostUsedIps({ commit, state }, { page = 1, limit }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/ips/most-used`, {
        params: {
          page,
          limit
        }
      })
      .then(({ data }) => {
        commit('SET_MOST_USED_IPS', data);
        return data;
      });
  },
  blockIp({ commit, state }, { ip }) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/blocked-ips`, {
        ip
      })
      .then(({ data }) => {
        return data;
      });
  },
  unblockIp({ commit, state }, { id }) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/blocked-ips/unblock`, {
        id
      })
      .then(({ data }) => {
        return data;
      });
  },
  exportMostUsedIp({ commit, state }, { ip, format }) {
    return this.$axios
      .post(
        `${this.app.$config.API_HOST}/team/${this.state.user.teamId}/blocked-ips/export`,
        {
          format,
          ip
        },
        { responseType: 'blob' }
      )
      .then(({ data }) => {
        saveBlobFile({ data, extension: format, filename: 'liste_ips' });
        return data;
      });
  },
  checkIfIpIsBlocked({ commit, state }, { ip }) {
    return this.$axios.post(`${this.app.$config.API_HOST}/client/session`, { ip });
  },
  getIpsReservations({ commit, state }, { ip }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/ips/get_ips_reservations`, { params: { ip } })
      .then((responses) => {
        return responses;
      });
  }
};
