export function isHeadlessUserAgent(agent) {
  return /headless/i.test(agent);
}

export function isValidAppVersion({ appVersion }) {
  return /headless/i.test(appVersion);
}

export function hasPlugins({ plugins }) {
  if (Array.isArray(plugins)) {
    return plugins.length === 0 ? -1 : 0;
  }
  return false;
}

export function isValidWebdriver({ webdriver }) {
  return !!webdriver;
}

export function isFast({ webdriver }) {
  return !!webdriver;
}

export function navigatorHaveValidProperties(navigator) {
  return 'credentials' in navigator && 'language' in navigator && 'languages' in navigator;
}
