<template>
  <component :is="errorPage" :error="error" :debug="debug" :show-footer="false" />
</template>

<script>
import { mapState } from 'vuex';
import error500 from '~/components/error/500';
import error401 from '~/components/error/401';
import error403 from '~/components/error/403';
import error404 from '~/components/error/404';

export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      bodyAttrs: {
        'data-layout': /dashboard/.test(this.getRouteBaseName()) ? 'vertical' : 'horizontal'
      },
      meta: [{ hid: 'language', name: 'language', content: this.$i18n.locale.toUpperCase() }]
    };
  },
  computed: {
    ...mapState({
      locale: (state) => state.lang.locale,
      isConnected: (state) => state.user.user && state.user.user._id
    }),
    host() {
      return this.$config.HOST_NUXT;
    },
    debug() {
      return this.$config.environement === 'development' || this.$config.environement === 'desktop';
    },
    errorPage() {
      if (this.error.statusCode === 404) {
        return error404;
      }
      if (this.error.statusCode === 401) {
        return error401;
      }
      if (this.error.statusCode === 403) {
        return error403;
      }
      // catch everything else
      return error500;
    }
  },
  mounted() {
    if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
      // detect it's IE11
      window.addEventListener(
        'hashchange',
        (event) => {
          const currentPath = window.location.hash.slice(1);
          if (this.$route.path !== currentPath) {
            this.$router.push(currentPath);
          }
        },
        false
      );
    }
  }
};
</script>

<style scoped>
.btn-outline-primary:hover,
.btn-primary:hover {
  background-color: #1212ff !important;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none !important;
}
</style>
