import Vue from 'vue';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/ar';
import 'vue-cal/dist/i18n/es';
import 'vue-cal/dist/i18n/de';
import 'vue-cal/dist/i18n/pt-br';
import 'vue-cal/dist/i18n/fr';
import 'vue-cal/dist/i18n/it';
import 'vue-cal/dist/i18n/ru';
import 'vue-cal/dist/i18n/nl';
import 'vue-cal/dist/i18n/zh-cn';
// https://vuedose.tips/tips/dynamic-imports-in-vue-js-for-better-performance/
const VueCal = () => import('vue-cal');

Vue.component('VueCal', VueCal);
