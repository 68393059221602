<template>
  <client-only>
    <iframe v-if="iframeEnabled" />
    <div v-else class="p-4 bg-dark text-white d-flex flex-column justify-content-center align-items-center">
      <p>
        {{ $t('cookies.blockedIframe') }}
      </p>

      <b-button variant="outline-primary" @click="openConsentModal">
        {{ $t('common.here') }}
      </b-button>
    </div>
  </client-only>
</template>

<script>
export default {
  name: 'ConsentBannerIframe',
  data() {
    return {
      cookies: this.$cookies
    };
  },
  computed: {
    iframeEnabled() {
      return this.cookies.consent;
    }
  },
  methods: {
    openConsentModal() {
      this.cookies.consent = false;
      this.cookies.modal = true;
    }
  }
};
</script>
