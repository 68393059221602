import environment from '~/config/environment';
import saveBlobFile from '~/helpers/saveBlobFile';
const Cookie = process.client ? require('js-cookie') : undefined;

export default {
  // auth part
  loginAdmin({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/admin/auth/login`, body).then(({ data }) => {
      if (data.token) {
        this.$axios.setToken(data.token, 'Bearer');
        commit('SET_TOKEN', data.token);
        return data;
      }
    });
  },
  login({ commit, state, rootState }, body) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/auth/login`, body, {
        headers: {
          'x-csrf-token': rootState.user.captcha.uui
        }
      })
      .then(({ data }) => {
        if (data.token) {
          this.$axios.setToken(data.token, 'Bearer');
          commit('SET_TOKEN', data.token);
          return data.hasTotp ? data : handleUser({ commit, state }, data);
        }
      });
  },
  loginTotp({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/auth/login-totp`, body).then(({ data }) => {
      if (data.token) {
        this.$axios.setToken(data.token, 'Bearer');
        commit('SET_TOKEN', data.token);
        return handleUser({ commit, state }, data);
      }
    });
  },
  logout({ commit, state }, obj = {}) {
    return this.$axios.post(`${this.app.$config.API_HOST}/auth/logout`, { token: obj.token || state.token }).then(() => {
      commit('SET_TOKEN', null);
      commit('SET_USER', {});
      commit('SET_CURRENT_TEAM', {});
      commit('SET_MEMBER', {});
      commit('SET_CURRENT_MEMBER', {});
      commit('SET_TEAM', {});
    });
  },
  gouvLogout({ commit, state }, obj = {}) {
    return this.$axios.get(`${this.app.$config.API_HOST}/auth/consulat/logout`, { token: obj.token || state.token }).then(({ data }) => {
      return data.uri;
    });
  },
  resetPassword({ commit, state }, { email, captcha, _csrf }) {
    return this.$axios
      .post(
        `${this.app.$config.API_HOST}/auth/reset`,
        { email, captcha },
        {
          headers: {
            'x-csrf-token': _csrf
          }
        }
      )
      .then(({ data }) => data);
  },
  newUserPassword({ commit, state }, { password, id, token }) {
    return this.$axios.put(`${this.app.$config.API_HOST}/password/${id}?token=${token}`, { password }).then(({ data }) => data);
  },
  setTrippertyCommand({ commit, state }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/team/tripperty`).then(({ data }) => {
      commit('SET_TRIPPERTY', data);
    });
  },
  setOrders({ commit, state }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/stats.orders`, { params: { team: state.teamId } }).then(({ data }) => {
      commit('SET_ORDERS', data);
      return data;
    });
  },
  getUser({ commit, state }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/users`, { params: { team: state.teamId } })
      .then(({ data }) => handleUser({ commit, state }, data));
  },
  getOwnerEditHistory({ commit, state }, { team, reservation_id, owner_id }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/${(team && team.id) || team || state.teamId}/users/${owner_id}`, { params: { reservation_id } })
      .then(({ data }) => {
        return data;
      });
  },
  updateCredentials({ commit, state }, { reservation_id, id, user }) {
    return this.$axios.put(`${this.app.$config.API_HOST}/users/${state.teamId}/credentials`, { reservation_id, id, user }).then(({ data }) => data);
  },
  getParamById({ commit, state }, { id, type, team }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${(team && team.id) || team || state.teamId}/params/${type}/${id}`).then(({ data }) => {
      commit('SET_CURRENT_PARAMS', data);
      return data;
    });
  },
  getUserSsr({ commit, state }, { host, team }) {
    const { API_HOST } = environment({ host });
    return this.$axios.get(`${API_HOST}/users`, { params: { team } }).then(({ data }) => handleUser({ commit, state }, data));
  },

  updateUser({ commit, state }, user) {
    return this.$axios.put(`${this.app.$config.API_HOST}/users`, user).then(({ data }) => {
      commit('UPDATE_USER', data);
      return data;
    });
  },
  updateTeamPassword({ commit, state }, { new_password, old_password, user }) {
    return this.$axios.put(`${this.app.$config.API_HOST}/users/team/password`, { old_password, new_password, user }).then(({ data }) => data);
  },
  deleteUser({ commit, state }) {
    return this.$axios.delete(`${this.app.$config.API_HOST}/users`).then(({ data }) => data);
  },
  setTokenFromLocalStorage({ commit, state }) {
    if (typeof window !== 'undefined' && window.localStorage) {
      const token = window.localStorage.getItem('token');
      commit('SET_TOKEN', token);
      this.$axios.setToken(token, 'Bearer');
    }
  },
  setTokenFromCookie({ commit, state }) {
    const token = Cookie.get('_gouv_c_tkn');
    if (token && token !== '') {
      commit('SET_TOKEN', token);
      this.$axios.setToken(token, 'Bearer');
    }
  },
  checkToken({ commit, state }, token) {
    return this.$axios.post(`${this.app.$config.API_HOST}/users/session`, { token });
  },
  resendEmail({ commit, state }, id) {
    return this.$axios.get(`${this.app.$config.API_HOST}/users/${id}/resend-email`).then(({ data }) => data);
  },
  getCaptcha({ commit, state }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/captcha`, {
        params: {
          locale: this.$i18n.locale
        }
      })
      .then(({ data, headers }) => {
        commit('SET_CAPTCHA', { uui: headers['x-gouv-csrf'], value: headers['x-gouv-ck'] });
        return {
          data,
          headers
        };
      });
  },
  handshake({ commit }) {
    return this.$axios.head(`${this.app.$config.API_HOST}/handshake`).then(({ headers }) => {
      commit('SET_HANDSHAKE', { value: headers[this.app.$config.handshakeHeader] });
      commit('SET_APP_ID', headers['x-gouv-app-id'].replace('fr.gouv$+', '').replace('-meae-ttc', ''));
      return true;
    });
  },
  // team part
  addTeam({ state, commit }, { mobile, name, type, address, picture, enable_only_reservation, reservation_form, layout }) {
    const obj = {
      name,
      type,
      mobile,
      city: address.locality || '',
      country: address.country || '',
      zipcode: address.postal_code || '',
      region: address.administrative_area_level_1 || '',
      address: (address.street_number ? address.street_number + ' ' : '') + (address.route || ''),
      loc: {
        type: 'Point',
        coordinates: [address.longitude, address.latitude]
      },
      picture,
      enable_only_reservation,
      reservation_form,
      layout
    };
    return this.$axios.post(`${this.app.$config.API_HOST}/team`, obj).then(({ data }) => data);
  },
  updateTeam({ commit, state }, { preventUpdate, ...team }) {
    return this.$axios.put(`${this.app.$config.API_HOST}/team/${team.id}`, team).then(({ data }) => {
      if (!preventUpdate) {
        commit('UPDATE_TEAM', team);
      }
      return team;
    });
  },
  updateTeamBackground({ commit, state }, { teamId, background }) {
    const formData = new FormData();
    formData.append('background', background);
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${teamId}/background`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => data);
  },
  getTeamTypes({ commit, state }) {
    const options = {
      params: {
        lang: this.$i18n.locale
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/types`, options).then(({ data }) => {
      commit('SET_TEAM_TYPES', data);
      return data;
    });
  },
  addMember({ commit, state }, { email, role, firstname, lastname, created_at, job, matricule_number, zones }) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${state.teamId}/members`, [
        {
          email,
          firstname,
          lastname,
          created_at,
          role,
          job,
          matricule_number,
          zones
        }
      ])
      .then(({ data }) => data);
  },
  addMembers({ commit, state }, users) {
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${state.teamId}/members`, users).then(({ data }) => data);
  },
  getMembers({ commit, state }, { page = 1, limit = 10, search = '', sortBy, sortDesc }) {
    const params = {
      page,
      limit,
      search,
      sortBy,
      sortDesc
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${state.teamId}/members`, { params }).then(({ data }) => {
      const label =
        state.team && state.team.type && state.team.type.label && state.team.type.label === 'mall'
          ? 'common.store_and_services'
          : 'common.store_and_services';
      const labels = [
        'fullname',
        'role',
        'job',
        'matricule_number',
        'receive_email',
        'redirect_email',
        'item_lost_alert',
        'reservation_alert',
        label
      ];
      if (state.currentTeam && state.currentTeam.enable_only_reservation) {
        labels.splice(6, 1);
      }
      const values = data.docs.map((i) => {
        const body = {
          id: i._id || i.id,
          fullname: typeof i.user === 'object' ? i.user.firstname + ' ' + i.user.lastname : '',
          role: typeof i.role === 'object' ? 'role.' + i.role.name : '',
          receive_email: i.receive_email === false ? 'form.no' : 'form.yes',
          item_lost_alert: i.item_lost_alert === false ? 'form.no' : 'form.yes',
          reservation_alert: i.reservation_alert === false ? 'form.no' : 'form.yes',
          redirect_email: i.redirect_email ? i.redirect_email : '',
          job: i.job || (typeof i.user === 'object' ? i.user.job : ''),
          matricule_number: i.matricule_number ? i.matricule_number.join(', ') : '',
          [label]: i.zones ? i.zones.join(', ') : '',
          team: i.team._id,
          user: i.user._id
        };
        if (state.currentTeam && state.currentTeam.enable_only_reservation) {
          delete body.item_lost_alert;
        }
        return body;
      });
      commit('datatable/SET_LABELS', labels, { root: true });
      commit('datatable/SET_VALUES', values, { root: true });
      commit('SET_MEMBERS_LIST', values);
      return data;
    });
  },
  downloadMemberList({ commit, state }, { search = '', sortBy, sortDesc, format }) {
    const params = {
      search,
      sortBy,
      sortDesc,
      export: true,
      format
    };
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${state.teamId}/members`, {
        responseType: 'blob',
        params
      })
      .then(({ data }) => {
        saveBlobFile({ data, extension: format, filename: 'member-list' });
        return data;
      });
  },
  getMember({ commit, state }, { id }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${state.teamId}/members/${id}`).then(({ data }) => {
      commit('SET_CURRENT_MEMBER', data);
    });
  },
  updateMember({ commit, state }, { id, role, redirectEmail, receiveEmail, receiveItemLostAlert, receiveReservationAlert, matricule_number, zones }) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${state.teamId}/members/${id}`, {
        role,
        redirectEmail,
        receiveEmail,
        receiveItemLostAlert,
        receiveReservationAlert,
        matricule_number,
        zones
      })
      .then(({ data }) => data);
  },
  deleteMember({ commit, state }, { id }) {
    return this.$axios.delete(`${this.app.$config.API_HOST}/team/${state.teamId}/members/${id}`).then(({ data }) => data);
  },
  getRoles({ commit, state }) {
    const options = {
      params: {
        team: state.teamId
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/roles`, options).then(({ data }) => {
      commit('SET_ROLES', data);
    });
  },
  getPricing({ commit, state }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/pricings`).then(({ data }) => {
      commit('SET_PRICINGS', data);
    });
  },
  getAllTeamFields({ commit, state }) {
    const options = {
      params: {
        lang: this.$i18n.locale
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${state.teamId}/fields`, options).then(({ data }) => {
      commit('SET_TEAM_FIELDS', data);
    });
  },
  getTeamParams({ commit, state }) {
    const options = {
      params: {
        lang: this.$i18n.locale || 'fr'
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${state.teamId}/params`, options).then(({ data }) => {
      commit('SET_TEAM_PARAMS', data);
      return data;
    });
  },
  getParams({ commit, state }, { type }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${state.teamId}/params/${type}`).then(({ data }) => {
      commit('SET_CURRENT_PARAMS', data);
      return data;
    });
  },
  applyCustomRecurrence({ commit, state }, customRecurrence) {
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${state.teamId}/params/custom-recurrence`, customRecurrence).then(({ data }) => {
      return data;
    });
  },
  getParamByType({ commit, state }, { type, team }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${team || state.teamId}/param-by-type/${type}`).then(({ data }) => {
      return data;
    });
  },
  getTeamsFromEmail({ commit, state }, { email }) {
    const options = {
      params: {
        lang: this.$i18n.locale
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${state.teamId}/email/${email}`, options).then(({ data }) => data);
  },
  getTeamTypesCities({ commit, state }, ref) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/cities`, {
        params: {
          lang: this.$i18n.locale,
          teamType: ref
        }
      })
      .then(({ data }) => data);
  },
  updateTeamParams({ commit, state }, body) {
    return this.$axios.put(`${this.app.$config.API_HOST}/team/${state.teamId}/params`, body).then(({ data }) => {
      commit('UPDATE_TEAM_PARAMS', data);
      return data;
    });
  },
  updateMainTeam({ commit, state }, params) {
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${params.teamId}/members/updatemain`).then(() => {
      commit('UPDATE_MAIN_TEAM', params.teamId);
    });
  },
  printDocuments({ state }, { itemId, documentType, contact, documentName, itemContact, printPage }) {
    return this.$axios({
      url: `${this.app.$config.API_HOST}/team/${state.teamId}/item/${itemId}/print-certificate`,
      method: 'POST',
      data: {
        documentType,
        itemId,
        contact,
        documentName,
        itemContact,
        sendLetter: printPage === 'letter'
      },
      responseType: 'blob' // important
    }).then(({ data }) => {
      return saveBlobFile({ data, extension: 'pdf', filename: documentName || `${itemId}_${documentType}`, getBlobURL: printPage });
    });
  },
  sendReceipt({ state }, { itemId, documentType, contact, documentName, itemContact, email, language }) {
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${state.teamId}/item/${itemId}/print-certificate`, {
      documentType,
      itemId,
      contact,
      documentName,
      itemContact,
      email,
      language,
      teamName: state.team.name,
      sendEmail: true
    });
  },
  getSlugs({ state }, { host }) {
    // We take the API_HOST
    const { API_HOST } = environment({ host });
    return this.$axios.get(`${(this.app.$config && this.app.$config.API_HOST) || API_HOST}/teams/slugs`);
  },
  changeCurrentTeam({ state, commit }, { team }) {
    if (typeof team === 'string') {
      commit('SET_TEAM_ID', team);
    } else {
      commit('SET_TEAM_ID', team._id);
    }
  },
  getTeamZones({ commit, state }, { team, zones }) {
    const options = {
      params: {
        lang: this.$i18n.locale || 'fr',
        zones
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${team}/zones`, options).then(({ data }) => {
      return data;
    });
  },
  loadTeamZones({ commit, state }, { team, zones }) {
    const body = {
      lang: this.$i18n.locale || 'fr',
      zones
    };
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${team}/zones`, body).then(({ data }) => {
      return data;
    });
  },
  addParams({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${state.teamId}/params/${body.type}`, body).then(({ data }) => {
      return data;
    });
  },
  addManyParams({ commit, state }, body = []) {
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${state.teamId}/params/many`, body).then(({ data }) => {
      return data;
    });
  },
  updateParams({ commit, state }, body) {
    return this.$axios
      .put(`${this.app.$config.API_HOST}/team/${state.teamId}/params/${body.params.type}/${body._id}`, body.params)
      .then(({ data }) => {
        return data;
      });
  },
  updateOrCreateParam({ commit, state }, body) {
    return this.$axios.put(`${this.app.$config.API_HOST}/team/${state.teamId}/param/update/${body.type}`, body).then(({ data }) => {
      return data;
    });
  },
  removeParams({ commit, state }, body) {
    return this.$axios.delete(`${this.app.$config.API_HOST}/team/${state.teamId}/params/${body.type}/${body._id}`).then(({ data }) => {
      const updateStore = state.currentParams.filter((entry) => entry._id !== body._id);
      commit('SET_CURRENT_PARAMS', updateStore);
      return data;
    });
  },
  getGoogleMapsLink({ commit, state }, { team }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${team || state.teamId}/google-maps-link`).then(({ data }) => {
      commit('SET_GOOGLE_MAPS_LINK', data);
      return data;
    });
  },
  getShopsDisplayNamesList({ commit, state }, label) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/reservations/services/names`, {
        params: {
          lang: this.app.i18n.locale,
          label
        }
      })
      .then(({ data }) => data);
  },
  getServiceCustomSessionParam({ commit, state }, { date, team, zone_id }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${team || state.teamId}/param/service-custom-session`, { params: { date, zone_id } })
      .then((data) => {
        return data;
      });
  }
};

const handleUser = ({ commit, state }, data) => {
  commit('SET_REFRESH_TOKEN', data.refreshToken);
  commit('SET_USER', data);
  return data;
};
