export default {
  SET_ENV(state, env) {
    state.env = env;
  },
  SET_NAVBAR_LOGO(state, logo) {
    state.navbar.logo = logo;
  },
  ENABLE_NAVBAR_TOGGLE(state) {
    state.toggleNavbar = true;
  },
  DISABLE_NAVBAR_TOGGLE(state) {
    state.toggleNavbar = false;
  },
  ENABLE_LOGO_TOGGLE(state) {
    state.toggleLogo = true;
  },
  DISABLE_LOGO_TOGGLE(state) {
    state.toggleLogo = false;
  },
  UPDATE_JSON_LD(state, { key, value }) {
    state.jsonLd[key] = value;
  },
  SET_MESSAGES(state, messages) {
    let hasUnseen = 0;
    for (const key in messages) {
      messages[key].date = this.$dayjs(messages[key].created_at).locale(this.$i18n.locale);
      if (!messages[key].seen_at && messages[key].receive_team !== this.state.user.teamId) {
        hasUnseen += 1;
      }
    }
    state.navbar.messages = messages;
    state.navbar.unseenMessage = hasUnseen;
  },
  SET_GROUPS(state, goups) {
    state.goups = goups;
  },
  SET_CURRENT_GROUP(state, group) {
    state.group = group;
  },
  SET_PUBLIC_TEAM(state, publicTeam) {
    state.publicTeam = publicTeam;
  }
};
