import Vue from 'vue';

import {
  AlertPlugin,
  AspectPlugin,
  BadgePlugin,
  FormTagsPlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  ButtonToolbarPlugin,
  CardPlugin,
  CarouselPlugin,
  CollapsePlugin,
  DropdownPlugin,
  EmbedPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormSpinbuttonPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  JumbotronPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  MediaPlugin,
  ModalPlugin,
  NavPlugin,
  OverlayPlugin,
  SidebarPlugin,
  SpinnerPlugin,
  TabsPlugin,
  TablePlugin,
  TooltipPlugin,
  PaginationPlugin,
  PopoverPlugin,
  VBPopoverPlugin,
  ProgressPlugin,
  SkeletonPlugin,
  VBModalPlugin,
  VBTooltipPlugin,
  VBVisiblePlugin
} from 'bootstrap-vue';

Vue.use(AlertPlugin);
Vue.use(AspectPlugin);
Vue.use(BadgePlugin);
Vue.use(FormTagsPlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(ButtonToolbarPlugin);
Vue.use(CardPlugin);
Vue.use(CarouselPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(EmbedPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormSpinbuttonPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(ImagePlugin);
Vue.use(InputGroupPlugin);
Vue.use(JumbotronPlugin);
Vue.use(LayoutPlugin);
Vue.use(LinkPlugin);
Vue.use(ListGroupPlugin);
Vue.use(MediaPlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(OverlayPlugin);
Vue.use(SidebarPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TabsPlugin);
Vue.use(TablePlugin);
Vue.use(TooltipPlugin);
Vue.use(PaginationPlugin);
Vue.use(PopoverPlugin);
Vue.use(VBPopoverPlugin);
Vue.use(ProgressPlugin);
Vue.use(SkeletonPlugin);

Vue.use(VBModalPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(VBVisiblePlugin);
