export default {
  getPublicTeams({ commit, state }, params) {
    const host = this.app.$config.API_HOST;
    const options = {
      params: {
        lang: this.$i18n.locale,
        ...params
      }
    };
    return this.$axios.get(`${host}/teams`, options).then(({ data }) => {
      commit('SET_PUBLIC_TEAMS', data);
      return data;
    });
  },
  getPublicTeam({ commit, state }, { id }) {
    const host = this.app.$config.API_HOST;
    const options = {
      params: {
        lang: this.$i18n.locale,
        id
      }
    };
    return this.$axios.get(`${host}/team/${id}`, options).then(({ data }) => {
      commit('SET_PUBLIC_TEAM', data);
      return data;
    });
  }
};
