export default () => ({
  items_lost_raw: [],
  items_lost_count: 0,
  items_found_count: 0,
  items_match_count: 0,
  items_oic_count: 0,
  items_match_manual_count: 0,
  items_match_delivery_count: 0,
  items_match_types_count: 0,
  items_lost: [],
  items_found: [],
  items_oic: [],
  items_match_types: [],
  ecolog_count: '0',
  reservation_validated: [],
  reservations_count: 0,
  reservations_validated_count: 0,
  xaxis_stats: {
    xaxis_items_lost: [],
    xaxis_items_found: [],
    xaxis_match: [],
    xaxis_match_manual: [],
    xaxis_match_delivery: [],
    xaxis_items_oic: []
  }
});
