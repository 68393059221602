const saveBlobFile = ({ data, extension, filename, getBlobURL = false }) => {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(data, `${filename}.${extension}`);
  } else {
    const blobURL = window.URL.createObjectURL(data);
    if (getBlobURL) return blobURL;
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = blobURL;
    downloadLink.setAttribute('download', `${filename}.${extension}`);
    if (typeof downloadLink.download === 'undefined') {
      downloadLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

export default saveBlobFile;
