<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div v-if="debug" class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h1 class="display-2 text-danger font-weight-medium">5<i class="bx bx-buoy bx-spin text-danger display-3"></i>0</h1>
            <h4 class="text-uppercase">Internal Server Error</h4>
          </div>
        </div>
      </div>

      <floating-item-box v-if="!debug" />

      <h1 class="text-danger text-center">
        {{ $t('error.err_occured') }}
      </h1>

      <div v-if="debug" class="alert alert-secondary p-2 mt-4" role="alert">
        {{ $t('error.open_dev_console') }}
      </div>
      <pre v-if="debug" class="card mt-4 p-4"><code>{{ error }}</code></pre>
    </div>
  </div>
</template>
<script>
import FloatingItemBox from '~/components/error/FloatingItemBox.vue';

export default {
  name: 'Error500',
  components: {
    FloatingItemBox
  },
  props: {
    error: {
      type: Object,
      default: null
    },
    debug: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped>
.account-pages {
  min-height: 70vh;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none !important;
}
.btn-primary:hover {
  background-color: #1212ff !important;
}
</style>
