<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <b-img src="/img/press/logo/color/logo_troov_color.png" class="mx-4 items-troov" />
    <div class="shadow-container text-center">
      <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="61px"
        y="20px"
        width="200px"
        height="40px"
        viewBox="0 0 200 40"
        enable-background="new 0 0 200 40"
        xml:space="preserve"
        class="ellipse-shadow"
      >
        <ellipse fill="#999999" cx="110" cy="20" rx="70" ry="12" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FloatingItemsBox'
};
</script>

<style scoped>
.items-troov {
  height: 140px;
  width: auto;
  animation: float 3s ease-out infinite;
}
@keyframes float {
  50% {
    transform: translate(0, 20px);
  }
}
.shadow-container {
  width: 160px;
  margin-top: 15px;
}
.ellipse-shadow {
  animation: shrink-shadow 3s ease-out infinite;
  transform-origin: center center;
}
.ellipse-shadow ellipse {
  transform-origin: center center;
}
@keyframes shrink-shadow {
  0% {
    width: 90%;
    margin: 0 5%;
  }
  50% {
    width: 60%;
    margin: 0 18%;
  }
  100% {
    width: 90%;
    margin: 0 5%;
  }
}
</style>
