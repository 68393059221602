export default {
  getPage({ commit, state }, { type, categorySlug, summarySlug, lang, name, ref, refLang, categoryOrder }) {
    const params = {
      type,
      categorySlug,
      summarySlug,
      categoryOrder,
      name,
      ref,
      refLang,
      lang
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/page.get`, { params }).then(({ data }) => {
      commit('SET_PAGE_CONTENT', data);
      return data;
    });
  },
  getFormations({ commit, state }, lang) {
    const options = {
      params: {
        lang
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/formations`, options).then(({ data }) => {
      return data;
    });
  },
  getFormationBySlug({ commit, state }, slug) {
    const options = {
      params: {
        lang: this.$i18n.locale || 'fr'
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/formation/${slug}`, options).then(({ data }) => {
      return data;
    });
  },
  getHome({ commit, state }, { lang }) {
    const params = {
      lang
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/page.faqHome`, { params }).then(({ data }) => {
      commit('SET_PAGE_CONTENT', data);
      return data;
    });
  },
  getLangUrl({ commit, state }, { type, categorySlug, summarySlug, lang }) {
    const params = {
      type,
      categorySlug,
      summarySlug,
      lang
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/page.langUrl`, { params }).then(({ data }) => {
      return data;
    });
  },
  getSidebar({ commit, state }, { type, categorySlug, lang, name, ref, refLang, categoryOrder }) {
    const params = {
      type,
      categorySlug,
      name,
      ref,
      refLang,
      categoryOrder,
      lang
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/page.sidebar`, { params }).then(({ data }) => {
      return data;
    });
  },
  getPageType({ commit, state }, { type, lang }) {
    const params = {
      type,
      lang
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/page.pageType`, { params }).then(({ data }) => {
      commit('SET_PAGE_CONTENT', data);
      return data;
    });
  },

  getFaq({ commit, state }, { lang, type }) {
    const params = {
      lang,
      type
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/page.faq.index`, { params }).then(({ data }) => {
      commit('SET_PAGE_CONTENT', data);
      return data;
    });
  }
};
