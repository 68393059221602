export const state = () => ({
  hostname: false,
  protocol: null,
  ip: null
});

export const mutations = {
  SET_HOSTNAME(state, { hostname, protocol, ip }) {
    state.hostname = hostname;
    state.protocol = protocol;
    state.ip = ip;
  }
};

export const actions = {
  changeHostnameValue({ commit }, { hostname, protocol, ip }) {
    commit('SET_HOSTNAME', { hostname, protocol, ip });
  }
};
