import yaoundeIps from '../datas/badIpYaounde.json';
import ndjamenaIps from '../datas/badIpNdjamena.json';
import badSentryIps from '../datas/badIpSentry.json';

export function getIp(req) {
  return (
    (req && req.headers['x-forwarded-for'] && req.headers['x-forwarded-for'].split(',').pop()) ||
    (req && req.connection && req.connection.remoteAddress) ||
    (req && req.socket && req.socket.remoteAddress) ||
    (req && req.connection && req.connection.socket && req.connection.socket.remoteAddress)
  );
}

export function isBlackListedYaounde(ip) {
  return yaoundeIps.list.slice(0, 40).includes(ip);
}

export function isBlackListedNdjamena(ip) {
  return ndjamenaIps.list.slice(0, 40).includes(ip);
}

export function hasStrangeBehavior(ip) {
  return badSentryIps.list.includes(ip);
}
