const onError =
  ({ store, i18n, route, redirect }) =>
  (err) => {
    if (store.state.user.teamId && err.response && err.response.status === 401 && !route.fullPath.match('login')) {
      let path = `/${i18n.locale || 'fr'}/login?${route.fullPath.includes('noRetry') ? 'noRetry=1&' : ''}nextLink=${route.fullPath}`;
      if (err.response.data.message === 'User blocked' || err.response.data.message === 'User deleted') {
        path = `/${i18n.locale || 'fr'}/login`;
      }
      store.commit('user/SET_TOKEN', null);
      return process.isServer ? redirect(path) : window.location.replace(path);
    }
    return Promise.reject(err);
  };

export default onError;
