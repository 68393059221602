export default {
  SET_ZONES(state, currentZone) {
    state.zones = currentZone;
  },
  SET_CURRENT_ZONE(state, currentZone) {
    state.currentZone = currentZone;
  },
  SET_MATCHING_CALENDAR(state, matchingCalendar) {
    state.matchingCalendar = matchingCalendar;
  }
};
