export default {
  SET_STATS_ITEMS_LOST(state, data) {
    state.items_lost_count = data.items_lost_count;
    state.items_lost = data.items_lost;
    state.items_lost_raw = data.items_lost_raw;
  },
  SET_STATS_ITEMS_FOUND(state, data) {
    state.items_found_count = data.items_found_count;
    state.items_found = data.items_found;
  },
  SET_STATS_ITEMS_MATCH(state, data) {
    state.items_match_count = data.items_match_count;
    state.items_match = data.items_match;
    state.items_match_types = data.items_match_types;
    state.items_match_types_count = data.items_match_types_count;
  },
  SET_STATS_ITEMS_MATCH_MANUAL(state, data) {
    state.items_match_manual_count = data.items_match_count;
    state.items_match_manual = data.items_match;
  },
  SET_STATS_ITEMS_MATCH_DELIVERY(state, data) {
    state.items_match_delivery_count = data.items_match_count;
    state.items_match_delivery = data.items_match;
  },
  SET_STATS_OIC(state, data) {
    state.items_oic_count = data.items_oic_count;
    state.items_oic = data.items_oic;
    state.items_oic_raw = data.items_oic_raw;
  },
  SET_STATS_OIC_ALERTINGS(state, data) {
    state.oic_alertings_count = data.oic_alertings_count;
    state.oic_alertings = data.oic_alertings;
    state.oic_alertings_raw = data.oic_alertings_raw;
  },
  SET_XAXIS_STATS_ITEMS_LOST(state, xaxisWeek) {
    state.xaxis_stats.xaxis_items_lost = xaxisWeek;
  },
  SET_XAXIS_STATS_ITEMS_FOUND(state, xaxisWeek) {
    state.xaxis_stats.xaxis_items_found = xaxisWeek;
  },
  SET_XAXIS_ITEMS_STATS_MATCH(state, xaxisWeek) {
    state.xaxis_stats.xaxis_match = xaxisWeek;
  },
  SET_XAXIS_STATS_OIC(state, xaxisWeek) {
    state.xaxis_stats.xaxis_items_oic = xaxisWeek;
  },
  SET_XAXIS_ITEMS_STATS_MATCH_MANUAL(state, xaxisWeek) {
    state.xaxis_stats.xaxis_match_manual = xaxisWeek;
  },
  SET_XAXIS_ITEMS_STATS_MATCH_DELIVERY(state, xaxisWeek) {
    state.xaxis_stats.xaxis_match_delivery = xaxisWeek;
  },
  SET_ECOLOG_COUNT(state, ecolog_count) {
    state.ecolog_count = ecolog_count;
  },
  SET_STATS_RESERVATIONS(state, reservations) {
    state.reservations = reservations;
  }
};
