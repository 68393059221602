import Chartist from 'chartist';
import 'chartist-plugin-legend';

const ctBarLabelsOption = {
  // The class name so you can style the text
  labelClass: 'ct-bar-label text-muted font-size-10',

  // Use this to get the text of the data and you can return your own
  // formatted text. For example, for a percentage:
  // {
  //  labelInterpolationFnc: function (text) { return text + '%' }
  // }
  labelInterpolationFnc(v, index) {
    if (v === 0 || parseInt(v, 10) === 0) {
      return '';
    }
    return v;
  },

  // Depending on your font size you may need to tweak these
  labelOffset: {
    x: 0,
    y: -4
  },

  // If labelOffset doesn't work for you and you need more custom positioning
  // you can use this. You can set position.x and position.y to functions and
  // instead of centering + labelOffset. This will _completely_ override the
  // built in positioning so labelOffset will no longer do anything. It will
  // pass the bar `data` back as the first param.
  //
  // Example:
  // Chartist.plugins.ctBarLabels({
  //   position: {
  //     x: function (data) {
  //       return data.x1 + 50; // align left with 50px of padding
  //     }
  //   }
  // });
  position: {
    x: null,
    y: null
  }
};

Chartist.plugins.ctBarLabels = function (option = ctBarLabelsOption) {
  const options = Chartist.extend({}, ctBarLabelsOption, option);

  const positionX =
    options.position.x ||
    function (data) {
      return (data.x1 + data.x2) / 2 + options.labelOffset.x;
    };

  const positionY =
    options.position.y ||
    function (data) {
      return data.y2 + options.labelOffset.y;
    };

  return function ctBarLabels(chart) {
    // Since it's specific to bars, verify its a bar chart
    if (chart instanceof Chartist.Bar) {
      chart.on('draw', function (data) {
        // If the data we're drawing is the actual bar, let's add the text
        // inside of it
        if (data.type === 'bar') {
          data.group
            .elem(
              'text',
              {
                // This gets the middle point of the bars and then adds the
                // optional offset to them
                x: positionX(data),
                y: positionY(data),
                style: 'text-anchor: middle',
                'font-size': '10px'
              },
              options.labelClass
            )
            .text(
              options.labelInterpolationFnc(
                // If there's not x (horizontal bars) there must be a y
                data.value.x || data.value.y,
                data.index
              )
            );
        }
      });
    }
  };
};

export default ({ app }, inject) => {
  inject('chartist', Chartist);
};
