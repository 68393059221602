const onError =
  ({ $config, $sentry, error, redirect, store, routeBaseName, i18n, route }) =>
  (err) => {
    const code = parseInt(err.response && err.response.status);
    if ($config.environment === 'production' && ![429, 401, 403, 418].includes(code)) {
      $sentry.setExtra('AxiosResponse', (err && err.response) || err);
      $sentry.setExtra('AxiosData', err && err.response && err.response.data);
      $sentry.setExtra('AxiosErrosJSON', err.toJSON && err.toJSON());
      if (store.state.user && store.state.user.user) $sentry.setUser({ ...store.state.user.user });
      try {
        $sentry.captureException(err);
      } catch (e) {
        // naaaaaah... it seems to happen
      }
    }

    // console.log('____');
    // console.log(routeBaseName, err.request, err.response);
    // console.log('____');

    if (code === 400) {
      return error({ statusCode: 400, message: '400 Bad Request\n' + (err && err.response && err.response.status) || '' });
    }
    if (code === 401) {
      if (routeBaseName && routeBaseName.includes('dashboard')) {
        store.commit('user/SET_TOKEN', null);
        if (store.state.user.teamId && !route.fullPath.match('login')) {
          const path = `/${i18n.locale || 'fr'}/login?${route.fullPath.includes('noRetry') ? 'noRetry=1&' : ''}nextLink=${route.fullPath}`;
          return process.isServer ? redirect(path) : window.location.replace(path);
        }
        return redirect(`/${i18n.locale || 'fr'}/login?noRetry=1`);
      }
    }
    if (routeBaseName && routeBaseName !== 'register' && code === 403) {
      return error({ statusCode: 403, message: '403 Forbidden' });
    }
  };

export default onError;
