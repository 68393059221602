// @lang
import { arSA, de, enGB, es, fr, it, nl, pt, ru, zhCN } from 'date-fns/locale';
import RemoveAccent from 'remove-accents';
import environment from '../config/environment';

/**
 * Get current hostname according environment constants
 * @returns {string}
 */
export const getEnvHostname = () => environment().HOST_NUXT;

/**
 * Default Metatags for SEO
 * @returns {*[]}
 */
export const getDefaultMetaTags = (hostname = null) => getGouvDefaultMetaTags(hostname);

export const getGouvDefaultMetaTags = (hostname = null) => [
  { hid: 'charset', charset: 'utf-8' }, // @todo: remove
  { hid: 'robots', name: 'robots', content: isDeployedInProduction() ? 'index,follow' : 'noindex,nofollow,max-snippet:-1' },
  { hid: 'googlebot', name: 'googlebot', content: isDeployedInProduction() ? 'index,follow' : 'noindex,nofollow,max-snippet:-1' },
  { hid: 'language', name: 'language', content: 'FR' }, // @todo: make id dynamic
  {
    hid: 'description',
    name: 'description',
    content: process.env.APP_DESCRIPTION || 'Solution de prise de rendez-vous des consulats'
  },
  {
    hid: 'keywords',
    name: 'keywords',
    content:
      process.env.APP_KEYWORDS ||
      "Rendez-vous consulat, Rendez-vous consulat amabassade, Rendez-vous consulat VISA, Rendez-vous consulat CNI, Rendez-vous carte d'identité, RDV consulat, RDV Ambassade, RDV Visa, RDV CNI, Service de prise de rendez-vous"
  },
  { hid: 'identifier-url', name: 'identifier-URL', content: hostname || getEnvHostname() },
  { hid: 'format-detection', name: 'format-detection', content: 'telephone=no' },
  { hid: 'reply-to', name: 'reply-to', content: process.env.APP_CONTACT || 'contact@diplomatie.gouv.fr' },
  {
    hid: 'copyright',
    name: 'copyright',
    content: `Copyright © Troov 2022 - ${new Date().getFullYear()} | All rights reserved to TROOV S.A.S.`
  },
  { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
  // { name: 'google-site-verification', content: 'CUc3wBA2kWgodvBLhrYPGQjRaXO6KoQGFEBMBVSgLmo' },
  {
    name: 'apple-mobile-web-app-title',
    content: process.env.APP_TITLE || 'Prise de rendez-vous - consulat.gouv.fr',
    hid: 'apple-mobile-web-app-title'
  },
  { name: 'apple-mobile-web-app-capable', content: 'yes', hid: 'apple-mobile-web-app-capable' },
  { name: 'apple-mobile-web-app-status-bar-style', content: 'black', hid: 'apple-mobile-web-app-status-bar-style' },
  { name: 'application-name', content: 'Troov Ton Créneau', hid: 'application-name' },
  { name: 'msapplication-TileColor', content: '#0909b9', hid: 'msapplication-TileColor' },
  { name: 'theme-color', content: '#0909b9', hid: 'theme-color' }
];

/**
 * Check if nuxt is running on a gouvprod or production server
 * @returns {boolean} false if running on local machine else false
 */
export const isDeployed = () => process.env.APP_CONFIG && process.env.APP_CONFIG !== 'development';

export const isDeployedInProduction = () => process.env.APP_CONFIG === 'production';

/**
 * Get x-forwarded-host
 * @param  {object} req
 * @return {String} x-forwarded-host
 */
export const getForwarded = (req) =>
  process.client ? window.location.href.split('/')[2] : req.headers['x-forwarded-host'] ? req.headers['x-forwarded-host'] : req.headers.host;

/**
 * Get hostname
 * @param  {object} req
 * @return {String} Hostname
 */
export const getHostname = (req) => (process.client ? window.location.href.split('/')[2] : req.headers.host);

/**
 * Replace all occurrences of a string
 * Regular Expression Based Implementation
 * @param target
 * @param search
 * @param replacement
 * @returns {string}
 */
export const replaceAll = (target, search, replacement) => {
  return target.replace(new RegExp(search, 'g'), replacement);
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false;
  }
  const d = new Date(str);
  return d.toISOString() === str;
};

export const removeDuplicates = function (array, key, duplicate = []) {
  const lookup = {};
  const result = [];
  for (let i = 0; i < array.length; i++) {
    if (!lookup[array[i][key]]) {
      lookup[array[i][key]] = true;
      result.push(array[i]);
    } else {
      duplicate.push(array[i]);
    }
  }
  return result;
};

export const animateScrollTo = (element, to, duration = 500) => {
  if (duration <= 0) return;
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;

  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    animateScrollTo(element, to, duration - 10);
  }, 10);
};

export const getBootstrapBreakpoint = () => {
  const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return w < 768 ? 'xs' : w < 992 ? 'sm' : w < 1200 ? 'md' : 'lg';
};

export const emptyImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

export const capitalize = (str = '') => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;

export const capitalizeAll = (str) =>
  typeof str === 'string'
    ? str
        .split(' ')
        .map((word) => capitalize(word))
        .join(' ')
    : str;

export const removeSpace = (text) => text.replace(/ /g, '');

// @lang
export const getDateFnsLocales = (locale) => {
  switch (locale) {
    case 'ar':
      return arSA;
    case 'de':
      return de;
    case 'en':
      return enGB;
    case 'es':
      return es;
    case 'fr':
      return fr;
    case 'it':
      return it;
    case 'nl':
      return nl;
    case 'pt':
      return pt;
    case 'ru':
      return ru;
    case 'zh':
      return zhCN;
    default:
      return fr;
  }
};

export const fixNum = (m) => {
  const val = parseInt(m, 10);
  return !val ? '00' : m < 10 ? `0${val}` : `${val}`;
};
export function slugify(str = '', shouldRemoveInvalidChars = true) {
  const map = {
    a: 'à|â|ª|æ|á|ä|ã|å|ā|À|Â|ª|Æ|Á|Ä|Ã|Å|Ā',
    e: 'é|è|ê|ë|ę|ė|ē|É|È|Ê|Ë|Ę|Ė|Ē',
    i: 'î|ï|ì|í|į|ī|Î|Ï|Ì|Í|Į|Ī',
    o: 'ô|œ|º|ö|ò|ó|õ|ø|ō|Ô|Œ|º|Ö|Ò|Ó|Õ|Ø|Ō',
    u: 'û|ù|ü|ú|ū|Û|Ù|Ü|Ú|Ū',
    y: 'ÿ',
    c: 'ç|ć|č|Ç|Ć|Č',
    n: 'ñ|ń|Ñ|Ń'
  };
  for (const pattern in map) {
    str = str
      .replace(/[-'`~!@#$%^&*()_|+=?;·:",.<>{}[\]\\/]/gi, '-') // remove invalid non chars
      .replace(new RegExp(map[pattern], 'g'), pattern)
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes;

    if (shouldRemoveInvalidChars) {
      str = str.replace(/[^a-z0-9 -]/g, ''); // remove invalid chars
    }
  }
  return str;
}

export const slugifyClean = (text, removeSpaces = true) => {
  let str = RemoveAccent(text).replace(/[-'`~!@#$%^&*()_|+=?;·:",.<>{}[\]\\/]/gi, '-');
  if (removeSpaces) {
    str = str
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-');
  }
  return str;
};

export function sanitizeToId(str) {
  return slugify(str, false).toLowerCase();
}

export const safeCopy = (value) => (value ? JSON.parse(JSON.stringify(value)) : value);

export const getSlotValueForFamilyReservations = (service, slot) => {
  return sanitizeToId(`slot-${service.name}-${new Date(slot.date).toISOString()}-${slot.time}`);
};

export const getGoogleMapsLink = (team) => {
  let query = '';
  if (team.address) {
    query += team.address;
  }
  if (team.city) {
    query += `,${team.city}`;
  }
  if (team.country) {
    query += `,${team.country}`;
  }
  return `https://www.google.com/maps/search/?api=1&query=${query}`;
};

export const arrayRange = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

function throttle(callback, delay) {
  let last;
  let timer;
  return function () {
    const context = this;
    const now = +new Date();
    const args = arguments;
    if (last && now < last + delay) {
      // le délai n'est pas écoulé on reset le timer
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        callback.apply(context, args);
      }, delay);
    } else {
      last = now;
      callback.apply(context, args);
    }
  };
}
