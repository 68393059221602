export default {
  firstReservation: (state, getters) => {
    return (state.currentParams.length > 0 && { value: {}, ...state.currentParams[0] }) || { value: {} };
  },
  currentParams: (state, getters) => {
    return state.currentParams;
  },
  captcha(state, getters) {
    return state.captcha;
  },
  _csrf(state, getters) {
    return state.cs.value;
  }
};
