export default {
  getEntities({ commit, state }) {
    const contact = this.app.$config.API_HOST;
    return this.$axios.get(`${contact}/entities`).then(({ data }) => {
      commit('SET_ENTITIES_TYPE', data);
      return data;
    });
  },
  sendDemoEmail({ commit, state }, body) {
    const { _csrf, ...query } = body;
    return this.$axios.post(`${this.app.$config.API_HOST}/contact.demo.email`, query, {
      headers: {
        'x-csrf-token': _csrf
      }
  });
  },
  sendWhiteBookEmail({ commit, state }, body) {
    const { _csrf, ...query } = body;
    return this.$axios.post(`${this.app.$config.API_HOST}/contact.whitebook.email`, query, {
      headers: {
        'x-csrf-token': _csrf
      }
    });
  }
};
