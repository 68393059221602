export default {
  /**
   * Development configuration
   * Use a function to be sure to use runtime configuration for process.env.API_PREFIX
   * @return {{API_HOST: string, API_ROOT: string, env: 'development', HOST_NUXT: string, "x-gouv-web": string}}
   */
  config: () => ({
    API_HOST: process.env.API_HOST || 'http://localhost:8080/api',
    API_ROOT: process.env.API_ROOT || 'http://localhost:8080',
    HOST_NUXT: process.env.HOST_NUXT || 'http://localhost:3000',
    env: 'development',
    'x-gouv-web': 'fr.gouv.consulat'
  })
};
