const sessionIdStorageName = 'resa-sid';

const addSessionIdToLocalStorage = (sessionId) => {
  if (process.client) {
    if (sessionId) window.localStorage.setItem(sessionIdStorageName, sessionId);
  }
};

export const removeSessionIdFromLocalStorage = () => {
  if (process.client) {
    return window.localStorage.removeItem(sessionIdStorageName);
  }
};

export default {
  createReservationsSession({ commit, state, rootState }, { team, standaloneServiceName, sessionId, captcha }) {
    return this.$axios
      .post(
        `${this.app.$config.API_HOST}/team/${team}/reservations-session`,
        { standaloneServiceName, sessionId, captcha },
        {
          headers: {
            'x-csrf-token': rootState.user.cs.value
          }
        }
      )
      .then(({ data, headers }) => {
        commit('SET_RESERVATIONS_SESSION', data);
        commit('user/SET_HANDSHAKE', { value: headers[this.app.$config.handshakeHeader] }, { root: true });
        addSessionIdToLocalStorage((data && data._id) || undefined);
        return data;
      });
  },

  getReservationsSession({ commit, dispatch, state }, { team, sessionId, standaloneServiceName }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${team}/reservations-session`, {
        params: { sessionId, standaloneServiceName }
      })
      .then(({ data = {} }) => {
        const { session } = data;
        if (session) {
          commit('SET_RESERVATIONS_SESSION', session);
          addSessionIdToLocalStorage(session._id);
        } else {
          removeSessionIdFromLocalStorage(sessionId);
        }
        return data;
      });
  },

  updateReservationsSessionStep({ commit, state }, { team, origin, value, stepIndex, dynamicStepIndex }) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${team}/reservations-session/${state._id}/update-step-value`, {
        key: origin,
        value,
        stepIndex,
        dynamicStepIndex
      })
      .then(() => {
        commit('SET_RESERVATIONS_SESSION_STEP', { team, key: origin, value, stepIndex, dynamicStepIndex });
      });
  },

  updateReservationsSessionDynamicSteps({ commit, state }, { team, key, steps }) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${team}/reservations-session/${state._id}/update-dynamic-steps`, {
        key,
        steps
      })
      .then(() => {
        commit('SET_RESERVATIONS_SESSION_DYNAMIC_STEPS', { key, steps });
      });
  },
  resetReservationsSession({ commit, state }) {
    commit('REMOVE_RESERVATIONS_SESSION');
  },
  addFamilyReservations({ commit, state, rootState }, { sessionId, reservations, team, captcha, locale }) {
    return this.$axios
      .post(
        `${this.app.$config.API_HOST}/team/${team}/reservations/family`,
        {
          reservations,
          language: locale,
          captcha,
          sessionId
        },
        {
          headers: {
            'x-csrf-token': rootState.user.cs.value
          }
        }
      )
      .then(({ data }) => {
        removeSessionIdFromLocalStorage();
        commit('SET_RESERVATION_SUMMARY', data.qrCodes);
        return data;
      });
  },
  getFamilyIcs({ state }, { team, locale }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${team}/reservations/family/${state._id}/ics`, {
        params: { language: locale },
        responseType: 'blob'
      })
      .then(({ data }) => {
        return data;
      });
  },
  updateReservationsSessionServices({ commit, state }, { services }) {
    commit('SET_RESERVATIONS_SESSION_SERVICES', { services });
  },
  updateStepIndex({ commit, state }, { stepIndex }) {
    commit('SET_RESERVATIONS_SESSIONS_STEP_INDEX', { stepIndex });
  },
  rateReservation({ state }, { team, rating, comment }) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/reservations/team/${team}/rate`, { rating, sessionId: state._id, comment })
      .then(({ data }) => {
        return data;
      });
  },
  updateServicesStepApplicantsPerService({ commit, state }, { numberOfSlots, zone_id }) {
    commit('SET_SERVICES_STEP_APPLICANTS_PER_SERVICE', { zone_id, numberOfSlots });
  },
  updateServicesStepApplicants({ commit, state }, { numberOfApplicants }) {
    commit('SET_SERVICES_STEP_APPLICANTS', { numberOfApplicants });
  }
};
