export default () => ({
  teams: [],
  team: {
    name: '',
    type: '',
    loc: {
      coordinates: ['', '']
    }
  }
});
