export default {
  SET_RESERVATIONS_SESSION(state, reservationsSession) {
    if (reservationsSession) {
      state._id = reservationsSession._id;
      state.team = reservationsSession.team;
      state.servicesStep = reservationsSession.servicesStep;
      state.importantInformationStep = reservationsSession.importantInformationStep;
      state.numberOfApplicantsStep = reservationsSession.numberOfApplicantsStep;
      state.confirmationSummaryStep = reservationsSession.confirmationSummaryStep;
      state.askConfirmationStep = reservationsSession.askConfirmationStep;
      state.slotsSteps = reservationsSession.slotsSteps;
      state.mainContactDetailsStep = reservationsSession.mainContactDetailsStep;
      state.secondaryContactsDetailsStep = reservationsSession.secondaryContactsDetailsStep;
      state.stepIndex = reservationsSession.stepIndex;
      state.standalone_service_name = reservationsSession.standalone_service_name;
    } else {
      state._id = '';
      state.servicesStep = null;
      state.importantInformationStep = null;
      state.team = '';
      state.numberOfApplicantsStep = null;
      state.confirmationSummaryStep = null;
      state.askConfirmationStep = null;
      state.slotsSteps = [];
      state.mainContactDetailsStep = null;
      state.secondaryContactsDetailsStep = null;
      state.stepIndex = 0;
      state.standalone_service_name = '';
    }
  },
  SET_RESERVATIONS_SESSION_STEP(state, { key, value, stepIndex, dynamicStepIndex }) {
    state.stepIndex = stepIndex;
    if (dynamicStepIndex !== undefined) {
      state[key + 's'][dynamicStepIndex].value = value;
    } else {
      state[key].value = value;
    }
  },
  SET_RESERVATIONS_SESSION_DYNAMIC_STEPS(state, { key, steps }) {
    state[key] = steps;
  },
  SET_RESERVATIONS_SESSION_SERVICES(state, { services }) {
    state.servicesStep.value.services = services;
  },
  SET_RESERVATIONS_SESSIONS_STEP_INDEX(state, { stepIndex }) {
    state.stepIndex = stepIndex;
  },
  SET_RESERVATION_SUMMARY(state, qrCodes) {
    state.confirmationSummaryStep.value = { qrcodes: qrCodes };
  },
  REMOVE_RESERVATIONS_SESSION(state) {
    state._id = '';
    state.team = '';
    state.servicesStep = null;
    state.importantInformationStep = null;
    state.numberOfApplicantsStep = null;
    state.confirmationSummaryStep = null;
    state.askConfirmationStep = null;
    state.slotsSteps = [];
    state.mainContactDetailsStep = null;
    state.secondaryContactsDetailsStep = null;
    state.stepIndex = 0;
    state.standalone_service_name = '';
  },
  SET_SERVICES_STEP_APPLICANTS(state, { numberOfApplicants }) {
    state.servicesStep.value.numberOfApplicants = numberOfApplicants;
  },
  SET_SERVICES_STEP_APPLICANTS_PER_SERVICE(state, { numberOfSlots, zone_id }) {
    const indexToUpdate = state.servicesStep.value.services.findIndex((service) => service.zone_id === zone_id);
    if (indexToUpdate !== -1) {
      state.servicesStep.value.services[indexToUpdate].numberOfSlots = numberOfSlots;
    }
    const indexSlotStep = state.slotsSteps.findIndex((service) => service.zone_id === zone_id);
    if (indexSlotStep !== -1) {
      state.slotsSteps[indexSlotStep].numberOfSlots = numberOfSlots;
    }
  }
};
