import { getIp, hasStrangeBehavior, isBlackListedYaounde } from '~/helpers/ipHelpers';

export default async function ({ store, req, error, route }) {
  const ip = getIp(req);
  if (ip) {
    const isBadIpSentry = hasStrangeBehavior(ip);
    const isBadIp = isBlackListedYaounde(ip) || isBlackListedYaounde(ip);
    if (isBadIpSentry || isBadIp) {
      console.log('Intercept blacklisted ip', ip);
      return error({ statusCode: 403, message: 'Blocket IP' });
    }
    try {
      await store.dispatch('ip/checkIfIpIsBlocked', { ip });
    } catch (err) {
      //
      console.log('badIp +++ -> ip/checkIfIpIsBlocked', err, ip);
      if (err.message.includes('ECONNREFUSED')) return error({ statusCode: 417, message: 'Unavailable' });
      //
      console.log('badIp * -> ip/checkIfIpIsBlocked', err, ip);
      return error({ statusCode: 403, message: 'Blocked IP' });
    }
  }
}
