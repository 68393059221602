<template>
  <div class="account-pages my-5 pt-5 100-vh">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="h2 text-primary font-weight-bold text-uppercase">
              {{ $t('blocked_ip.no_access_title') }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-7 py-md-5 gouv">
          <div class="mx-md-5 items-troov">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 3303 1668"
              style="enable-background: new 0 0 3303 1668"
              xml:space="preserve"
            >
              <g id="Calque_1" class="st0">
                <rect x="7193.5" y="3268.7" class="st1" width="1284" height="2845.5" />
                <rect x="7193.5" y="3289" class="st2" width="1284" height="173.1" />
                <rect x="7193.5" y="5893.9" class="st2" width="1284" height="173.1" />
              </g>
              <g id="Calque_17"></g>
              <g id="Calque_18" class="st0">
                <path class="st3" d="M8054.4,5395.3h-438.6c-10,0-18.2-8.1-18.2-18.2v-367.5" />
              </g>
              <g id="Calque_3">
                <g>
                  <g>
                    <g>
                      <path
                        class="st4"
                        d="M1806.5,1083.1c-105.3-17.4-208.5,41.4-295.2,146.2c-8.1,3.3-16.7,5.9-25.6,7.8
					c-47.3,9.8-93.9-4.2-127.5-34c-13.6,7.5-28.5,13.2-44.5,16.5c-85.5,17.8-169.4-37.1-187.2-122.6
					c-17.8-85.5,37.1-169.4,122.6-187.2c-4.3-20.7-3-41.3,3-60.1l74,204.9c8.1,22.5,32.9,34.1,55.3,26L1787.2,934
					c17.5,15.9,30.3,37.3,35.5,62.2C1829.3,1027.4,1822.6,1058.3,1806.5,1083.1z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st4"
                        d="M1629.9,416.1c-8.3,3-17.5-1.3-20.5-9.6c-1.9-5.2-0.9-10.8,2.2-15l1.5,4.3c1.6,4.6,6.7,6.9,11.3,5.3
					c4.6-1.6,6.9-6.7,5.3-11.3l-1.5-4.3c5,1.2,9.4,4.9,11.3,10.1C1642.5,403.9,1638.2,413.1,1629.9,416.1z"
                      />
                    </g>
                    <g>
                      <path
                        class="st4"
                        d="M2017.6,416.7c-2,4.6-4.2,8.9-6.7,13.1c6.8,24.1,5.5,51.3-5.6,76.8c-22.2,51.1-75.6,78.2-124.1,65.3
					c-1.8-0.5-3.7-1-5.5-1.6l-81-224.2c-8.1-22.5-32.9-34.1-55.3-26l-119.2,43.1l-11.4-31.6c-1.3-3.6-4.7-5.8-8.3-5.8
					c-1,0-2,0.2-3,0.5c-4.6,1.7-6.9,6.7-5.3,11.3l11.4,31.6L1546,390l-11.4-31.6c-1.3-3.6-4.7-5.8-8.3-5.8c-1,0-2,0.2-3,0.5
					c-1.5,0.5-2.7,1.4-3.7,2.5c-0.2-0.7-0.4-1.5-0.6-2.2c-1.3-4.7-2.2-9.5-2.6-14.3c-1.4-13.9,0.6-28.2,6.5-41.7
					c13.1-30.2,42-48.8,73.9-51.3c0.6-1.5,1.1-2.9,1.8-4.4c20.5-47.2,70.6-71.9,116.9-59.9c29.6-51.2,91.6-74,144.9-50.9
					c50.1,21.7,75.9,77,64.4,130.7c13.1,0.3,26.2,3,38.8,8.5C2018.2,293.8,2042.4,359.5,2017.6,416.7z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Calque_25"></g>
              <g id="Calque_11"></g>
              <g id="Calque_12"></g>
              <g id="Calque_10" class="st0"></g>
              <g id="Calque_4">
                <path
                  class="st5"
                  d="M8313.1,4501h-958.2c-27.4,0-49.5-22.2-49.5-49.5v-826.3c0-27.4,22.2-49.5,49.5-49.5h958.2
		c27.4,0,49.5,22.2,49.5,49.5v826.3C8362.7,4478.8,8340.5,4501,8313.1,4501z"
                />
              </g>
              <g id="Calque_8" class="st0">
                <g class="st6">
                  <path
                    class="st7"
                    d="M7973,5444.9H7698c-6.3,0-11.4-5.1-11.4-11.4v-274.9c0-6.3,5.1-11.4,11.4-11.4h136.5H7973
			c6.3,0,11.4,5.1,11.4,11.4v274.9C7984.4,5439.8,7979.3,5444.9,7973,5444.9z"
                  />
                  <line class="st7" x1="7783.2" y1="5147.2" x2="7783.2" y2="5444.9" />
                  <line class="st7" x1="7883" y1="5147.2" x2="7883" y2="5444.9" />
                  <line class="st7" x1="7685.4" y1="5249.2" x2="7985.6" y2="5249.2" />
                  <line class="st7" x1="7685.4" y1="5349.7" x2="7985.6" y2="5349.7" />
                </g>
              </g>
              <g id="Calque_13"></g>
              <g id="Calque_7"></g>
              <g id="Calque_5"></g>
              <g id="Calque_14"></g>
              <g id="Calque_6">
                <g id="Calque_41" class="st0">
                  <rect x="7867.6" y="4100" class="st8" width="572.2" height="34.1" />
                </g>
                <g id="Calque_15"></g>
                <g id="Calque_21"></g>
                <g id="Calque_16">
                  <path
                    class="st9"
                    d="M8085.8,5089.8v241c0,10.8-11.9,19.6-26.6,19.6H7613c-14.7,0-26.6-8.8-26.6-19.6V5029
			c0-10.8,11.9-19.6,26.6-19.6h262.2h93.2"
                  />
                </g>
              </g>
              <g id="Calque_26"></g>
              <g id="Calque_22" class="st0">
                <line class="st10" x1="7833.7" y1="4914.7" x2="7833.7" y2="4880.4" />
                <line class="st10" x1="7747.4" y1="4915" x2="7771.7" y2="4939.2" />
                <line class="st10" x1="7896.9" y1="4939.2" x2="7921.2" y2="4915" />
                <line class="st3" x1="7835.7" y1="4917.7" x2="7835.7" y2="4883.4" />
                <line class="st3" x1="7749.4" y1="4918" x2="7773.7" y2="4942.2" />
                <line class="st3" x1="7898.9" y1="4942.2" x2="7923.2" y2="4918" />
              </g>
              <g id="Calque_19"></g>
              <g id="Calque_40" class="st0">
                <g class="st11">
                  <g>
                    <path
                      class="st7"
                      d="M8095.8,5234.7c-17.9,0.9-35.7,3.1-53.3,6.5c-17.6,3.4-34.9,8.1-51.8,14c-8.5,3-16.8,6.2-25.1,9.8
				c-7.8,3.4-15.7,6.8-23,11c-6.5,3.8-12.6,9.1-14.4,16.7c-1.7,7.3,0.7,15,5.2,20.9c10.5,13.7,30.1,15.9,46,16.9
				c19.2,1.2,38.5-0.9,57.5-3.6c16.7-2.4,33.9-4.5,49.8-10.4c7.7-2.8,15.2-6.6,21.6-11.7c5.9-4.7,11-12.8,6.4-20.2
				c-3.9-6.3-12-8.3-18.8-9.6c-9-1.6-18.1-2.1-27.3-1.6c-18.5,1-36.6,5.7-54.1,11.3c-8.3,2.7-16.6,5.4-24.7,8.6
				c-6.5,2.5-12.9,5.3-18.6,9.3c-5.4,3.7-10.3,8.5-13,14.5c-3.1,7-3.1,14.8-0.2,21.9c6.6,16.1,22.9,23.6,38.5,28.5
				c17.6,5.5,36,8.8,54.4,9.8c9.1,0.5,18.2,0.4,27.2-0.3c8.6-0.7,17.3-1.9,25.6-4.7c4-1.3,8-3,11.7-5c3.4-1.9,6.5-4.2,8.7-7.5
				c2.1-3,3-7,1.2-10.4c-1.9-3.6-5.9-4.9-9.7-5.1c-4-0.2-8.2,0.2-12.2,0.4c-4.4,0.2-8.8,0.6-13.2,1.2c-8.7,1.1-17.3,3.1-25.2,7
				c-13.1,6.3-24.3,18.8-25.6,33.7c-1.4,15.7,9.4,29,21,38.1c13.3,10.5,28.8,18.4,44.8,24c7.9,2.8,16,4.9,24.2,6.4
				c1.3,0.2,2.7-0.4,3.1-1.7c0.3-1.2-0.4-2.8-1.7-3.1c-16-2.9-31.6-8.3-46-15.9c-7-3.7-13.8-7.9-20.1-12.6
				c-5.6-4.3-10.9-9.3-14.8-15.2c-3.7-5.7-6-12.2-5.6-19c0.4-6.4,3.2-12.6,7.2-17.6c9.6-12.1,24.4-17.1,39.2-19
				c4.2-0.5,8.3-0.9,12.5-1.1c3.9-0.2,7.8-0.6,11.7-0.5c2.5,0.1,6.1,0.6,6.4,3.7c0.3,2.5-2.2,5.4-4.1,7.1c-2.7,2.4-6.3,4-9.6,5.4
				c-3.7,1.6-7.5,2.7-11.3,3.7c-7.8,1.9-15.9,2.7-23.9,3.1c-17.5,0.8-35-0.5-52.1-4c-8.5-1.7-16.9-3.9-25.1-6.6
				c-7.4-2.5-14.8-5.5-21.1-10.2c-5.7-4.3-10.4-10.3-12.3-17.3c-1.7-6.2-0.8-12.7,2.7-18.1c3.2-5.1,8.2-8.9,13.3-11.9
				c5.5-3.2,11.5-5.6,17.5-7.8c16.5-5.9,33.3-11.6,50.5-15.1c16.7-3.4,34.2-4.7,51-1.2c5.2,1.1,13.1,3,14.6,8.9
				c0.8,3.2-0.8,6.3-2.9,8.6c-2.4,2.8-5.4,4.9-8.5,6.8c-13.2,8.3-28.7,11.8-43.9,14.3c-17.4,3-35,5.6-52.7,6.5
				c-9,0.5-18.1,0.5-27.1-0.2c-8-0.6-16.2-1.7-23.8-4.4c-6.7-2.4-13-6.3-16.6-12.6c-3.2-5.4-4.5-12.3-1.4-18.1
				c3.3-6.1,9.8-9.6,15.8-12.6c7.5-3.8,15.2-7.3,23-10.5c15.6-6.4,31.7-11.8,48.1-16.1c16.4-4.3,33-7.4,49.9-9.4
				c8.2-1,16.4-1.6,24.6-2C8099,5239.5,8099.1,5234.5,8095.8,5234.7L8095.8,5234.7z"
                    />
                  </g>
                </g>
                <g class="st11">
                  <g>
                    <path
                      class="st7"
                      d="M7614.1,5194.5c3.9,1.8,8.3,3.7,11.1,7c2.3,2.8,2,6.1-0.1,8.9c-2.5,3.4-6.6,5.2-10.6,6.3
				c-4.7,1.3-9.5,1.7-14.4,1.9c-22,0.8-43.9-3.2-65.4-7.3c-9.6-1.9-19.3-3.9-28.5-7.4c-4.3-1.7-8.5-3.7-12.3-6.3
				c-1.6-1.1-3.4-2.5-4.1-4.3c-0.5-1.3,0.2-2.1,1.1-2.8c3.3-2.3,8.2-2.4,12-2.6c5.4-0.3,10.7-0.2,16.1,0.3c10.7,0.9,21.2,3.2,31.6,6
				c10.1,2.8,20.1,6.1,30,9.6c3.9,1.4,7.8,2.9,11.5,4.9c3.3,1.7,6.6,3.8,9.1,6.7c2,2.3,3.2,5.2,2.4,8.3c-1,3.9-4.2,6.9-7.5,8.9
				c-4,2.5-8.6,3.9-13.1,5c-5,1.2-10.2,2-15.3,2.6c-10.8,1.4-21.7,1.9-32.5,1.7c-10.6-0.2-21.3-1-31.8-3.1c-4.9-1-9.8-2.2-14.5-4.1
				c-2.1-0.8-4.2-1.7-6.1-2.9c-1.4-0.9-3.1-2.2-3.5-3.7c-0.1-0.3,0-0.1,0,0c0,0.2,0.1-0.3,0-0.2c0,0,0.3-0.3,0.4-0.4
				c0.7-0.4,1.6-0.5,2.4-0.5c2.4-0.1,4.8,0.3,7.2,0.5c5,0.5,10.1,1.1,15,2.1c9.2,1.7,18.8,4.5,25.9,11c2.7,2.5,5.1,5.6,6,9.3
				c0.8,3.2-0.2,6.5-2.1,9.3c-2.3,3.3-5.7,5.7-9.1,7.8c-3.9,2.3-8.1,4.2-12.4,5.9c-9.6,3.7-19.5,6.3-29.7,8c-5,0.8-10,1.4-15.1,1.7
				c-1.3,0.1-2.5,1.1-2.5,2.5c0,1.3,1.1,2.6,2.5,2.5c11.3-0.7,22.6-2.6,33.6-5.7c5.2-1.5,10.3-3.2,15.3-5.3c4.7-1.9,9.4-4.1,13.5-7
				c3.7-2.6,7.2-5.7,9.3-9.8c2-3.8,2.5-8.2,1.1-12.4c-3.1-9.4-12.4-15.4-21.2-18.8c-10.4-4-21.7-5.1-32.7-6.3
				c-4.2-0.4-11.5-0.9-12.5,4.8c-0.8,5.1,4.8,8.6,8.6,10.5c10,4.9,21.2,6.8,32.2,8c11.7,1.3,23.6,1.6,35.4,1.1
				c11.5-0.6,23.3-1.7,34.5-4.5c9.1-2.3,20.7-7,23.2-17.2c2.2-8.9-5.1-15.8-12.2-19.8c-8.9-5-19-7.9-28.6-11.1
				c-21.9-7.2-45.2-13.2-68.5-10.9c-4.3,0.4-9.6,1.2-12.6,4.6c-3.8,4.2-1.1,9.4,2.7,12.5c4,3.3,8.8,5.6,13.6,7.6
				c4.9,2,10,3.6,15.1,4.9c10.9,2.8,22,4.7,33.1,6.6c11.9,2,23.9,3.7,36,4.2c5.8,0.2,11.6,0.3,17.4-0.2c5.5-0.4,10.9-1.3,16-3.5
				c4.3-1.8,8.4-4.9,10.3-9.2c2-4.5,1.2-9.1-2-12.8c-3.1-3.7-7.8-5.9-12.2-7.8c-1.2-0.6-2.7-0.4-3.4,0.9
				C7612.6,5192.2,7612.9,5193.9,7614.1,5194.5L7614.1,5194.5z"
                    />
                  </g>
                </g>
              </g>
              <g id="Calque_9"></g>
              <g id="Calque_20"></g>
              <g id="Calque_23" class="st0">
                <circle class="st12" cx="7836.2" cy="5004.6" r="86.5" />
                <path
                  class="st13"
                  d="M7876.7,5057.1h-82.2c-6.3,0-11.4-5.1-11.4-11.4v-82.2c0-6.3,5.1-11.4,11.4-11.4h82.2
		c6.3,0,11.4,5.1,11.4,11.4v82.2C7888.1,5052,7883,5057.1,7876.7,5057.1z"
                />
                <line class="st13" x1="7817.9" y1="4953" x2="7817.9" y2="5058" />
                <line class="st13" x1="7854.1" y1="4953" x2="7854.1" y2="5058" />
                <line class="st13" x1="7783.5" y1="5023.6" x2="7888.5" y2="5023.6" />
                <line class="st13" x1="7783.5" y1="4987.4" x2="7888.5" y2="4987.4" />
                <circle class="st12" cx="7835.6" cy="5004.3" r="29.2" />
                <polyline class="st3" points="7823.6,5004.3 7835.6,5014.5 7847.9,4995.4 	" />
              </g>
              <g id="Calque_24"></g>
              <g id="Calque_30"></g>
              <g id="Calque_31"></g>
              <g id="Calque_32"></g>
              <g id="Calque_35"></g>
              <g id="Calque_34"></g>
              <g id="Calque_33"></g>
              <g id="Calque_27">
                <rect x="446" y="3141" style="fill: none" width="2475" height="1372.5" />
                <text transform="matrix(1 0 0 1 446.0158 3891.0068)" class="st14" style="font-family: 'Marianne-ExtraBold'; font-size: 1000px">
                  403
                </text>
                <g>
                  <g>
                    <path class="st14" d="M497.5,779.5l278-374h194v374h64v152h-64v174h-169v-174h-303V779.5z M800.5,779.5v-177l-134,177H800.5z" />
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st14"
                        d="M1460.2,689.3c-1.1-3.4-2.4-6.6-3.8-9.6c-7.5,1.2-16.4-3.8-21.6-12.9c-2.6-4.5-3.9-9.3-3.9-13.8
					c-1.9-0.3-3.8-0.2-5.5,0.4c-11.5,3.8-14.4,26-6.6,49.6c7.8,23.6,23.4,39.7,34.9,35.9S1468,712.9,1460.2,689.3z M1652.2,621.8
					c-1.5-4.5-3.2-8.7-5.2-12.6c-0.3,0.2-0.6,0.5-1,0.6c-8,4.6-19.7-0.4-26-11.4c-2.3-4-3.6-8.2-3.9-12.2
					c-10.5,4.9-13,26.4-5.4,49.2c7.8,23.6,23.4,39.7,34.9,35.9S1660,645.4,1652.2,621.8z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st14"
                        d="M1967,823.1L1883,590.7l-7.4-20.4l-81-224.2c-8.1-22.5-32.9-34.1-55.3-26l-119.2,43.1l-11.4-31.6
					c-1.3-3.6-4.7-5.8-8.3-5.8c-1,0-2,0.2-3,0.5c-4.6,1.7-6.9,6.7-5.3,11.3l11.4,31.6L1546,390l-11.4-31.6c-1.3-3.6-4.7-5.8-8.3-5.8
					c-1,0-2,0.2-3,0.5c-1.5,0.5-2.7,1.4-3.7,2.5c-0.6,0.7-1.1,1.5-1.4,2.3c-0.9,2-1,4.3-0.2,6.4l2.8,7.9l4.8,13.4l3.7,10.3
					l-57.6,20.8l-11.4-31.6c-1.3-3.6-4.7-5.8-8.3-5.8c-1,0-2,0.2-3,0.5c-4.6,1.6-6.9,6.7-5.3,11.2l11.4,31.6l-57.2,20.6l-11.4-31.6
					c-1.3-3.6-4.7-5.8-8.3-5.8c-1,0-2,0.2-3,0.5c-4.6,1.6-6.9,6.7-5.3,11.3l11.4,31.6l-58,20.9l-11.4-31.6c-1.3-3.6-4.7-5.8-8.3-5.8
					c-1,0-2,0.2-3,0.5c-4.6,1.6-6.9,6.7-5.3,11.3l11.4,31.6l-127.4,46c-22.4,8.1-34.1,32.9-26,55.3l98.3,272.1l74,204.9
					c8.1,22.5,32.9,34.1,55.3,26L1787.2,934l153.8-55.6C1963.5,870.3,1975.1,845.5,1967,823.1z M1333.5,523.2
					c-8.3,3-17.5-1.3-20.5-9.6c-1.9-5.3-0.9-10.9,2.2-15l1.6,4.3c1.7,4.6,6.7,6.9,11.3,5.3c4.6-1.6,6.9-6.7,5.3-11.2l-1.5-4.3
					c5,1.2,9.4,4.9,11.3,10.1C1346.1,511,1341.8,520.2,1333.5,523.2z M1476.2,454.6c4.6-1.6,6.9-6.7,5.3-11.3l-1.6-4.3
					c5.1,1.2,9.4,4.9,11.3,10.1c3,8.3-1.3,17.5-9.6,20.5s-17.5-1.3-20.5-9.6c-1.9-5.3-0.9-10.9,2.2-15l1.6,4.3
					C1466.6,453.9,1471.7,456.2,1476.2,454.6z M1387.5,486.6c-1.9-5.2-0.9-10.9,2.2-15l1.6,4.3c1.6,4.6,6.7,6.9,11.3,5.3
					c4.6-1.7,6.9-6.7,5.3-11.3l-1.5-4.3c5.1,1.2,9.4,4.9,11.3,10.1c3,8.3-1.3,17.5-9.6,20.5C1399.7,499.3,1390.5,494.9,1387.5,486.6
					z M1478.7,774.4c-23.5,7.8-55.4-25.2-71.4-73.5c-16-48.4-9.9-93.9,13.5-101.6c23.5-7.8,55.5,25.2,71.4,73.5
					C1508.3,721.1,1502.2,766.6,1478.7,774.4z M1611.6,391.5l1.5,4.3c1.6,4.6,6.7,6.9,11.3,5.3c4.6-1.6,6.9-6.7,5.3-11.3l-1.5-4.3
					c5,1.2,9.4,4.9,11.3,10.1c3,8.3-1.3,17.5-9.6,20.5c-8.3,3-17.5-1.3-20.5-9.6C1607.5,401.2,1608.5,395.6,1611.6,391.5z
					 M1534.9,433.4c-2-5.4-0.8-11.2,2.5-15.4l1.6,4.6c1.6,4.6,6.7,6.9,11.2,5.3c2.6-1,4.5-3,5.4-5.5c0.6-1.8,0.6-3.8-0.1-5.8l-1.5-4
					c4.9,1.3,9,4.9,10.8,10c0.8,2.1,1.1,4.3,0.9,6.4c-0.4,6.2-4.3,11.9-10.5,14.1C1547.1,446,1537.9,441.7,1534.9,433.4z
					 M1639.5,893c-1.3,0.6-2.6,1.3-4,1.9c-18.4,8.2-37.4,10.2-53.4,6.7c-7.6-1.7-14.5-4.5-20.3-8.6c-6.4-4.4-11.4-10.2-14.6-17.3
					c-2-4.5-3.2-9.2-3.5-14c-1.6-24.2,17.3-51,47.9-64.7c36.7-16.5,76.2-7.9,88.4,19.1C1691.6,842.2,1673.6,875.9,1639.5,893z
					 M1667.7,705.4c-23.5,7.8-55.4-25.2-71.4-73.5c-16-48.4-9.9-93.9,13.5-101.6c23.5-7.8,55.4,25.2,71.4,73.5
					C1697.3,652.1,1691.2,697.6,1667.7,705.4z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st7"
                      d="M1492.3,672.7c-16-48.4-48-81.3-71.4-73.5c-23.5,7.8-29.5,53.2-13.5,101.6c16,48.4,48,81.3,71.4,73.5
				C1502.2,766.6,1508.3,721.1,1492.3,672.7z M1453.6,739c-11.5,3.8-27.1-12.3-34.9-35.9c-7.8-23.6-4.9-45.9,6.6-49.6
				c1.8-0.6,3.6-0.7,5.5-0.4c0,4.4,1.3,9.2,3.9,13.8c5.2,9.1,14.2,14.1,21.6,12.9c1.4,3,2.7,6.2,3.8,9.6
				C1468,712.9,1465,735.2,1453.6,739z"
                    />
                  </g>
                  <g>
                    <path
                      class="st7"
                      d="M1453.6,739c-11.5,3.8-27.1-12.3-34.9-35.9c-7.8-23.6-4.9-45.9,6.6-49.6c1.8-0.6,3.6-0.7,5.5-0.4
				c0,4.4,1.3,9.2,3.9,13.8c5.2,9.1,14.2,14.1,21.6,12.9c1.4,3,2.7,6.2,3.8,9.6C1468,712.9,1465,735.2,1453.6,739z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st7"
                      d="M1681.3,603.7c-16-48.4-48-81.3-71.4-73.5c-23.5,7.8-29.5,53.2-13.5,101.6c16,48.4,48,81.3,71.4,73.5
				C1691.2,697.6,1697.3,652.1,1681.3,603.7z M1645.6,671.5c-11.5,3.8-27.1-12.3-34.9-35.9c-7.5-22.8-5-44.3,5.4-49.2
				c0.3,4,1.6,8.2,3.9,12.2c6.3,10.9,17.9,16,26,11.4c0.4-0.2,0.7-0.4,1-0.6c2,3.9,3.7,8.1,5.2,12.6
				C1660,645.4,1657,667.7,1645.6,671.5z"
                    />
                  </g>
                  <g>
                    <path
                      class="st7"
                      d="M1645.6,671.5c-11.5,3.8-27.1-12.3-34.9-35.9c-7.5-22.8-5-44.3,5.4-49.2c0.3,4,1.6,8.2,3.9,12.2
				c6.3,10.9,17.9,16,26,11.4c0.4-0.2,0.7-0.4,1-0.6c2,3.9,3.7,8.1,5.2,12.6C1660,645.4,1657,667.7,1645.6,671.5z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st14"
                      d="M1645.6,671.5c-11.5,3.8-27.1-12.3-34.9-35.9c-7.5-22.8-5-44.3,5.4-49.2c0.3,4,1.6,8.2,3.9,12.2
				c6.3,10.9,17.9,16,26,11.4c0.4-0.2,0.7-0.4,1-0.6c2,3.9,3.7,8.1,5.2,12.6C1660,645.4,1657,667.7,1645.6,671.5z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st14"
                      d="M1453.6,739c-11.5,3.8-27.1-12.3-34.9-35.9c-7.8-23.6-4.9-45.9,6.6-49.6c1.8-0.6,3.6-0.7,5.5-0.4
				c0,4.4,1.3,9.2,3.9,13.8c5.2,9.1,14.2,14.1,21.6,12.9c1.4,3,2.7,6.2,3.8,9.6C1468,712.9,1465,735.2,1453.6,739z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st4"
                      d="M1426.6,1358.1c-24.4,48.5-39.8,96.6-44.8,142.5c-588-5.6-1036.8-35.3-1036.8-71.1
				C345,1392.8,816.7,1362.6,1426.6,1358.1z"
                    />
                    <path
                      class="st4"
                      d="M1630.3,1357.6c77,0.2,152.3,0.8,225.1,1.7c-9.4,37.4-32.4,82-67.9,132.5c-2.1,3-4.2,5.9-6.3,8.9
				c-64.7,0.6-131.1,0.9-198.7,0.9c-10.2,0-20.3,0-30.5,0c9.9-36.6,32.7-79.6,66.9-128.3C1622.8,1367.9,1626.5,1362.6,1630.3,1357.6
				z"
                    />
                    <path
                      class="st4"
                      d="M2820,1429.5c0,31.6-349.4,58.4-835.5,68.1c22.2-46.1,36.2-91.7,40.6-135.4
				C2490,1372.6,2820,1398.8,2820,1429.5z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="st7"
                      d="M1639.5,893c-1.3,0.6-2.6,1.3-4,1.9c-18.4,8.2-37.4,10.2-53.4,6.7c-7.6-1.7-14.5-4.5-20.3-8.6
				c-6.4-4.4-11.4-10.2-14.6-17.3c-2-4.5-3.2-9.2-3.5-14c-1.6-24.2,17.3-51,47.9-64.7c36.7-16.5,76.2-7.9,88.4,19.1
				C1691.6,842.2,1673.6,875.9,1639.5,893z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    class="st14"
                    d="M2025.2,1362.3c-4.4,43.6-18.4,89.2-40.6,135.4c-65.5,1.3-133.4,2.3-203.4,3c2.1-2.9,4.2-5.9,6.3-8.9
			c35.5-50.5,58.5-95.1,67.9-132.5c11.2-45,2.6-79.6-28.2-101.3c-56-39.4-123.6,0.5-196.9,99.6c-3.8,5.1-7.5,10.3-11.3,15.7
			c-34.2,48.6-57,91.7-66.9,128.3c-57.8-0.1-114.6-0.4-170.2-0.9c4.9-45.9,20.4-94,44.8-142.5c14.1-28.1,31.2-56.3,50.9-84.4
			c11-15.6,22.3-30.4,33.8-44.4c86.7-104.9,189.9-163.7,295.2-146.2c36.5,6,73.2,21.2,109.5,46.8
			C2002.3,1190.5,2034.3,1272.2,2025.2,1362.3z"
                  />
                </g>
                <g>
                  <path
                    class="st14"
                    d="M2338.8,1125.5c-139,0-227-64-286-170l148-91c36,76,75,111,138,111c54,0,93-33,93-82c0-54-44-82-105-82h-112
			v-150h107c43,0,80-21,80-65c0-39-30-61-73-61c-51,0-81,28-106,69l-137-85c53-83,134-134,256-134c134,0,232,73,232,190
			c0,64-25,117-72,154c65,37,101,97,101,171C2602.8,1037.5,2489.8,1125.5,2338.8,1125.5z"
                  />
                </g>
              </g>
              <g id="Calque_28"></g>
              <g id="Calque_29"></g>
              <g id="Calque_36"></g>
              <g id="Calque_37"></g>
              <g id="SABLIER"></g>
              <g id="Calque_38"></g>
              <g id="Calque_39" class="st0">
                <path
                  class="st8"
                  d="M7689.9,5043.7c0.9,35.6-18.3,62-26.2,71.7c-25.6,26.9-51,53.8-76.5,80.7c-25.5-26.9-51-53.8-76.6-80.7
		c-16.3-19-26.2-44.2-26.2-71.7c0-59.4,46-107.6,102.7-107.6C7648,4936.1,7688.6,4993.7,7689.9,5043.7z"
                />
                <g class="st11">
                  <path
                    class="st15"
                    d="M7639,5111.2h-103.7c-8.4,0-15.2-6.8-15.2-15.2v-93.4c0-8.4,6.8-15.2,15.2-15.2H7639
			c8.4,0,15.2,6.8,15.2,15.2v93.4C7654.2,5104.4,7647.4,5111.2,7639,5111.2z M7535.3,4990.6c-6.6,0-11.9,5.3-11.9,11.9v93.4
			c0,6.6,5.3,11.9,11.9,11.9H7639c6.6,0,11.9-5.3,11.9-11.9v-93.4c0-6.6-5.3-11.9-11.9-11.9H7535.3z"
                  />
                </g>
                <g class="st11">
                  <g>
                    <g>
                      <path class="st16" d="M7555.4,5052.9h-13.7v-12.5h13.7V5052.9z M7543.4,5051.3h10.3v-9.2h-10.3V5051.3z" />
                    </g>
                    <g>
                      <path class="st16" d="M7555.4,5071.9h-13.7v-12.5h13.7V5071.9z M7543.4,5070.2h10.3v-9.2h-10.3V5070.2z" />
                    </g>
                    <g>
                      <path class="st16" d="M7555.4,5093.7h-13.7v-12.5h13.7V5093.7z M7543.4,5092h10.3v-9.2h-10.3V5092z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path class="st16" d="M7581.1,5052.9h-13.7v-12.5h13.7V5052.9z M7569.1,5051.3h10.3v-9.2h-10.3V5051.3z" />
                    </g>
                    <g>
                      <path class="st16" d="M7581.1,5071.9h-13.7v-12.5h13.7V5071.9z M7569.1,5070.2h10.3v-9.2h-10.3V5070.2z" />
                    </g>
                    <g>
                      <path class="st16" d="M7581.1,5093.7h-13.7v-12.5h13.7V5093.7z M7569.1,5092h10.3v-9.2h-10.3V5092z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path class="st16" d="M7606.8,5052.9h-13.7v-12.5h13.7V5052.9z M7594.8,5051.3h10.3v-9.2h-10.3V5051.3z" />
                    </g>
                    <g>
                      <path class="st16" d="M7606.8,5071.9h-13.7v-12.5h13.7V5071.9z M7594.8,5070.2h10.3v-9.2h-10.3V5070.2z" />
                    </g>
                    <g>
                      <path class="st16" d="M7606.8,5093.7h-13.7v-12.5h13.7V5093.7z M7594.8,5092h10.3v-9.2h-10.3V5092z" />
                    </g>
                  </g>
                  <g>
                    <path class="st16" d="M7632.6,5052.9h-13.7v-12.5h13.7V5052.9z M7620.6,5051.3h10.3v-9.2h-10.3V5051.3z" />
                  </g>
                  <g>
                    <path class="st16" d="M7632.6,5071.9h-13.7v-12.5h13.7V5071.9z M7620.6,5070.2h10.3v-9.2h-10.3V5070.2z" />
                  </g>
                  <g>
                    <path class="st17" d="M7632.6,5093.7h-13.7v-12.5h13.7V5093.7z M7620.6,5092h10.3v-9.2h-10.3V5092z" />
                  </g>
                </g>
                <rect x="7619.6" y="4973.9" class="st18" width="3.7" height="31.1" />
                <rect x="7547.6" y="4973.9" class="st18" width="3.7" height="31.1" />
                <line class="st19" x1="7523.4" y1="5026" x2="7650.9" y2="5026" />
                <line class="st3" x1="7655.7" y1="4838.9" x2="7683.6" y2="4867.8" />
                <line class="st3" x1="7655.7" y1="4867.8" x2="7683.6" y2="4838.9" />
              </g>
            </svg>
          </div>
          <p class="text-center lead">
            {{ $t('blocked_ip.no_access_description') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IsGouvStyleMixin from '~/components/dynamics/is-gouv-style.mixin';

export default {
  name: 'Error403',
  mixins: [IsGouvStyleMixin],
  props: {
    error: {
      type: Object,
      default: null
    },
    debug: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped>
.account-pages {
  min-height: 65vh;
}
.st0 {
  display: none;
}
.st1 {
  display: inline;
  fill: #000091;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}
.st2 {
  display: inline;
  fill: #ffffff;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}
.st3 {
  display: inline;
  fill: none;
  stroke: #0f2b42;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st4 {
  fill: #e3e3fd;
}
.st5 {
  display: none;
  fill: #ffffff;
}
.st6 {
  display: inline;
  opacity: 0.3;
}
.st7 {
  fill: #ffffff;
}
.st8 {
  display: inline;
  fill: #0f2b42;
}
.st9 {
  display: none;
  fill: none;
  stroke: #0f2b42;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st10 {
  display: inline;
  fill: none;
  stroke: #ffffff;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st11 {
  display: inline;
}

.st12 {
  display: inline;
  fill: #ffffff;
  stroke: #0f2b42;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st13 {
  display: inline;
  fill: none;
  stroke: #0f2b42;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st14 {
  fill: #000091;
}
.st15 {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st17 {
  fill: #2ab19f;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st18 {
  display: inline;
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st19 {
  display: inline;
  fill: none;
  stroke: #ffffff;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.btn-primary:hover {
  background-color: #1212ff !important;
}
</style>
