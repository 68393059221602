const middleware = {}

middleware['auth'] = require('../src/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authenticated'] = require('../src/middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['blockedIp'] = require('../src/middleware/blockedIp.js')
middleware['blockedIp'] = middleware['blockedIp'].default || middleware['blockedIp']

middleware['detector/index'] = require('../src/middleware/detector/index.js')
middleware['detector/index'] = middleware['detector/index'].default || middleware['detector/index']

middleware['detector/testFunctions/index'] = require('../src/middleware/detector/testFunctions/index.js')
middleware['detector/testFunctions/index'] = middleware['detector/testFunctions/index'].default || middleware['detector/testFunctions/index']

middleware['intercept'] = require('../src/middleware/intercept.js')
middleware['intercept'] = middleware['intercept'].default || middleware['intercept']

middleware['ips'] = require('../src/middleware/ips.js')
middleware['ips'] = middleware['ips'].default || middleware['ips']

export default middleware
