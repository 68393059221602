import { mapState } from 'vuex';

const isGouvStyle = {
  computed: {
    ...mapState({
      isUserGouv: (state) => state.user.user.email === 'gouv@troov.com',
      hasGouvLayout: (state) => true
    }),
    isGouvStyle() {
      return true;
    }
  }
};

export default isGouvStyle;
