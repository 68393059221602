import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

export default ({ app }, inject) => {
  inject('simplebar', (element, options = {}) => {
    return new SimpleBar(element, {
      autoHide: options.autoHide || true,
      ariaLabel: options.ariaLabel || true,
      forceVisible: options.forceVisible || true, // true|'x'|'y'`
      scrollbarMinSize: 100,
      scrollbarMaxSize: 100
    });
  });
};
