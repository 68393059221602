import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'

import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'
import getCustomIntegrations from '/home/app/gouv-vue/source/src/plugins/sentry/session-replay'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:"15f07d88ab2c96a533fceb08db09ab328d6a5c7e",
    dsn:"https:\u002F\u002F408078155ff34f6d9934ba544d86a747@o237490.ingest.sentry.io\u002F6633553",
    environment:"production",
    attachStacktrace:true,
    normalizeDepth:3,
    ignoreErrors:["Request failed with status code 404","Network Error","ChunkLoadError","RangeError","Invalid interval","Request failed with status code 401","Request failed with status code 502","Request failed with status code 429","Cannot set headers after they are sent to the client"],
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new ExtraErrorData({ depth:5 }),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const customIntegrations = await getCustomIntegrations(ctx)
  if (Array.isArray(customIntegrations)) {
    config.integrations.push(...customIntegrations)
  } else {
    console.error(`[@nuxtjs/sentry] Invalid value returned from customClientIntegrations plugin. Expected an array, got "${typeof customIntegrations}".`)
  }

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
