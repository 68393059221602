import dev from './dev';

export default {
  /**
   * Desktop App configuration
   * @return {{API_HOST: string, API_ROOT: string, env: 'desktop', HOST_NUXT: string, "x-gouv-web": string}}
   */
  config: () => {
    let base = {
      API_HOST: 'https://api.consulat.gouv.fr/api',
      API_ROOT: 'https://api.consulat.gouv.fr',
      HOST_NUXT: 'https://consulat.gouv.fr'
    };

    if (process.env.TEST_DESKTOP && process.env.TEST_DESKTOP === 'true') {
      base = {
        ...base,
        ...dev.config()
      };
    }
    return {
      ...base,
      env: 'desktop',
      'x-gouv-web': 'com.troov.meae.desktop'
    };
  }
};
