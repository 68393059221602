import environment from '~/config/environment';
import { getIp, hasStrangeBehavior, isBlackListedYaounde } from '~/helpers/ipHelpers';

const consola = console;

async function nuxtServerInit({ dispatch, commit }, { $config, $sentry, req, app, route, redirect, getRouteBaseName, error }) {
  const hostname = req.headers.host;
  const protocol = req.headers['x-forwarded-proto'] || 'http';

  const ip = getIp(req);
  const isGouvIp = ['77.158.88.38', '77.158.88.39', '77.158.89.186', '77.158.89.187', '194.206.66.115', '77.158.88', '77.158.89'].includes(ip);
  const isBadIpSentry = hasStrangeBehavior(ip);
  const isBadIp = isBlackListedYaounde(ip) || isBlackListedYaounde(ip);

  if (isBadIpSentry || isBadIp) {
    consola.log('Intercept blacklisted ip', ip);
    return error({ statusCode: 403, message: 'Blocket IP' });
  }

  if (!isGouvIp) {
    try {
      await dispatch('ip/checkIfIpIsBlocked', { ip });
    } catch (err) {
      if (err.message.includes('ECONNREFUSED')) return error({ statusCode: 417, message: 'Unavailable' });
      consola.error('badIp # -> ip/checkIfIpIsBlocked [%s], ', ip, err.message);
      consola.info(JSON.stringify(err.config));
      return error({ statusCode: 403, message: 'Blocked IP' });
    }
  }

  commit('layout/SET_HOSTNAME', { hostname, protocol, ip });
  const routeBaseName = getRouteBaseName();

  const cookieparser = process.server ? require('cookieparser') : undefined;
  const query = route.query || {};
  const appEnv = environment({ host: hostname });
  commit('site/SET_ENV', appEnv);
  const isDashboardPage = routeBaseName && routeBaseName.includes('dashboard');
  const isLoginPage = routeBaseName && routeBaseName.includes('login');

  try {
    await dispatch('user/handshake');

    let user = {};
    const routesNoToken = ['index', 'password-id', 'all-appointment', 'login'];
    if (routeBaseName && !routesNoToken.includes(routeBaseName) && (query.token || req.headers.cookie)) {
      const token = query.token || cookieparser.parse(req.headers.cookie)._gouv_c_tkn;
      if (token) {
        commit('user/SET_TOKEN', token);
        app.$axios.setToken(token, 'Bearer');
        if (req.url.match(/^\/admin/)) {
          user = await dispatch('admin/getUserAdminSsr', { host: hostname });
        } else {
          user = await dispatch('user/getUserSsr', { host: hostname, team: route.params.team });
          commit('user/SET_USER', user);
        }
      } else if (isDashboardPage && !isLoginPage) {
        commit('user/SET_TOKEN', null);
        if (route.fullPath.includes('noRetry=1')) {
          return redirect(appEnv.HOST_NUXT + '/login');
        }
        redirect(appEnv.HOST_NUXT + '/login' + (route.fullPath && '?nextLink=' + route.fullPath) || '');
      }
    }
  } catch (e) {
    const code = parseInt((e.response && e.response.status) || -1);
    if (code === 429) {
      consola.error('nuxtServerInit', route.fullPath, routeBaseName, JSON.stringify({ code, ip }));
      consola.error(JSON.stringify(e.config));
    }
    if ($config.environment === 'production' && ![429, 401, 403, 418].includes(code)) {
      try {
        // $sentry.setExtra('data', { routeBaseName, query, token: query.token, cookie: req.headers.cookie });
        // consola.error('nuxtServerInit [%s]', routeBaseName, e);
        // $sentry.setExtra('nuxtServerInitResponse', e);
        // if (e.response && e.response.data) $sentry.setExtra('nuxtServerInitData', e.response.data);
        // $sentry.captureException(e);
        // $sentry.captureEvent({
        //   message: `[nuxtServerInit] Error`,
        //   level: 'error',
        //   extra: {
        //     response: e.response,
        //     data: e.response && e.response.data,
        //     config: e.config,
        //     json: e.toJSON()
        //   }
        // });
      } catch (e) {
        //
      }
    }
    if (isDashboardPage && !isLoginPage) {
      commit('user/SET_TOKEN', null);
      if (route.fullPath.includes('noRetry=1')) {
        redirect(appEnv.HOST_NUXT + '/login');
      } else redirect(appEnv.HOST_NUXT + '/login' + (route.fullPath && '?nextLink=' + route.fullPath) || '');
    } else error({ statusCode: 500, message: 'Unavailable' });
  }
}

export default nuxtServerInit;
