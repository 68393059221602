export default {
  /**
   * Staging configuration
   * Use a function to be sure to use runtime configuration for process.env.API_PREFIX
   * @return {{API_HOST: string, API_ROOT: string, env: string, HOST_NUXT: string, "x-gouv-web": string}}
   */
  config: () => ({
    API_HOST: 'https://qa-api.gouv.troov.com/api',
    API_ROOT: 'https://qa-api.gouv.troov.com',
    HOST_NUXT: 'https://qa.gouv.troov.com',
    env: 'gouvqa',
    'x-gouv-web': 'fr.gouv.consulat'
  })
};
