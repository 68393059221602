import { Crawler } from 'es6-crawler-detect';

export default function (context) {
  // const CrawlerDetector = new Crawler();
  // const userAgentString = process.server ? context.req.headers['user-agent'] : navigator.userAgent;
  // check the current visitor's useragent
  // if (CrawlerDetector.isCrawler(userAgentString)) {
  //   // true if crawler user agent detected
  //   context.$sentry.setTag('crawler', userAgentString);
  //   try {
  //     context.$sentry.captureEvent({
  //       message: `crawler user agent detected`,
  //       level: 'critical',
  //       extra: {
  //         matches: CrawlerDetector.getMatches()
  //       }
  //     });
  //   } catch (e) {
  //     // naaaaaah... it seems to happen
  //   }
  // }
}
