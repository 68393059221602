export default {
  SET_RESERVATIONS_CALANDAR_COUNT(state, data) {
    state.reservationsCount = data;
  },
  SET_RESERVATION_RANGE_USERS(state, data) {
    state.reservationRange = data;
  },
  SET_RESERVATIONS_AVAILABILITY_STORE(state, data) {
    state.reservationsAvailabilty = data;
  },
  SET_RESERVATIONS_EXCLUDE_STORE(state, data) {
    state.storeExcludeDays = data;
  },
  SET_RESERVATIONS_LIST(state, data) {
    state.reservationsList = data && data.docs && data.docs.length > 0 ? data : { docs: [] };
  },
  SET_CURRENT_RESERVATION(state, currentReservation) {
    state.currentReservation = currentReservation;
  },
  SET_EXCLUDE_DAYS_DICTIONARY(state, data) {
    state.excludeDaysDictionary = data;
  },

  REMOVE_EXCLUDE_DAYS_DICTIONARY(state) {
    state.excludeDaysDictionary = {};
  }
};
