export default {
  /**
   * Consulat configuration
   * Use a function to be sure to use runtime configuration for process.env.API_PREFIX
   * @return {{API_HOST: string, API_ROOT: string, env: string, HOST_NUXT: string, "x-gouv-web": string}}
   */
  config: () => ({
    API_HOST: 'https://api.consulat.gouv.fr/api',
    API_ROOT: 'https://api.consulat.gouv.fr',
    HOST_NUXT: 'https://consulat.gouv.fr',
    env: 'production',
    'x-gouv-web': 'fr.gouv.consulat'
  })
};
