import saveBlobFile from '~/helpers/saveBlobFile';

export default {
  getAllCountReservation({ commit, state }, { name = '', start = '', end = '', matching, mode = 'week' }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservations/count`, {
        params: {
          name: encodeURIComponent(name) || '',
          start,
          end,
          matching: matching ? 'true' : '',
          mode
        }
      })
      .then(({ data }) => {
        commit('SET_RESERVATIONS_CALANDAR_COUNT', data);
        return data;
      });
  },
  getLoggerHistory({ commit, state }, { type, team, page, limit, filters }) {
    const options = {
      params: {
        historyType: type,
        team,
        page,
        limit,
        ...filters
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/message/history`, options).then(({ data }) => {
      return data;
    });
  },
  getUsersReservation({ commit, state }, { name = '', start = '', end = '', matching, search, page = 1, limit, filter, noMembers }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservations/users`, {
        params: {
          name: encodeURIComponent(name) || '',
          start,
          end,
          matching: matching ? 'true' : '',
          search,
          page,
          limit,
          filter,
          noMembers
        }
      })
      .then(({ data }) => {
        commit('SET_RESERVATION_RANGE_USERS', { ...data });
        return Object.assign({}, { ...data });
      });
  },
  downloadUsersReservation(
    { commit, state },
    { name = '', start = '', end = '', matching, zone, format, filename, search, teams, arrived, include, filter, lang = 'fr' }
  ) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservations/users`, {
        responseType: 'blob',
        params: {
          name: encodeURIComponent(name) || '',
          start,
          end,
          matching: matching ? 'true' : '',
          export: format,
          search,
          zone,
          teams,
          arrived,
          include,
          filter,
          lang
        }
      })
      .then(({ data }) => {
        saveBlobFile({ data, extension: format, filename });
        return data;
      });
  },
  getSmartCounterReservation({ commit, state }, { start, name = '', matching, mode = 'day', isArrived = false }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservations/current`, {
        params: {
          name: encodeURIComponent(name) || '',
          matching: matching ? 'true' : '',
          mode,
          is_arrived: isArrived,
          start: encodeURIComponent(this.$dayjs(start).toISOString(true))
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  getAvailableTimeByTeamReservation({ commit, state }, { date, team, name, places, matching, maxCapacity, sessionId }) {
    const teamId = (team && team._id) || team;
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${teamId}/reservations/availability`, {
        params: {
          name: encodeURIComponent(name) || '',
          date: date || '',
          places: places || 1,
          matching: matching || '',
          maxCapacity,
          sessionId
        }
      })
      .then(({ data }) => {
        commit('SET_RESERVATIONS_AVAILABILITY_STORE', data);
        return data;
      });
  },
  getExcludeDaysByTeamReservation({ commit, state }, { start, end, team, session, sessionId }) {
    const teamId = (team && team._id) || team;
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${teamId}/reservations/exclude-days`, {
        start,
        end,
        session,
        sessionId
      })
      .then(({ data }) => {
        commit('SET_RESERVATIONS_EXCLUDE_STORE', data);
        return data;
      });
  },
  getExcludeDaysByTeamReservationForOneService({ commit, state }, { team, session, sessionId, zone_id }) {
    const teamId = (team && team._id) || team;

    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${teamId}/reservations/exclude-days`, {
        session,
        sessionId
      })
      .then(({ data }) => {
        const newDic = { ...state.excludeDaysDictionary };
        newDic[zone_id] = data;
        commit('SET_EXCLUDE_DAYS_DICTIONARY', newDic);
        return data;
      });
  },
  getRemaingTimeByTeamReservation({ commit, state }, { date, team, name, places, matching, source, mode }) {
    const teamId = (team && team._id) || team;
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${teamId}/reservations/remaing`, {
        params: {
          mode,
          name: encodeURIComponent(name) || '',
          date: date || '',
          places: places || 1,
          matching: matching || '',
          source
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  addSlotsReservation({ commit, state }, slots) {
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservations/slots`, { slots }).then(({ data }) => data);
  },
  getAllReservation({ commit, state }, { date, page }) {
    const options = {
      params: {
        date,
        page
      }
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/reservations`, options).then(({ data }) => {
      commit('SET_RESERVATIONS_LIST', data);
      return data;
    });
  },
  getUsersBySession({ commit, state }, { sessionId }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservations/session/${sessionId}`, {
        params: {
          sessionId
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  usersHasAlreadyReserved({ commit, state }, { body, teamId }) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${this.state.user.teamId || teamId}/reservations/family/already-reserved-by-users`, body)
      .then(({ data }) => {
        return data;
      });
  },
  addReservation({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/reservations`, body).then(({ data }) => data);
  },
  removeReservation({ commit, state }, body) {
    return this.$axios.delete(`${this.app.$config.API_HOST}/reservations.remove`, { data: body }).then(({ data }) => {
      return data;
    });
  },
  sendMessage({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/reservations.message`, body).then(({ data }) => {
      return data;
    });
  },
  sendConfirmation({ commit, state }, body) {
    return this.$axios
      .post(`${this.state.site.env.API_HOST}/team/${this.state.user.teamId}/reservations/send-confirmation`, body)
      .then(({ data }) => {
        return data;
      });
  },
  approveReservation({ commit, state }, { id }) {
    return this.$axios.post(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservations/${id || ''}/approve`).then(({ data }) => {
      return data;
    });
  },
  getReservation({ commit, state }, { id }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservation/${id || ''}`).then(({ data }) => {
      return data;
    });
  },
  getOwnerReservation({ commit, state }, { reservation, token, isArrived, commented }) {
    const params = {
      reservation: reservation._id || reservation,
      isArrived,
      token
    };
    return this.$axios.get(`${this.app.$config.API_HOST}/reservations.owner`, { params }).then(({ data }) => {
      commit('SET_CURRENT_RESERVATION', data);
      return data;
    });
  },
  rateReservation({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/reservations.rating`, body).then(({ data }) => {
      return data;
    });
  },
  searchContactReservation({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/reservations.contact`, { ...body, team: this.state.user.teamId }).then(({ data }) => {
      return (data && data.docs) || [];
    });
  },
  setExternalReservation({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/reservations.external`, { ...body }).then(({ data }) => {
      return data;
    });
  },
  updateSessionPeople({ commit, state }, body) {
    return this.$axios
      .post(`${this.app.$config.API_HOST}/team/${this.state.user.teamId}/reservations/${body.zone || ''}/session`, { ...body })
      .then(({ data }) => {
        return data;
      });
  },
  cancelFamilyReservationsFromEmailOrigin({ commit, state }, { team, sessionId, reservationsIds, language, token }) {
    return this.$axios.delete(`${this.app.$config.API_HOST}/team/${team}/reservations/family/delete-from-email`, {
      data: {
        sessionId,
        reservationsIds,
        language,
        token
      }
    });
  },
  getActionsHistory({ commit, state }, { team, zone, page = 1, limit }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${team}/reservations/history`, {
        params: {
          page,
          zone,
          limit
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  getCurrentReservationMessageHistory({ commit, state }, { team, reservation, page = 1, limit }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${team}/reservation/${reservation}/history`, {
        params: {
          page,
          limit
        }
      })
      .then(({ data }) => {
        return data;
      });
  },
  pay({ commit, state }, body) {
    return this.$axios.post(`${this.app.$config.API_HOST}/reservations.pay`, { ...body }).then(({ data }) => {
      return data;
    });
  },
  getPaidReservations({ state }, { team, page, limit, filters }) {
    return this.$axios
      .get(`${this.app.$config.API_HOST}/team/${team}/reservations/paid`, { params: { page, limit, ...filters } })
      .then(({ data }) => {
        return data;
      });
  },
  subscribeWaitingList({ state }, { team, service, email }) {
    const body = {
      langage: this.$i18n.locale,
      team,
      email,
      service
    };
    return this.$axios.post(`${this.app.$config.API_HOST}/waitinglist/${team}/subscribe`, body).then(({ data }) => {
      return data;
    });
  },
  getWaitingListInfo({ state }, { hash }) {
    const body = { hash };
    return this.$axios.post(`${this.app.$config.API_HOST}/waitinglist/infos`, body).then(({ data }) => {
      return data;
    });
  },
  getWaitingListSubcscriberInfo({ state }, { email, office }) {
    return this.$axios.get(`${this.app.$config.API_HOST}/waitinglist/subscriber-infos`, { params: { email, office } }).then(({ data }) => {
      return data;
    });
  },
  unsubscribeToWaitingList({ state }, { hash }) {
    const body = { hash };
    return this.$axios.post(`${this.app.$config.API_HOST}/waitinglist/unsubscribe`, body).then(({ data }) => {
      return data;
    });
  },
  addOrUpdateNote({ state }, { _id, note }) {
    return this.$axios.put(`${this.app.$config.API_HOST}/reservations.note`, { params: { _id, note } }).then(({ data }) => {
      return data;
    });
  },
  updateCustomFields({ state }, { _id, fields }) {
    return this.$axios.put(`${this.app.$config.API_HOST}/reservations.fields`, { params: { _id, fields } }).then(({ data }) => {
      return data;
    });
  },
  getOpeningIntervalFromService({ commit, state }, { teamId, serviceId }) {
    return this.$axios.get(`${this.state.site.env.API_HOST}/team/${teamId}/reservations/get-interval`, {
      params: {
        serviceId
      }
    });
  }
};
