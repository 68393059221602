import { getIp, hasStrangeBehavior, isBlackListedNdjamena, isBlackListedYaounde } from '~/helpers/ipHelpers';

export default function ({ req, error, $sentry }) {
  // https://github.com/nuxt/nuxt.js/issues/2914
  const ip = getIp(req);
  const isBlackListed = isBlackListedYaounde(ip) || isBlackListedNdjamena(ip) || hasStrangeBehavior(ip);
  if (isBlackListed) {
    $sentry.setTag('badIp', ip);
    try {
      $sentry.captureEvent({
        message: `[${ip}] IP blocked bt middleware [ips]`,
        level: 'critical',
        extra: {
          ip,
          yaoundList: isBlackListedYaounde(ip),
          ndjamenaList: isBlackListedNdjamena(ip),
          customList: hasStrangeBehavior(ip)
        }
      });
    } catch (e) {
      // naaaaaah... it seems to happen
    }
    return error({ statusCode: 403, message: 'Blocked IP' });
  }
}
