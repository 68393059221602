export default () => ({
  reservationsCount: [],
  reservationRange: {
    docs: []
  },
  reservationsAvailabilty: [],
  reservationsList: { docs: [] },
  currentReservation: {},
  storeExcludeDays: [],
  excludeDaysDictionary: {}
});
