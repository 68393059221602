export default () => ({
  _id: '',
  servicesStep: null,
  importantInformationStep: null,
  team: '',
  numberOfApplicantsStep: null,
  confirmationSummaryStep: null,
  askConfirmationStep: null,
  slotsSteps: [],
  mainContactDetailsStep: null,
  secondaryContactsDetailsStep: null,
  stepIndex: 0,
  standalone_service_name: ''
});
