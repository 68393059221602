import TurndownService from 'turndown';
import { gfm } from 'turndown-plugin-gfm';
import sal from 'sal.js';
import { removeSpace } from '~/helpers';

export default ({ app }, inject) => {
  inject('turndownService', () => {
    const turndownService = new TurndownService();
    turndownService.use(gfm);
    turndownService.addRule('strikethrough', {
      filter: ['del', 's', 'strike'],
      replacement(content) {
        return '~~' + content + '~~';
      }
    });
    turndownService.addRule('underline', {
      filter: ['u', 'ins'],
      replacement(content) {
        return '++' + content + '++';
      }
    });
    return turndownService;
  });
  inject('sal', (options) => {
    return sal(options);
  });
  inject('removeWhiteSpace', (options) => {
    return removeSpace(options);
  });
};
