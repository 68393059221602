export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: true,
  locales: [{"code":"en","iso":"en-US","name":"English","file":"en.json","dir":"ltr","isCatchallLocale":true},{"code":"fr","iso":"fr-FR","name":"Français","file":"fr.json","dir":"ltr"},{"code":"ar","iso":"ar-AR","name":"العربية","file":"ar.json","dir":"rtl"},{"code":"zh","iso":"zh-CN","name":"中文","file":"zh.json","dir":"ltr"},{"code":"ru","iso":"ru-RU","name":"Русский","file":"ru.json","dir":"ltr"},{"code":"it","iso":"it-IT","name":"Italiano","file":"it.json","dir":"ltr"},{"code":"es","iso":"es-ES","name":"Español","file":"es.json","dir":"ltr"},{"code":"de","iso":"de-DE","name":"Deutsch","file":"de.json","dir":"ltr"},{"code":"pt","iso":"pt-PT","name":"Português","file":"pt.json","dir":"ltr"},{"code":"nl","iso":"nl-NL","name":"Nederlands","file":"nl.json","dir":"ltr"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: true,
  langDir: "~/locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://consulat.gouv.fr",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  skipNuxtState: true,
  normalizedLocales: [{"code":"en","iso":"en-US","name":"English","file":"en.json","dir":"ltr","isCatchallLocale":true},{"code":"fr","iso":"fr-FR","name":"Français","file":"fr.json","dir":"ltr"},{"code":"ar","iso":"ar-AR","name":"العربية","file":"ar.json","dir":"rtl"},{"code":"zh","iso":"zh-CN","name":"中文","file":"zh.json","dir":"ltr"},{"code":"ru","iso":"ru-RU","name":"Русский","file":"ru.json","dir":"ltr"},{"code":"it","iso":"it-IT","name":"Italiano","file":"it.json","dir":"ltr"},{"code":"es","iso":"es-ES","name":"Español","file":"es.json","dir":"ltr"},{"code":"de","iso":"de-DE","name":"Deutsch","file":"de.json","dir":"ltr"},{"code":"pt","iso":"pt-PT","name":"Português","file":"pt.json","dir":"ltr"},{"code":"nl","iso":"nl-NL","name":"Nederlands","file":"nl.json","dir":"ltr"}],
  localeCodes: ["en","fr","ar","zh","ru","it","es","de","pt","nl"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "s",
  16: "r",
  17: "c",
  18: "/",
  19: "l",
  20: "o",
  21: "c",
  22: "a",
  23: "l",
  24: "e",
  25: "s",
  26: "/",
  27: "e",
  28: "n",
  29: ".",
  30: "j",
  31: "s",
  32: "o",
  33: "n",
  34: "\"",
  35: ",",
  36: "\"",
  37: "f",
  38: "r",
  39: ".",
  40: "j",
  41: "s",
  42: "o",
  43: "n",
  44: "\"",
  45: ":",
  46: "\"",
  47: ".",
  48: ".",
  49: "/",
  50: "s",
  51: "r",
  52: "c",
  53: "/",
  54: "l",
  55: "o",
  56: "c",
  57: "a",
  58: "l",
  59: "e",
  60: "s",
  61: "/",
  62: "f",
  63: "r",
  64: ".",
  65: "j",
  66: "s",
  67: "o",
  68: "n",
  69: "\"",
  70: ",",
  71: "\"",
  72: "a",
  73: "r",
  74: ".",
  75: "j",
  76: "s",
  77: "o",
  78: "n",
  79: "\"",
  80: ":",
  81: "\"",
  82: ".",
  83: ".",
  84: "/",
  85: "s",
  86: "r",
  87: "c",
  88: "/",
  89: "l",
  90: "o",
  91: "c",
  92: "a",
  93: "l",
  94: "e",
  95: "s",
  96: "/",
  97: "a",
  98: "r",
  99: ".",
  100: "j",
  101: "s",
  102: "o",
  103: "n",
  104: "\"",
  105: ",",
  106: "\"",
  107: "z",
  108: "h",
  109: ".",
  110: "j",
  111: "s",
  112: "o",
  113: "n",
  114: "\"",
  115: ":",
  116: "\"",
  117: ".",
  118: ".",
  119: "/",
  120: "s",
  121: "r",
  122: "c",
  123: "/",
  124: "l",
  125: "o",
  126: "c",
  127: "a",
  128: "l",
  129: "e",
  130: "s",
  131: "/",
  132: "z",
  133: "h",
  134: ".",
  135: "j",
  136: "s",
  137: "o",
  138: "n",
  139: "\"",
  140: ",",
  141: "\"",
  142: "r",
  143: "u",
  144: ".",
  145: "j",
  146: "s",
  147: "o",
  148: "n",
  149: "\"",
  150: ":",
  151: "\"",
  152: ".",
  153: ".",
  154: "/",
  155: "s",
  156: "r",
  157: "c",
  158: "/",
  159: "l",
  160: "o",
  161: "c",
  162: "a",
  163: "l",
  164: "e",
  165: "s",
  166: "/",
  167: "r",
  168: "u",
  169: ".",
  170: "j",
  171: "s",
  172: "o",
  173: "n",
  174: "\"",
  175: ",",
  176: "\"",
  177: "i",
  178: "t",
  179: ".",
  180: "j",
  181: "s",
  182: "o",
  183: "n",
  184: "\"",
  185: ":",
  186: "\"",
  187: ".",
  188: ".",
  189: "/",
  190: "s",
  191: "r",
  192: "c",
  193: "/",
  194: "l",
  195: "o",
  196: "c",
  197: "a",
  198: "l",
  199: "e",
  200: "s",
  201: "/",
  202: "i",
  203: "t",
  204: ".",
  205: "j",
  206: "s",
  207: "o",
  208: "n",
  209: "\"",
  210: ",",
  211: "\"",
  212: "e",
  213: "s",
  214: ".",
  215: "j",
  216: "s",
  217: "o",
  218: "n",
  219: "\"",
  220: ":",
  221: "\"",
  222: ".",
  223: ".",
  224: "/",
  225: "s",
  226: "r",
  227: "c",
  228: "/",
  229: "l",
  230: "o",
  231: "c",
  232: "a",
  233: "l",
  234: "e",
  235: "s",
  236: "/",
  237: "e",
  238: "s",
  239: ".",
  240: "j",
  241: "s",
  242: "o",
  243: "n",
  244: "\"",
  245: ",",
  246: "\"",
  247: "d",
  248: "e",
  249: ".",
  250: "j",
  251: "s",
  252: "o",
  253: "n",
  254: "\"",
  255: ":",
  256: "\"",
  257: ".",
  258: ".",
  259: "/",
  260: "s",
  261: "r",
  262: "c",
  263: "/",
  264: "l",
  265: "o",
  266: "c",
  267: "a",
  268: "l",
  269: "e",
  270: "s",
  271: "/",
  272: "d",
  273: "e",
  274: ".",
  275: "j",
  276: "s",
  277: "o",
  278: "n",
  279: "\"",
  280: ",",
  281: "\"",
  282: "p",
  283: "t",
  284: ".",
  285: "j",
  286: "s",
  287: "o",
  288: "n",
  289: "\"",
  290: ":",
  291: "\"",
  292: ".",
  293: ".",
  294: "/",
  295: "s",
  296: "r",
  297: "c",
  298: "/",
  299: "l",
  300: "o",
  301: "c",
  302: "a",
  303: "l",
  304: "e",
  305: "s",
  306: "/",
  307: "p",
  308: "t",
  309: ".",
  310: "j",
  311: "s",
  312: "o",
  313: "n",
  314: "\"",
  315: ",",
  316: "\"",
  317: "n",
  318: "l",
  319: ".",
  320: "j",
  321: "s",
  322: "o",
  323: "n",
  324: "\"",
  325: ":",
  326: "\"",
  327: ".",
  328: ".",
  329: "/",
  330: "s",
  331: "r",
  332: "c",
  333: "/",
  334: "l",
  335: "o",
  336: "c",
  337: "a",
  338: "l",
  339: "e",
  340: "s",
  341: "/",
  342: "n",
  343: "l",
  344: ".",
  345: "j",
  346: "s",
  347: "o",
  348: "n",
  349: "\"",
  350: "}",
}

export const localeMessages = {
  'en.json': () => import('../../src/locales/en.json' /* webpackChunkName: "lang-en.json" */),
  'fr.json': () => import('../../src/locales/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'ar.json': () => import('../../src/locales/ar.json' /* webpackChunkName: "lang-ar.json" */),
  'zh.json': () => import('../../src/locales/zh.json' /* webpackChunkName: "lang-zh.json" */),
  'ru.json': () => import('../../src/locales/ru.json' /* webpackChunkName: "lang-ru.json" */),
  'it.json': () => import('../../src/locales/it.json' /* webpackChunkName: "lang-it.json" */),
  'es.json': () => import('../../src/locales/es.json' /* webpackChunkName: "lang-es.json" */),
  'de.json': () => import('../../src/locales/de.json' /* webpackChunkName: "lang-de.json" */),
  'pt.json': () => import('../../src/locales/pt.json' /* webpackChunkName: "lang-pt.json" */),
  'nl.json': () => import('../../src/locales/nl.json' /* webpackChunkName: "lang-nl.json" */),
}
