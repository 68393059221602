import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _78019ca5 = () => interopDefault(import('../src/pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _045e86ce = () => interopDefault(import('../src/pages/accessibility-certificate.vue' /* webpackChunkName: "pages/accessibility-certificate" */))
const _5b6a23f0 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5928c472 = () => interopDefault(import('../src/pages/cgu.vue' /* webpackChunkName: "pages/cgu" */))
const _044ec048 = () => interopDefault(import('../src/pages/confidentiality.vue' /* webpackChunkName: "pages/confidentiality" */))
const _a1ddf266 = () => interopDefault(import('../src/pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0612db4f = () => interopDefault(import('../src/pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _bc1112a2 = () => interopDefault(import('../src/pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _70c35294 = () => interopDefault(import('../src/pages/legals.vue' /* webpackChunkName: "pages/legals" */))
const _c81addc2 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2d53ca55 = () => interopDefault(import('../src/pages/login-totp.vue' /* webpackChunkName: "pages/login-totp" */))
const _2a8cc9bf = () => interopDefault(import('../src/pages/appointment-schedule.vue' /* webpackChunkName: "pages/appointment-schedule" */))
const _1b544c03 = () => interopDefault(import('../src/pages/test-turnstile.vue' /* webpackChunkName: "pages/test-turnstile" */))
const _07305e60 = () => interopDefault(import('../src/pages/notifications/unsubscribe.vue' /* webpackChunkName: "pages/notifications/unsubscribe" */))
const _70f993ab = () => interopDefault(import('../src/pages/rating/ttc.vue' /* webpackChunkName: "pages/rating/ttc" */))
const _e3cc9192 = () => interopDefault(import('../src/pages/faq/pro/_type/index.vue' /* webpackChunkName: "pages/faq/pro/_type/index" */))
const _1b3403a8 = () => interopDefault(import('../src/pages/faq/pro/_type/_content/index.vue' /* webpackChunkName: "pages/faq/pro/_type/_content/index" */))
const _6667d57a = () => interopDefault(import('../src/pages/dashboard/_team/index.vue' /* webpackChunkName: "pages/dashboard/_team/index" */))
const _8d7015b4 = () => interopDefault(import('../src/pages/dashboard/_team/addteam.vue' /* webpackChunkName: "pages/dashboard/_team/addteam" */))
const _c4f6eff8 = () => interopDefault(import('../src/pages/dashboard/_team/members/index.vue' /* webpackChunkName: "pages/dashboard/_team/members/index" */))
const _9e443e58 = () => interopDefault(import('../src/pages/dashboard/_team/messages.vue' /* webpackChunkName: "pages/dashboard/_team/messages" */))
const _f136c11c = () => interopDefault(import('../src/pages/dashboard/_team/reservations/index.vue' /* webpackChunkName: "pages/dashboard/_team/reservations/index" */))
const _2325d80a = () => interopDefault(import('../src/pages/dashboard/_team/stats/index.vue' /* webpackChunkName: "pages/dashboard/_team/stats/index" */))
const _67da8816 = () => interopDefault(import('../src/pages/dashboard/_team/user/index.vue' /* webpackChunkName: "pages/dashboard/_team/user/index" */))
const _59be125a = () => interopDefault(import('../src/pages/dashboard/_team/members/add.vue' /* webpackChunkName: "pages/dashboard/_team/members/add" */))
const _c046dd60 = () => interopDefault(import('../src/pages/dashboard/_team/members/addTeam.vue' /* webpackChunkName: "pages/dashboard/_team/members/addTeam" */))
const _67d11963 = () => interopDefault(import('../src/pages/dashboard/_team/members/import.vue' /* webpackChunkName: "pages/dashboard/_team/members/import" */))
const _46729a12 = () => interopDefault(import('../src/pages/dashboard/_team/members/main.vue' /* webpackChunkName: "pages/dashboard/_team/members/main" */))
const _2064b910 = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/index.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/index" */))
const _432d8124 = () => interopDefault(import('../src/pages/dashboard/_team/reservations/message-history.vue' /* webpackChunkName: "pages/dashboard/_team/reservations/message-history" */))
const _32db6615 = () => interopDefault(import('../src/pages/dashboard/_team/reservations/payment-list.vue' /* webpackChunkName: "pages/dashboard/_team/reservations/payment-list" */))
const _6ca2339f = () => interopDefault(import('../src/pages/dashboard/_team/stats/reservations.vue' /* webpackChunkName: "pages/dashboard/_team/stats/reservations" */))
const _c2595fe2 = () => interopDefault(import('../src/pages/dashboard/_team/user/settings.vue' /* webpackChunkName: "pages/dashboard/_team/user/settings" */))
const _851fb426 = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/forms.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/forms" */))
const _1cf45e8b = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/my-messages.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/my-messages" */))
const _d8da7632 = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/profile.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/profile" */))
const _0e1fab0b = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/profile-location.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/profile-location" */))
const _418ff7c6 = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/reservations-shop/index.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/reservations-shop/index" */))
const _48d6209c = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/reservations-shop/create-shop.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/reservations-shop/create-shop" */))
const _5c8d206a = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/reservations-shop/import.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/reservations-shop/import" */))
const _1834dace = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/reservations-shop/_setting/index.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/reservations-shop/_setting/index" */))
const _009db33a = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/reservations-shop/_setting/custom.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/reservations-shop/_setting/custom" */))
const _d6b164ca = () => interopDefault(import('../src/pages/dashboard/_team/pro/settings/reservations-shop/_setting/history.vue' /* webpackChunkName: "pages/dashboard/_team/pro/settings/reservations-shop/_setting/history" */))
const _69f1eabb = () => interopDefault(import('../src/pages/dashboard/_team/user/reservation/_id.vue' /* webpackChunkName: "pages/dashboard/_team/user/reservation/_id" */))
const _0f05b20b = () => interopDefault(import('../src/pages/dashboard/_team/documents/_id.vue' /* webpackChunkName: "pages/dashboard/_team/documents/_id" */))
const _71dcbb6c = () => interopDefault(import('../src/pages/dashboard/_team/members/_id.vue' /* webpackChunkName: "pages/dashboard/_team/members/_id" */))
const _7305f40c = () => interopDefault(import('../src/pages/faq/_categorySlug/_summarySlug.vue' /* webpackChunkName: "pages/faq/_categorySlug/_summarySlug" */))
const _57261435 = () => interopDefault(import('../src/pages/academy/_/index.vue' /* webpackChunkName: "pages/academy/_/index" */))
const _5bda6d30 = () => interopDefault(import('../src/pages/_/appointment/index.vue' /* webpackChunkName: "pages/_/appointment/index" */))
const _54cd2b60 = () => interopDefault(import('../src/pages/_/subscribe.vue' /* webpackChunkName: "pages/_/subscribe" */))
const _921f6658 = () => interopDefault(import('../src/pages/_/appointment/cancel.vue' /* webpackChunkName: "pages/_/appointment/cancel" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/academy",
    component: _78019ca5,
    name: "academy___fr___default"
  }, {
    path: "/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___fr___default"
  }, {
    path: "/ar",
    component: _5b6a23f0,
    name: "index___ar"
  }, {
    path: "/cgu",
    component: _5928c472,
    name: "cgu___fr___default"
  }, {
    path: "/confidentiality",
    component: _044ec048,
    name: "confidentiality___fr___default"
  }, {
    path: "/dashboard",
    component: _a1ddf266,
    name: "dashboard___fr___default"
  }, {
    path: "/de",
    component: _5b6a23f0,
    name: "index___de"
  }, {
    path: "/en",
    component: _5b6a23f0,
    name: "index___en"
  }, {
    path: "/es",
    component: _5b6a23f0,
    name: "index___es"
  }, {
    path: "/faq",
    component: _0612db4f,
    name: "faq___fr___default"
  }, {
    path: "/forbidden",
    component: _bc1112a2,
    name: "forbidden___fr___default"
  }, {
    path: "/fr",
    component: _5b6a23f0,
    name: "index___fr"
  }, {
    path: "/it",
    component: _5b6a23f0,
    name: "index___it"
  }, {
    path: "/legals",
    component: _70c35294,
    name: "legals___fr___default"
  }, {
    path: "/login",
    component: _c81addc2,
    name: "login___fr___default"
  }, {
    path: "/login-totp",
    component: _2d53ca55,
    name: "login-totp___fr___default"
  }, {
    path: "/nl",
    component: _5b6a23f0,
    name: "index___nl"
  }, {
    path: "/priserendezvous",
    component: _2a8cc9bf,
    name: "appointment-schedule___fr___default"
  }, {
    path: "/pt",
    component: _5b6a23f0,
    name: "index___pt"
  }, {
    path: "/ru",
    component: _5b6a23f0,
    name: "index___ru"
  }, {
    path: "/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___fr___default"
  }, {
    path: "/zh",
    component: _5b6a23f0,
    name: "index___zh"
  }, {
    path: "/ar/academy",
    component: _78019ca5,
    name: "academy___ar"
  }, {
    path: "/ar/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___ar"
  }, {
    path: "/ar/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___ar"
  }, {
    path: "/ar/cgu",
    component: _5928c472,
    name: "cgu___ar"
  }, {
    path: "/ar/confidentiality",
    component: _044ec048,
    name: "confidentiality___ar"
  }, {
    path: "/ar/dashboard",
    component: _a1ddf266,
    name: "dashboard___ar"
  }, {
    path: "/ar/faq",
    component: _0612db4f,
    name: "faq___ar"
  }, {
    path: "/ar/forbidden",
    component: _bc1112a2,
    name: "forbidden___ar"
  }, {
    path: "/ar/legals",
    component: _70c35294,
    name: "legals___ar"
  }, {
    path: "/ar/login",
    component: _c81addc2,
    name: "login___ar"
  }, {
    path: "/ar/login-totp",
    component: _2d53ca55,
    name: "login-totp___ar"
  }, {
    path: "/ar/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___ar"
  }, {
    path: "/de/academy",
    component: _78019ca5,
    name: "academy___de"
  }, {
    path: "/de/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___de"
  }, {
    path: "/de/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___de"
  }, {
    path: "/de/cgu",
    component: _5928c472,
    name: "cgu___de"
  }, {
    path: "/de/confidentiality",
    component: _044ec048,
    name: "confidentiality___de"
  }, {
    path: "/de/dashboard",
    component: _a1ddf266,
    name: "dashboard___de"
  }, {
    path: "/de/faq",
    component: _0612db4f,
    name: "faq___de"
  }, {
    path: "/de/forbidden",
    component: _bc1112a2,
    name: "forbidden___de"
  }, {
    path: "/de/legals",
    component: _70c35294,
    name: "legals___de"
  }, {
    path: "/de/login",
    component: _c81addc2,
    name: "login___de"
  }, {
    path: "/de/login-totp",
    component: _2d53ca55,
    name: "login-totp___de"
  }, {
    path: "/de/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___de"
  }, {
    path: "/en/academy",
    component: _78019ca5,
    name: "academy___en"
  }, {
    path: "/en/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___en"
  }, {
    path: "/en/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___en"
  }, {
    path: "/en/cgu",
    component: _5928c472,
    name: "cgu___en"
  }, {
    path: "/en/confidentiality",
    component: _044ec048,
    name: "confidentiality___en"
  }, {
    path: "/en/dashboard",
    component: _a1ddf266,
    name: "dashboard___en"
  }, {
    path: "/en/faq",
    component: _0612db4f,
    name: "faq___en"
  }, {
    path: "/en/forbidden",
    component: _bc1112a2,
    name: "forbidden___en"
  }, {
    path: "/en/legals",
    component: _70c35294,
    name: "legals___en"
  }, {
    path: "/en/login",
    component: _c81addc2,
    name: "login___en"
  }, {
    path: "/en/login-totp",
    component: _2d53ca55,
    name: "login-totp___en"
  }, {
    path: "/en/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___en"
  }, {
    path: "/es/academy",
    component: _78019ca5,
    name: "academy___es"
  }, {
    path: "/es/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___es"
  }, {
    path: "/es/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___es"
  }, {
    path: "/es/cgu",
    component: _5928c472,
    name: "cgu___es"
  }, {
    path: "/es/confidentiality",
    component: _044ec048,
    name: "confidentiality___es"
  }, {
    path: "/es/dashboard",
    component: _a1ddf266,
    name: "dashboard___es"
  }, {
    path: "/es/faq",
    component: _0612db4f,
    name: "faq___es"
  }, {
    path: "/es/forbidden",
    component: _bc1112a2,
    name: "forbidden___es"
  }, {
    path: "/es/legals",
    component: _70c35294,
    name: "legals___es"
  }, {
    path: "/es/login",
    component: _c81addc2,
    name: "login___es"
  }, {
    path: "/es/login-totp",
    component: _2d53ca55,
    name: "login-totp___es"
  }, {
    path: "/es/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___es"
  }, {
    path: "/fr/academy",
    component: _78019ca5,
    name: "academy___fr"
  }, {
    path: "/fr/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___fr"
  }, {
    path: "/fr/cgu",
    component: _5928c472,
    name: "cgu___fr"
  }, {
    path: "/fr/confidentiality",
    component: _044ec048,
    name: "confidentiality___fr"
  }, {
    path: "/fr/dashboard",
    component: _a1ddf266,
    name: "dashboard___fr"
  }, {
    path: "/fr/faq",
    component: _0612db4f,
    name: "faq___fr"
  }, {
    path: "/fr/forbidden",
    component: _bc1112a2,
    name: "forbidden___fr"
  }, {
    path: "/fr/legals",
    component: _70c35294,
    name: "legals___fr"
  }, {
    path: "/fr/login",
    component: _c81addc2,
    name: "login___fr"
  }, {
    path: "/fr/login-totp",
    component: _2d53ca55,
    name: "login-totp___fr"
  }, {
    path: "/fr/priserendezvous",
    component: _2a8cc9bf,
    name: "appointment-schedule___fr"
  }, {
    path: "/fr/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___fr"
  }, {
    path: "/it/academy",
    component: _78019ca5,
    name: "academy___it"
  }, {
    path: "/it/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___it"
  }, {
    path: "/it/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___it"
  }, {
    path: "/it/cgu",
    component: _5928c472,
    name: "cgu___it"
  }, {
    path: "/it/confidentiality",
    component: _044ec048,
    name: "confidentiality___it"
  }, {
    path: "/it/dashboard",
    component: _a1ddf266,
    name: "dashboard___it"
  }, {
    path: "/it/faq",
    component: _0612db4f,
    name: "faq___it"
  }, {
    path: "/it/forbidden",
    component: _bc1112a2,
    name: "forbidden___it"
  }, {
    path: "/it/legals",
    component: _70c35294,
    name: "legals___it"
  }, {
    path: "/it/login",
    component: _c81addc2,
    name: "login___it"
  }, {
    path: "/it/login-totp",
    component: _2d53ca55,
    name: "login-totp___it"
  }, {
    path: "/it/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___it"
  }, {
    path: "/nl/academy",
    component: _78019ca5,
    name: "academy___nl"
  }, {
    path: "/nl/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___nl"
  }, {
    path: "/nl/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___nl"
  }, {
    path: "/nl/cgu",
    component: _5928c472,
    name: "cgu___nl"
  }, {
    path: "/nl/confidentiality",
    component: _044ec048,
    name: "confidentiality___nl"
  }, {
    path: "/nl/dashboard",
    component: _a1ddf266,
    name: "dashboard___nl"
  }, {
    path: "/nl/faq",
    component: _0612db4f,
    name: "faq___nl"
  }, {
    path: "/nl/forbidden",
    component: _bc1112a2,
    name: "forbidden___nl"
  }, {
    path: "/nl/legals",
    component: _70c35294,
    name: "legals___nl"
  }, {
    path: "/nl/login",
    component: _c81addc2,
    name: "login___nl"
  }, {
    path: "/nl/login-totp",
    component: _2d53ca55,
    name: "login-totp___nl"
  }, {
    path: "/nl/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___nl"
  }, {
    path: "/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___fr___default"
  }, {
    path: "/pt/academy",
    component: _78019ca5,
    name: "academy___pt"
  }, {
    path: "/pt/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___pt"
  }, {
    path: "/pt/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___pt"
  }, {
    path: "/pt/cgu",
    component: _5928c472,
    name: "cgu___pt"
  }, {
    path: "/pt/confidentiality",
    component: _044ec048,
    name: "confidentiality___pt"
  }, {
    path: "/pt/dashboard",
    component: _a1ddf266,
    name: "dashboard___pt"
  }, {
    path: "/pt/faq",
    component: _0612db4f,
    name: "faq___pt"
  }, {
    path: "/pt/forbidden",
    component: _bc1112a2,
    name: "forbidden___pt"
  }, {
    path: "/pt/legals",
    component: _70c35294,
    name: "legals___pt"
  }, {
    path: "/pt/login",
    component: _c81addc2,
    name: "login___pt"
  }, {
    path: "/pt/login-totp",
    component: _2d53ca55,
    name: "login-totp___pt"
  }, {
    path: "/pt/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___pt"
  }, {
    path: "/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___fr___default"
  }, {
    path: "/ru/academy",
    component: _78019ca5,
    name: "academy___ru"
  }, {
    path: "/ru/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___ru"
  }, {
    path: "/ru/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___ru"
  }, {
    path: "/ru/cgu",
    component: _5928c472,
    name: "cgu___ru"
  }, {
    path: "/ru/confidentiality",
    component: _044ec048,
    name: "confidentiality___ru"
  }, {
    path: "/ru/dashboard",
    component: _a1ddf266,
    name: "dashboard___ru"
  }, {
    path: "/ru/faq",
    component: _0612db4f,
    name: "faq___ru"
  }, {
    path: "/ru/forbidden",
    component: _bc1112a2,
    name: "forbidden___ru"
  }, {
    path: "/ru/legals",
    component: _70c35294,
    name: "legals___ru"
  }, {
    path: "/ru/login",
    component: _c81addc2,
    name: "login___ru"
  }, {
    path: "/ru/login-totp",
    component: _2d53ca55,
    name: "login-totp___ru"
  }, {
    path: "/ru/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___ru"
  }, {
    path: "/zh/academy",
    component: _78019ca5,
    name: "academy___zh"
  }, {
    path: "/zh/accessibility-certificate",
    component: _045e86ce,
    name: "accessibility-certificate___zh"
  }, {
    path: "/zh/appointment-schedule",
    component: _2a8cc9bf,
    name: "appointment-schedule___zh"
  }, {
    path: "/zh/cgu",
    component: _5928c472,
    name: "cgu___zh"
  }, {
    path: "/zh/confidentiality",
    component: _044ec048,
    name: "confidentiality___zh"
  }, {
    path: "/zh/dashboard",
    component: _a1ddf266,
    name: "dashboard___zh"
  }, {
    path: "/zh/faq",
    component: _0612db4f,
    name: "faq___zh"
  }, {
    path: "/zh/forbidden",
    component: _bc1112a2,
    name: "forbidden___zh"
  }, {
    path: "/zh/legals",
    component: _70c35294,
    name: "legals___zh"
  }, {
    path: "/zh/login",
    component: _c81addc2,
    name: "login___zh"
  }, {
    path: "/zh/login-totp",
    component: _2d53ca55,
    name: "login-totp___zh"
  }, {
    path: "/zh/test-turnstile",
    component: _1b544c03,
    name: "test-turnstile___zh"
  }, {
    path: "/ar/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___ar"
  }, {
    path: "/ar/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___ar"
  }, {
    path: "/de/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___de"
  }, {
    path: "/de/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___de"
  }, {
    path: "/en/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___en"
  }, {
    path: "/en/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___en"
  }, {
    path: "/es/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___es"
  }, {
    path: "/es/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___es"
  }, {
    path: "/fr/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___fr"
  }, {
    path: "/fr/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___fr"
  }, {
    path: "/it/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___it"
  }, {
    path: "/it/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___it"
  }, {
    path: "/nl/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___nl"
  }, {
    path: "/nl/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___nl"
  }, {
    path: "/pt/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___pt"
  }, {
    path: "/pt/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___pt"
  }, {
    path: "/ru/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___ru"
  }, {
    path: "/ru/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___ru"
  }, {
    path: "/zh/notifications/unsubscribe",
    component: _07305e60,
    name: "notifications-unsubscribe___zh"
  }, {
    path: "/zh/rating/ttc",
    component: _70f993ab,
    name: "rating-ttc___zh"
  }, {
    path: "/ar/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___ar"
  }, {
    path: "/de/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___de"
  }, {
    path: "/en/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___en"
  }, {
    path: "/es/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___es"
  }, {
    path: "/fr/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___fr"
  }, {
    path: "/it/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___it"
  }, {
    path: "/nl/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___nl"
  }, {
    path: "/pt/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___pt"
  }, {
    path: "/ru/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___ru"
  }, {
    path: "/zh/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___zh"
  }, {
    path: "/ar/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___ar"
  }, {
    path: "/de/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___de"
  }, {
    path: "/en/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___en"
  }, {
    path: "/es/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___es"
  }, {
    path: "/fr/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___fr"
  }, {
    path: "/it/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___it"
  }, {
    path: "/nl/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___nl"
  }, {
    path: "/pt/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___pt"
  }, {
    path: "/ru/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___ru"
  }, {
    path: "/zh/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___zh"
  }, {
    path: "/ar/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___ar"
  }, {
    path: "/de/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___de"
  }, {
    path: "/en/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___en"
  }, {
    path: "/es/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___es"
  }, {
    path: "/faq/pro/:type",
    component: _e3cc9192,
    name: "faq-pro-type___fr___default"
  }, {
    path: "/fr/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___fr"
  }, {
    path: "/it/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___it"
  }, {
    path: "/nl/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___nl"
  }, {
    path: "/pt/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___pt"
  }, {
    path: "/ru/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___ru"
  }, {
    path: "/zh/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___zh"
  }, {
    path: "/ar/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___ar"
  }, {
    path: "/ar/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___ar"
  }, {
    path: "/ar/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___ar"
  }, {
    path: "/ar/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___ar"
  }, {
    path: "/ar/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___ar"
  }, {
    path: "/ar/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___ar"
  }, {
    path: "/de/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___de"
  }, {
    path: "/de/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___de"
  }, {
    path: "/de/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___de"
  }, {
    path: "/de/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___de"
  }, {
    path: "/de/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___de"
  }, {
    path: "/de/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___de"
  }, {
    path: "/en/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___en"
  }, {
    path: "/en/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___en"
  }, {
    path: "/en/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___en"
  }, {
    path: "/en/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___en"
  }, {
    path: "/en/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___en"
  }, {
    path: "/en/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___en"
  }, {
    path: "/es/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___es"
  }, {
    path: "/es/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___es"
  }, {
    path: "/es/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___es"
  }, {
    path: "/es/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___es"
  }, {
    path: "/es/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___es"
  }, {
    path: "/es/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___es"
  }, {
    path: "/fr/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___fr"
  }, {
    path: "/fr/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___fr"
  }, {
    path: "/fr/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___fr"
  }, {
    path: "/fr/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___fr"
  }, {
    path: "/fr/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___fr"
  }, {
    path: "/fr/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___fr"
  }, {
    path: "/it/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___it"
  }, {
    path: "/it/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___it"
  }, {
    path: "/it/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___it"
  }, {
    path: "/it/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___it"
  }, {
    path: "/it/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___it"
  }, {
    path: "/it/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___it"
  }, {
    path: "/nl/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___nl"
  }, {
    path: "/nl/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___nl"
  }, {
    path: "/nl/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___nl"
  }, {
    path: "/nl/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___nl"
  }, {
    path: "/nl/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___nl"
  }, {
    path: "/nl/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___nl"
  }, {
    path: "/pt/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___pt"
  }, {
    path: "/pt/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___pt"
  }, {
    path: "/pt/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___pt"
  }, {
    path: "/pt/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___pt"
  }, {
    path: "/pt/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___pt"
  }, {
    path: "/pt/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___pt"
  }, {
    path: "/ru/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___ru"
  }, {
    path: "/ru/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___ru"
  }, {
    path: "/ru/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___ru"
  }, {
    path: "/ru/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___ru"
  }, {
    path: "/ru/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___ru"
  }, {
    path: "/ru/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___ru"
  }, {
    path: "/zh/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___zh"
  }, {
    path: "/zh/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___zh"
  }, {
    path: "/zh/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___zh"
  }, {
    path: "/zh/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___zh"
  }, {
    path: "/zh/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___zh"
  }, {
    path: "/zh/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___zh"
  }, {
    path: "/ar/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___ar"
  }, {
    path: "/ar/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___ar"
  }, {
    path: "/ar/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___ar"
  }, {
    path: "/ar/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___ar"
  }, {
    path: "/ar/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___ar"
  }, {
    path: "/ar/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___ar"
  }, {
    path: "/ar/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___ar"
  }, {
    path: "/ar/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___ar"
  }, {
    path: "/ar/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___ar"
  }, {
    path: "/de/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___de"
  }, {
    path: "/de/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___de"
  }, {
    path: "/de/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___de"
  }, {
    path: "/de/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___de"
  }, {
    path: "/de/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___de"
  }, {
    path: "/de/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___de"
  }, {
    path: "/de/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___de"
  }, {
    path: "/de/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___de"
  }, {
    path: "/de/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___de"
  }, {
    path: "/en/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___en"
  }, {
    path: "/en/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___en"
  }, {
    path: "/en/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___en"
  }, {
    path: "/en/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___en"
  }, {
    path: "/en/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___en"
  }, {
    path: "/en/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___en"
  }, {
    path: "/en/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___en"
  }, {
    path: "/en/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___en"
  }, {
    path: "/en/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___en"
  }, {
    path: "/es/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___es"
  }, {
    path: "/es/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___es"
  }, {
    path: "/es/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___es"
  }, {
    path: "/es/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___es"
  }, {
    path: "/es/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___es"
  }, {
    path: "/es/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___es"
  }, {
    path: "/es/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___es"
  }, {
    path: "/es/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___es"
  }, {
    path: "/es/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___es"
  }, {
    path: "/fr/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___fr"
  }, {
    path: "/fr/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___fr"
  }, {
    path: "/fr/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___fr"
  }, {
    path: "/fr/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___fr"
  }, {
    path: "/fr/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___fr"
  }, {
    path: "/fr/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___fr"
  }, {
    path: "/fr/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___fr"
  }, {
    path: "/fr/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___fr"
  }, {
    path: "/fr/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___fr"
  }, {
    path: "/it/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___it"
  }, {
    path: "/it/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___it"
  }, {
    path: "/it/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___it"
  }, {
    path: "/it/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___it"
  }, {
    path: "/it/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___it"
  }, {
    path: "/it/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___it"
  }, {
    path: "/it/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___it"
  }, {
    path: "/it/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___it"
  }, {
    path: "/it/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___it"
  }, {
    path: "/nl/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___nl"
  }, {
    path: "/nl/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___nl"
  }, {
    path: "/nl/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___nl"
  }, {
    path: "/nl/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___nl"
  }, {
    path: "/nl/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___nl"
  }, {
    path: "/nl/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___nl"
  }, {
    path: "/nl/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___nl"
  }, {
    path: "/nl/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___nl"
  }, {
    path: "/nl/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___nl"
  }, {
    path: "/pt/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___pt"
  }, {
    path: "/pt/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___pt"
  }, {
    path: "/pt/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___pt"
  }, {
    path: "/pt/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___pt"
  }, {
    path: "/pt/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___pt"
  }, {
    path: "/pt/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___pt"
  }, {
    path: "/pt/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___pt"
  }, {
    path: "/pt/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___pt"
  }, {
    path: "/pt/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___pt"
  }, {
    path: "/ru/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___ru"
  }, {
    path: "/ru/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___ru"
  }, {
    path: "/ru/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___ru"
  }, {
    path: "/ru/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___ru"
  }, {
    path: "/ru/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___ru"
  }, {
    path: "/ru/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___ru"
  }, {
    path: "/ru/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___ru"
  }, {
    path: "/ru/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___ru"
  }, {
    path: "/ru/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___ru"
  }, {
    path: "/zh/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___zh"
  }, {
    path: "/zh/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___zh"
  }, {
    path: "/zh/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___zh"
  }, {
    path: "/zh/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___zh"
  }, {
    path: "/zh/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___zh"
  }, {
    path: "/zh/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___zh"
  }, {
    path: "/zh/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___zh"
  }, {
    path: "/zh/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___zh"
  }, {
    path: "/zh/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___zh"
  }, {
    path: "/ar/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___ar"
  }, {
    path: "/ar/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___ar"
  }, {
    path: "/ar/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___ar"
  }, {
    path: "/ar/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___ar"
  }, {
    path: "/ar/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___ar"
  }, {
    path: "/de/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___de"
  }, {
    path: "/de/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___de"
  }, {
    path: "/de/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___de"
  }, {
    path: "/de/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___de"
  }, {
    path: "/de/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___de"
  }, {
    path: "/en/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___en"
  }, {
    path: "/en/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___en"
  }, {
    path: "/en/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___en"
  }, {
    path: "/en/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___en"
  }, {
    path: "/en/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___en"
  }, {
    path: "/es/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___es"
  }, {
    path: "/es/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___es"
  }, {
    path: "/es/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___es"
  }, {
    path: "/es/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___es"
  }, {
    path: "/es/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___es"
  }, {
    path: "/fr/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___fr"
  }, {
    path: "/fr/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___fr"
  }, {
    path: "/fr/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___fr"
  }, {
    path: "/fr/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___fr"
  }, {
    path: "/fr/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___fr"
  }, {
    path: "/it/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___it"
  }, {
    path: "/it/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___it"
  }, {
    path: "/it/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___it"
  }, {
    path: "/it/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___it"
  }, {
    path: "/it/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___it"
  }, {
    path: "/nl/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___nl"
  }, {
    path: "/nl/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___nl"
  }, {
    path: "/nl/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___nl"
  }, {
    path: "/nl/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___nl"
  }, {
    path: "/nl/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___nl"
  }, {
    path: "/pt/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___pt"
  }, {
    path: "/pt/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___pt"
  }, {
    path: "/pt/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___pt"
  }, {
    path: "/pt/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___pt"
  }, {
    path: "/pt/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___pt"
  }, {
    path: "/ru/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___ru"
  }, {
    path: "/ru/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___ru"
  }, {
    path: "/ru/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___ru"
  }, {
    path: "/ru/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___ru"
  }, {
    path: "/ru/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___ru"
  }, {
    path: "/zh/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___zh"
  }, {
    path: "/zh/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___zh"
  }, {
    path: "/zh/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___zh"
  }, {
    path: "/zh/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___zh"
  }, {
    path: "/zh/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___zh"
  }, {
    path: "/ar/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___ar"
  }, {
    path: "/ar/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___ar"
  }, {
    path: "/de/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___de"
  }, {
    path: "/de/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___de"
  }, {
    path: "/en/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___en"
  }, {
    path: "/en/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___en"
  }, {
    path: "/es/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___es"
  }, {
    path: "/es/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___es"
  }, {
    path: "/fr/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___fr"
  }, {
    path: "/fr/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___fr"
  }, {
    path: "/it/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___it"
  }, {
    path: "/it/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___it"
  }, {
    path: "/nl/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___nl"
  }, {
    path: "/nl/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___nl"
  }, {
    path: "/pt/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___pt"
  }, {
    path: "/pt/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___pt"
  }, {
    path: "/ru/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___ru"
  }, {
    path: "/ru/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___ru"
  }, {
    path: "/zh/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___zh"
  }, {
    path: "/zh/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___zh"
  }, {
    path: "/ar/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___ar"
  }, {
    path: "/de/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___de"
  }, {
    path: "/en/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___en"
  }, {
    path: "/es/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___es"
  }, {
    path: "/fr/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___fr"
  }, {
    path: "/it/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___it"
  }, {
    path: "/nl/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___nl"
  }, {
    path: "/pt/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___pt"
  }, {
    path: "/ru/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___ru"
  }, {
    path: "/zh/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___zh"
  }, {
    path: "/ar/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___ar"
  }, {
    path: "/ar/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___ar"
  }, {
    path: "/de/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___de"
  }, {
    path: "/de/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___de"
  }, {
    path: "/en/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___en"
  }, {
    path: "/en/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___en"
  }, {
    path: "/es/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___es"
  }, {
    path: "/es/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___es"
  }, {
    path: "/fr/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___fr"
  }, {
    path: "/fr/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___fr"
  }, {
    path: "/it/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___it"
  }, {
    path: "/it/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___it"
  }, {
    path: "/nl/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___nl"
  }, {
    path: "/nl/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___nl"
  }, {
    path: "/pt/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___pt"
  }, {
    path: "/pt/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___pt"
  }, {
    path: "/ru/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___ru"
  }, {
    path: "/ru/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___ru"
  }, {
    path: "/zh/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___zh"
  }, {
    path: "/zh/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___zh"
  }, {
    path: "/ar/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___ar"
  }, {
    path: "/de/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___de"
  }, {
    path: "/en/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___en"
  }, {
    path: "/es/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___es"
  }, {
    path: "/fr/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___fr"
  }, {
    path: "/it/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___it"
  }, {
    path: "/nl/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___nl"
  }, {
    path: "/pt/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___pt"
  }, {
    path: "/ru/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___ru"
  }, {
    path: "/zh/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___zh"
  }, {
    path: "/ar/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___ar"
  }, {
    path: "/ar/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___ar"
  }, {
    path: "/de/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___de"
  }, {
    path: "/de/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___de"
  }, {
    path: "/en/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___en"
  }, {
    path: "/en/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___en"
  }, {
    path: "/es/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___es"
  }, {
    path: "/es/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___es"
  }, {
    path: "/fr/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___fr"
  }, {
    path: "/fr/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___fr"
  }, {
    path: "/it/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___it"
  }, {
    path: "/it/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___it"
  }, {
    path: "/nl/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___nl"
  }, {
    path: "/nl/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___nl"
  }, {
    path: "/pt/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___pt"
  }, {
    path: "/pt/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___pt"
  }, {
    path: "/ru/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___ru"
  }, {
    path: "/ru/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___ru"
  }, {
    path: "/zh/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___zh"
  }, {
    path: "/zh/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___zh"
  }, {
    path: "/ar/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___ar"
  }, {
    path: "/de/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___de"
  }, {
    path: "/en/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___en"
  }, {
    path: "/es/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___es"
  }, {
    path: "/faq/pro/:type?/:content",
    component: _1b3403a8,
    name: "faq-pro-type-content___fr___default"
  }, {
    path: "/fr/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___fr"
  }, {
    path: "/it/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___it"
  }, {
    path: "/nl/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___nl"
  }, {
    path: "/pt/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___pt"
  }, {
    path: "/ru/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___ru"
  }, {
    path: "/zh/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___zh"
  }, {
    path: "/nl/academy/*",
    component: _57261435,
    name: "academy-all___nl"
  }, {
    path: "/pt/academy/*",
    component: _57261435,
    name: "academy-all___pt"
  }, {
    path: "/de/academy/*",
    component: _57261435,
    name: "academy-all___de"
  }, {
    path: "/es/academy/*",
    component: _57261435,
    name: "academy-all___es"
  }, {
    path: "/it/academy/*",
    component: _57261435,
    name: "academy-all___it"
  }, {
    path: "/ru/academy/*",
    component: _57261435,
    name: "academy-all___ru"
  }, {
    path: "/zh/academy/*",
    component: _57261435,
    name: "academy-all___zh"
  }, {
    path: "/ar/academy/*",
    component: _57261435,
    name: "academy-all___ar"
  }, {
    path: "/fr/academy/*",
    component: _57261435,
    name: "academy-all___fr"
  }, {
    path: "/en/academy/*",
    component: _57261435,
    name: "academy-all___en"
  }, {
    path: "/dashboard/:team",
    component: _6667d57a,
    name: "dashboard-team___fr___default"
  }, {
    path: "/dashboard/:team/addteam",
    component: _8d7015b4,
    name: "dashboard-team-addteam___fr___default"
  }, {
    path: "/dashboard/:team/members",
    component: _c4f6eff8,
    name: "dashboard-team-members___fr___default"
  }, {
    path: "/dashboard/:team/messages",
    component: _9e443e58,
    name: "dashboard-team-messages___fr___default"
  }, {
    path: "/dashboard/:team/reservations",
    component: _f136c11c,
    name: "dashboard-team-reservations___fr___default"
  }, {
    path: "/dashboard/:team/stats",
    component: _2325d80a,
    name: "dashboard-team-stats___fr___default"
  }, {
    path: "/dashboard/:team/user",
    component: _67da8816,
    name: "dashboard-team-user___fr___default"
  }, {
    path: "/dashboard/:team/members/add",
    component: _59be125a,
    name: "dashboard-team-members-add___fr___default"
  }, {
    path: "/dashboard/:team/members/addTeam",
    component: _c046dd60,
    name: "dashboard-team-members-addTeam___fr___default"
  }, {
    path: "/dashboard/:team/members/import",
    component: _67d11963,
    name: "dashboard-team-members-import___fr___default"
  }, {
    path: "/dashboard/:team/members/main",
    component: _46729a12,
    name: "dashboard-team-members-main___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings",
    component: _2064b910,
    name: "dashboard-team-pro-settings___fr___default"
  }, {
    path: "/dashboard/:team/reservations/message-history",
    component: _432d8124,
    name: "dashboard-team-reservations-message-history___fr___default"
  }, {
    path: "/dashboard/:team/reservations/payment-list",
    component: _32db6615,
    name: "dashboard-team-reservations-payment-list___fr___default"
  }, {
    path: "/dashboard/:team/stats/reservations",
    component: _6ca2339f,
    name: "dashboard-team-stats-reservations___fr___default"
  }, {
    path: "/dashboard/:team/user/settings",
    component: _c2595fe2,
    name: "dashboard-team-user-settings___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/forms",
    component: _851fb426,
    name: "dashboard-team-pro-settings-forms___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/my-messages",
    component: _1cf45e8b,
    name: "dashboard-team-pro-settings-my-messages___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/profile",
    component: _d8da7632,
    name: "dashboard-team-pro-settings-profile___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/profile-location",
    component: _0e1fab0b,
    name: "dashboard-team-pro-settings-profile-location___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/reservations-shop",
    component: _418ff7c6,
    name: "dashboard-team-pro-settings-reservations-shop___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/reservations-shop/create-shop",
    component: _48d6209c,
    name: "dashboard-team-pro-settings-reservations-shop-create-shop___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/reservations-shop/import",
    component: _5c8d206a,
    name: "dashboard-team-pro-settings-reservations-shop-import___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/reservations-shop/:setting",
    component: _1834dace,
    name: "dashboard-team-pro-settings-reservations-shop-setting___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/reservations-shop/:setting?/custom",
    component: _009db33a,
    name: "dashboard-team-pro-settings-reservations-shop-setting-custom___fr___default"
  }, {
    path: "/dashboard/:team/pro/settings/reservations-shop/:setting?/history",
    component: _d6b164ca,
    name: "dashboard-team-pro-settings-reservations-shop-setting-history___fr___default"
  }, {
    path: "/dashboard/:team/user/reservation/:id?",
    component: _69f1eabb,
    name: "dashboard-team-user-reservation-id___fr___default"
  }, {
    path: "/dashboard/:team/documents/:id?",
    component: _0f05b20b,
    name: "dashboard-team-documents-id___fr___default"
  }, {
    path: "/dashboard/:team/members/:id",
    component: _71dcbb6c,
    name: "dashboard-team-members-id___fr___default"
  }, {
    path: "/faq/:categorySlug/:summarySlug?",
    component: _7305f40c,
    name: "faq-categorySlug-summarySlug___fr___default"
  }, {
    path: "/ar/*/appointment",
    component: _5bda6d30,
    name: "all-appointment___ar"
  }, {
    path: "/ar/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___ar"
  }, {
    path: "/de/*/appointment",
    component: _5bda6d30,
    name: "all-appointment___de"
  }, {
    path: "/de/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___de"
  }, {
    path: "/en/*/appointment",
    component: _5bda6d30,
    name: "all-appointment___en"
  }, {
    path: "/en/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___en"
  }, {
    path: "/es/*/appointment",
    component: _5bda6d30,
    name: "all-appointment___es"
  }, {
    path: "/es/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___es"
  }, {
    path: "/fr/*/rendez-vous",
    component: _5bda6d30,
    name: "all-appointment___fr"
  }, {
    path: "/fr/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___fr"
  }, {
    path: "/it/*/appointment",
    component: _5bda6d30,
    name: "all-appointment___it"
  }, {
    path: "/it/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___it"
  }, {
    path: "/nl/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___nl"
  }, {
    path: "/nl/*/winkelafspraak",
    component: _5bda6d30,
    name: "all-appointment___nl"
  }, {
    path: "/pt/*/appointment",
    component: _5bda6d30,
    name: "all-appointment___pt"
  }, {
    path: "/pt/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___pt"
  }, {
    path: "/ru/*/appointment",
    component: _5bda6d30,
    name: "all-appointment___ru"
  }, {
    path: "/ru/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___ru"
  }, {
    path: "/zh/*/appointment",
    component: _5bda6d30,
    name: "all-appointment___zh"
  }, {
    path: "/zh/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___zh"
  }, {
    path: "/ar/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___ar"
  }, {
    path: "/de/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___de"
  }, {
    path: "/en/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___en"
  }, {
    path: "/es/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___es"
  }, {
    path: "/fr/*/rendez-vous/annulation",
    component: _921f6658,
    name: "all-appointment-cancel___fr"
  }, {
    path: "/it/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___it"
  }, {
    path: "/nl/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___nl"
  }, {
    path: "/pt/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___pt"
  }, {
    path: "/ru/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___ru"
  }, {
    path: "/zh/*/appointment/cancel",
    component: _921f6658,
    name: "all-appointment-cancel___zh"
  }, {
    path: "/academy/*",
    component: _57261435,
    name: "academy-all___fr___default"
  }, {
    path: "/",
    component: _5b6a23f0,
    name: "index___fr___default"
  }, {
    path: "/*/rendez-vous",
    component: _5bda6d30,
    name: "all-appointment___fr___default"
  }, {
    path: "/*/subscribe",
    component: _54cd2b60,
    name: "all-subscribe___fr___default"
  }, {
    path: "/*/rendez-vous/annulation",
    component: _921f6658,
    name: "all-appointment-cancel___fr___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
