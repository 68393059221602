<template>
  <div class="account-pages my-5 pt-5 100-vh">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="h2 text-primary font-weight-bold text-uppercase">
              {{ $t('error.session_ended') }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-7 py-md-5 gouv">
          <p class="mt-5 text-center lead">
            {{ $t('common.reloadPageAfter') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IsGouvStyleMixin from '~/components/dynamics/is-gouv-style.mixin';

export default {
  name: 'Error403',
  mixins: [IsGouvStyleMixin],
  props: {
    error: {
      type: Object,
      default: null
    },
    debug: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped>
.account-pages {
  min-height: 65vh;
}
.st0 {
  display: none;
}
.st1 {
  display: inline;
  fill: #000091;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}
.st2 {
  display: inline;
  fill: #ffffff;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}
.st3 {
  display: inline;
  fill: none;
  stroke: #0f2b42;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st4 {
  fill: #e3e3fd;
}
.st5 {
  display: none;
  fill: #ffffff;
}
.st6 {
  display: inline;
  opacity: 0.3;
}
.st7 {
  fill: #ffffff;
}
.st8 {
  display: inline;
  fill: #0f2b42;
}
.st9 {
  display: none;
  fill: none;
  stroke: #0f2b42;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st10 {
  display: inline;
  fill: none;
  stroke: #ffffff;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st11 {
  display: inline;
}

.st12 {
  display: inline;
  fill: #ffffff;
  stroke: #0f2b42;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st13 {
  display: inline;
  fill: none;
  stroke: #0f2b42;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st14 {
  fill: #000091;
}
.st15 {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st17 {
  fill: #2ab19f;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st18 {
  display: inline;
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 0.25;
  stroke-miterlimit: 10;
}
.st19 {
  display: inline;
  fill: none;
  stroke: #ffffff;
  stroke-width: 3;
  stroke-miterlimit: 10;
}
.btn-primary:hover {
  background-color: #1212ff !important;
}
</style>
