import deepmerge from 'deepmerge';
import { isDeployed } from '~/helpers';

const Cookie = process.client ? require('js-cookie') : undefined;

export default {
  SET_USER(state, user) {
    state.user = user;
    state.members = user.members;
    state.member = user.member;
    state.currentTeam = user.member && user.member.team;
    state.team = user.member && user.member.team;
    state.teamId = user.member && user.member.team._id;
    state.role = user.member && user.member.role;

    if (user.members && user.members.length > 0) {
      state.teams = user.members.map((m) => m.team);
    } else {
      state.teams = [];
    }
  },
  UPDATE_USER(state, user) {
    state.user = deepmerge(state.user, user);
  },
  SET_TRIPPERTY(state, tripperty) {
    state.user.tripperty = tripperty;
  },
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },
  SET_TOKEN(state, token) {
    state.token = token;
    if (process.client) {
      if (!token) {
        Cookie.remove('_gouv_c_tkn');
      } else {
        Cookie.set('_gouv_c_tkn', token, {
          expires: 3, // after 3 day for disney
          secure: isDeployed() // May have som side effect be cause it use HTTPS !⚠️
        });
      }
    }
  },
  SET_REFRESH_TOKEN(state, token) {
    state.refreshToken = token;
    if (process.client) {
      if (!token) {
        window.localStorage.removeItem('_t_rfrsh_tkn', token);
      } else {
        window.localStorage.setItem('_t_rfrsh_tkn', token);
      }
    }
  },
  SET_MEMBER(state, member) {
    state.member = member;
  },
  SET_MEMBERS_LIST(state, values) {
    state.membersList = values || [];
  },
  SET_TEAM(state, team) {
    state.team = team;
  },
  SET_TEAM_ID(state, teamId) {
    state.teamId = teamId;
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_ROLE(state, role) {
    state.role = role;
  },
  SET_PRICINGS(state, pricings) {
    state.pricings = pricings;
  },
  SET_TEAM_FIELDS(state, teamFields) {
    state.teamFields = teamFields;
  },
  SET_CURRENT_MEMBER(state, currentMember) {
    state.currentMember = currentMember;
  },
  SET_TEAM_TYPES(state, teamTypes) {
    state.teamTypes = [...teamTypes];
  },
  SET_MATRICULE(state, matricule) {
    state.selectedMatricule = matricule;
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('matricule', matricule);
    }
  },
  SET_MATRICULE_LIST(state, matricules) {
    state.member.matricule_number = matricules;
    state.matricules = matricules;
  },
  SET_TEAM_PARAMS(state, teamParams) {
    state.teamParams = { ...teamParams };
  },
  UPDATE_TEAM_PARAMS(state, teamParams) {
    state.teamParams = deepmerge(state.teamParams, teamParams);
  },
  SET_CURRENT_TEAM(state, currentTeam) {
    state.currentTeam = currentTeam;
  },
  SET_CURRENT_PARAMS(state, currentParams) {
    state.currentParams = currentParams;
  },
  CLEAN_CURRENT_PARAMS(state) {
    state.currentParams = [];
  },
  UPDATE_TEAM(state, team) {
    state.team = deepmerge(state.team, team);
    state.currentTeam = deepmerge(state.currentTeam, team);
  },
  UPDATE_MAIN_TEAM(state, mainTeam) {
    for (let i = 0; i < state.members.length; ++i) {
      if (state.members[i].team._id === mainTeam) {
        state.members[i].main_team = true;
      } else {
        state.members[i].main_team = false;
      }
    }
  },
  SET_GOOGLE_MAPS_LINK(state, teamGoogleMapsLink) {
    state.teamGoogleMapsLink = teamGoogleMapsLink;
  },
  SET_CAPTCHA(state, { uui, value }) {
    state.captcha = { uui, value };
  },
  SET_HANDSHAKE(state, { value }) {
    state.cs.value = value;
  },
  SET_APP_ID(state, appid) {
    state['x-gouv-app-id'] = appid;
  }
};
