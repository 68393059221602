export default (app, inject) => {
  const taostTypes = function (message, type, options) {
    return this.$toast[type](message, {
      position: 'bottom-right',
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      ...options
    });
  };
  inject('toastError', function (message, options) {
    return taostTypes.call(this, message, 'error', options);
  });
  inject('toastWarning', function (message, options) {
    return taostTypes.call(this, message, 'warning', options);
  });
  inject('toastSuccess', function (message, options) {
    return taostTypes.call(this, message, 'success', options);
  });
  inject('toastInfo', function (message, options) {
    return taostTypes.call(this, message, 'info', options);
  });
};
