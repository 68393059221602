import { load } from '@fingerprintjs/botd';

function isBot() {
  const botdPromise = load();
  // Get the bot detection result when you need it.
  botdPromise
    .then((botd) => botd.detect())
    .then((result) => {
      if (result.bot) {
        window.$nuxt.$router.push('/error');
      }
    })
    .catch((error) => console.error(error));
}

export default ({ app }, inject) => {
  app.isBot = isBot;
};
