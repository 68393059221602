const Cookie = process.client ? require('js-cookie') : undefined;

export default function ({ store, req, app, route }) {
  if (!store.state.user.token || store.state.user.token === '') {
    const query = route.query || {};
    let token;
    if (query.token) {
      token = query.token;
    } else {
      token = req && req.session && req.session._gouv_c_tkn ? req.session._gouv_c_tkn : '';
    }
    if (process.client && (token === '' || !token)) {
      token = Cookie.get('_gouv_c_tkn');
    }
    if (token && token !== '') {
      app.$axios.setToken(token, 'Bearer');
      store.commit('user/SET_TOKEN', token);
    }
  } else {
    app.$axios.setToken(store.state.user.token, 'Bearer');
  }
}
