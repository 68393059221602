export default () => ({
  user: {},
  team: {},
  teamParams: {},
  teams: [],
  teamTypes: [],
  members: [],
  membersList: [],
  teamId: '',
  token: '',
  refreshToken: '',
  publicTeam: {},
  role: {},
  member: {},
  roles: [],
  currentTeam: {},
  teamGoogleMapsLink: '',
  currentMember: {
    user: {
      email: '',
      firstname: '',
      lastname: ''
    },
    role: {
      name: ''
    }
  },
  teamFields: [],
  matricules: [],
  selectedMatricule: '',
  orders: {},
  currentParams: [],
  countries: [],
  captcha: {
    uui: null,
    value: null
  },
  cs: {
    value: null
  },
  'x-gouv-app-id': null
});
