<template>
  <div class="account-pages my-5 pt-5 100-vh">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <h1 class="h2 text-primary font-weight-bold text-uppercase">
              {{ $t('error.page_not_found_404') }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-7 py-md-5 gouv">
          <div class="mx-md-5 items-troov">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3302.993 1667.997">
              <g id="Calque_3" data-name="Calque 3">
                <path
                  class="error-cls-1"
                  d="M1732.751,1133.633a115.449,115.449,0,0,1-60.05-3.489,115.041,115.041,0,0,1-85.85,29.25,146.729,146.729,0,0,1-228.57,43.71,158.237,158.237,0,1,1-109.12-293.25,118.634,118.634,0,0,1,2.97-60.1l74.01,204.859a43.231,43.231,0,0,0,55.33,25.971l405.73-146.59a116.172,116.172,0,0,1-54.45,199.639Z"
                />
                <path
                  class="error-cls-1"
                  d="M1629.871,416.084a15.994,15.994,0,0,1-18.25-24.621l1.55,4.3a8.794,8.794,0,1,0,16.54-5.979l-1.55-4.29a15.992,15.992,0,0,1,1.71,30.59Z"
                />
                <path
                  class="error-cls-1"
                  d="M2017.6,416.734a116.619,116.619,0,0,1-6.68,13.089,115.079,115.079,0,0,1-5.65,76.841c-22.17,51.12-75.56,78.179-124.11,65.29q-2.76-.721-5.49-1.62l-81-224.19a43.219,43.219,0,0,0-55.33-25.971l-119.22,43.071-11.41-31.58a8.794,8.794,0,1,0-16.54,5.98l11.41,31.579-57.55,20.79-11.41-31.579a8.775,8.775,0,0,0-11.26-5.281,8.626,8.626,0,0,0-3.68,2.54c-.23-.73-.44-1.459-.64-2.189a87.7,87.7,0,0,1-2.64-14.291,83.389,83.389,0,0,1,6.47-41.709c13.09-30.19,42.05-48.84,73.94-51.281.57-1.479,1.15-2.949,1.79-4.42,20.48-47.23,70.65-71.94,116.92-59.94,29.64-51.23,91.63-74.02,144.86-50.949,50.14,21.739,75.89,76.989,64.37,130.71a103.3,103.3,0,0,1,38.79,8.5C2018.231,293.843,2042.441,359.473,2017.6,416.734Z"
                />
              </g>
              <g id="Calque_27" data-name="Calque 27">
                <path class="error-cls-2" d="M497.5,779.5l278-374h194v374h64v152h-64v174h-169v-174h-303Zm303,0v-177l-134,177Z" />
                <path class="error-cls-2" d="M2099.97,779.5l278-374h194v374h64v152h-64v174h-169v-174h-303Zm303,0v-177l-134,177Z" />
                <path
                  class="error-cls-2"
                  d="M1460.161,689.314a89.681,89.681,0,0,0-3.8-9.63c-7.48,1.23-16.4-3.821-21.65-12.91a28.146,28.146,0,0,1-3.94-13.761,11.5,11.5,0,0,0-5.511.381c-11.459,3.779-14.419,26.009-6.609,49.64s23.44,39.72,34.9,35.929S1467.971,712.943,1460.161,689.314Zm192-67.5a88.815,88.815,0,0,0-5.22-12.56,9.349,9.349,0,0,1-1,.649c-8.04,4.641-19.67-.449-25.98-11.379a28.867,28.867,0,0,1-3.88-12.161c-10.48,4.881-12.96,26.371-5.43,49.171,7.82,23.63,23.44,39.72,34.9,35.929S1659.971,645.443,1652.161,621.814Z"
                />
                <path
                  class="error-cls-2"
                  d="M1966.981,823.084l-83.95-232.38-7.36-20.37-81-224.19a43.219,43.219,0,0,0-55.33-25.971l-119.22,43.071-11.41-31.58a8.794,8.794,0,1,0-16.54,5.98l11.41,31.579-57.55,20.79-11.41-31.579a8.775,8.775,0,0,0-11.26-5.281,8.767,8.767,0,0,0-5.09,4.81,8.716,8.716,0,0,0-.19,6.44l2.84,7.851,4.839,13.41,3.731,10.33-57.55,20.79-11.41-31.58a8.792,8.792,0,0,0-16.54,5.969l11.41,31.581-57.16,20.649-11.41-31.58a8.793,8.793,0,0,0-8.26-5.81,8.7,8.7,0,0,0-2.99.531,8.789,8.789,0,0,0-5.28,11.259l11.4,31.58-57.95,20.94-11.41-31.579a8.8,8.8,0,0,0-8.28-5.81,8.642,8.642,0,0,0-2.98.52,8.79,8.79,0,0,0-5.28,11.259l11.41,31.581-127.43,46.04a43.226,43.226,0,0,0-25.95,55.339l98.3,272.081,74.01,204.859a43.231,43.231,0,0,0,55.33,25.971l405.73-146.59,153.83-55.58A43.209,43.209,0,0,0,1966.981,823.084Zm-633.49-299.92a16,16,0,0,1-18.25-24.63l1.56,4.3a8.792,8.792,0,0,0,16.54-5.971l-1.55-4.3a16,16,0,0,1,1.7,30.6Zm142.75-68.58a8.8,8.8,0,0,0,5.29-11.261l-1.55-4.289a15.982,15.982,0,1,1-16.55,5.96l1.56,4.3A8.79,8.79,0,0,0,1476.241,454.584Zm-88.74,32.049a16.007,16.007,0,0,1,2.23-15.02l1.56,4.31a8.794,8.794,0,1,0,16.54-5.98l-1.55-4.29a16,16,0,1,1-18.78,20.98Zm91.24,287.73c-23.46,7.761-55.44-25.17-71.43-73.54s-9.94-93.88,13.52-101.63,55.45,25.161,71.44,73.541S1502.211,766.6,1478.741,774.363Zm132.88-382.9,1.55,4.3a8.794,8.794,0,1,0,16.54-5.979l-1.55-4.29a15.994,15.994,0,1,1-16.54,5.969Zm-76.73,41.92a15.968,15.968,0,0,1,2.54-15.41l1.65,4.561a8.789,8.789,0,1,0,16.53-5.981l-1.46-4.04a16,16,0,1,1-19.26,20.87Zm104.64,459.651c-1.3.65-2.63,1.28-3.98,1.889-18.36,8.241-37.44,10.221-53.45,6.741a56.653,56.653,0,0,1-20.32-8.56,41.914,41.914,0,0,1-14.61-17.3,40.676,40.676,0,0,1-3.48-14.049c-1.59-24.2,17.31-50.991,47.88-64.72,36.66-16.481,76.23-7.921,88.38,19.11C1691.641,842.184,1673.631,875.874,1639.531,893.034Zm28.21-187.671c-23.46,7.761-55.44-25.17-71.43-73.54s-9.94-93.88,13.52-101.63,55.44,25.161,71.44,73.541S1691.211,697.6,1667.741,705.363Z"
                />
                <path
                  class="error-cls-3"
                  d="M1492.271,672.734c-15.99-48.38-47.98-81.3-71.44-73.541s-29.52,53.25-13.52,101.63,47.97,81.3,71.43,73.54S1508.26,721.1,1492.271,672.734Zm-38.72,66.229c-11.46,3.791-27.08-12.3-34.9-35.929s-4.85-45.861,6.609-49.64a11.5,11.5,0,0,1,5.511-.381,28.146,28.146,0,0,0,3.94,13.761c5.25,9.089,14.17,14.14,21.65,12.91a89.681,89.681,0,0,1,3.8,9.63C1467.971,712.943,1465.01,735.173,1453.551,738.963Z"
                />
                <path
                  class="error-cls-3"
                  d="M1453.551,738.963c-11.46,3.791-27.08-12.3-34.9-35.929s-4.85-45.861,6.609-49.64a11.5,11.5,0,0,1,5.511-.381,28.146,28.146,0,0,0,3.94,13.761c5.25,9.089,14.17,14.14,21.65,12.91a89.681,89.681,0,0,1,3.8,9.63C1467.971,712.943,1465.01,735.173,1453.551,738.963Z"
                />
                <path
                  class="error-cls-3"
                  d="M1681.271,603.734c-16-48.38-47.98-81.3-71.44-73.541s-29.52,53.25-13.52,101.63,47.97,81.3,71.43,73.54S1697.26,652.1,1681.271,603.734Zm-35.72,67.729c-11.46,3.791-27.08-12.3-34.9-35.929-7.53-22.8-5.05-44.29,5.43-49.171a28.867,28.867,0,0,0,3.88,12.161c6.31,10.93,17.94,16.02,25.98,11.379a9.349,9.349,0,0,0,1-.649,88.815,88.815,0,0,1,5.22,12.56C1659.971,645.443,1657.01,667.673,1645.551,671.463Z"
                />
                <path
                  class="error-cls-3"
                  d="M1645.551,671.463c-11.46,3.791-27.08-12.3-34.9-35.929-7.53-22.8-5.05-44.29,5.43-49.171a28.867,28.867,0,0,0,3.88,12.161c6.31,10.93,17.94,16.02,25.98,11.379a9.349,9.349,0,0,0,1-.649,88.815,88.815,0,0,1,5.22,12.56C1659.971,645.443,1657.01,667.673,1645.551,671.463Z"
                />
                <path
                  class="error-cls-2"
                  d="M1645.551,671.463c-11.46,3.791-27.08-12.3-34.9-35.929-7.53-22.8-5.05-44.29,5.43-49.171a28.867,28.867,0,0,0,3.88,12.161c6.31,10.93,17.94,16.02,25.98,11.379a9.349,9.349,0,0,0,1-.649,88.815,88.815,0,0,1,5.22,12.56C1659.971,645.443,1657.01,667.673,1645.551,671.463Z"
                />
                <path
                  class="error-cls-2"
                  d="M1453.551,738.963c-11.46,3.791-27.08-12.3-34.9-35.929s-4.85-45.861,6.609-49.64a11.5,11.5,0,0,1,5.511-.381,28.146,28.146,0,0,0,3.94,13.761c5.25,9.089,14.17,14.14,21.65,12.91a89.681,89.681,0,0,1,3.8,9.63C1467.971,712.943,1465.01,735.173,1453.551,738.963Z"
                />
                <path
                  class="error-cls-1"
                  d="M2819.991,1429.527c0,31.58-349.36,58.41-835.45,68.11-65.48,1.31-133.44,2.31-203.36,2.97-64.68.61-131.05.92-198.68.92q-15.27,0-30.45-.02-86.715-.12-170.22-.92c-588.02-5.58-1036.83-35.27-1036.83-71.06,0-36.69,471.73-66.97,1081.58-71.43q76.605-.57,155.92-.57,24,0,47.79.05c77.05.17,152.29.75,225.11,1.71,58.25.76,114.95,1.76,169.77,2.99C2489.981,1372.637,2819.991,1398.837,2819.991,1429.527Z"
                />
                <path
                  class="error-cls-3"
                  d="M1639.531,893.034c-1.3.65-2.63,1.28-3.98,1.889-18.36,8.241-37.44,10.221-53.45,6.741a56.653,56.653,0,0,1-20.32-8.56,41.914,41.914,0,0,1-14.61-17.3,40.676,40.676,0,0,1-3.48-14.049c-1.59-24.2,17.31-50.991,47.88-64.72,36.66-16.481,76.23-7.921,88.38,19.11C1691.641,842.184,1673.631,875.874,1639.531,893.034Z"
                />
                <path
                  class="error-cls-2"
                  d="M1916.071,1129.837c-155.42-109.29-317.23-28.7-438.6,143.9a670.773,670.773,0,0,0-50.89,84.36c-24.36,48.51-39.84,96.58-44.75,142.49q83.37.8,170.22.92c9.93-36.55,32.74-79.64,66.94-128.26q5.67-8.07,11.3-15.67c73.29-99.05,140.84-138.95,196.87-99.56,30.84,21.69,39.49,56.24,28.24,101.27-9.35,37.38-32.41,81.99-67.9,132.46q-3.165,4.5-6.32,8.86c69.92-.66,137.88-1.66,203.36-2.97,22.18-46.12,36.219-91.72,40.63-135.36C2034.281,1272.2,2002.331,1190.487,1916.071,1129.837Z"
                />
              </g>
            </svg>
          </div>
          <p class="text-center">
            {{ $t('error.page_not_found_title') }}
          </p>
          <p v-if="!isGouvStyle" class="text-center my-5">
            {{ $t('error.page_not_found_links') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IsGouvStyleMixin from '~/components/dynamics/is-gouv-style.mixin';

export default {
  name: 'Error404',
  mixins: [IsGouvStyleMixin],
  props: {
    error: {
      type: Object,
      default: null
    },
    debug: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped>
.account-pages {
  min-height: 65vh;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none !important;
  /*text-decoration-style*/
}
</style>
