import environment from './environment';

/**
 * @lang
 * Locales list
 * @type {*[]}
 */
export const locales = [
  { code: 'en', iso: 'en-US', name: 'English', file: 'en.json', dir: 'ltr', isCatchallLocale: true },
  { code: 'fr', iso: 'fr-FR', name: 'Français', file: 'fr.json', dir: 'ltr' },
  { code: 'ar', iso: 'ar-AR', name: 'العربية', file: 'ar.json', dir: 'rtl' },
  { code: 'zh', iso: 'zh-CN', name: '中文', file: 'zh.json', dir: 'ltr' },
  { code: 'ru', iso: 'ru-RU', name: 'Русский', file: 'ru.json', dir: 'ltr' },
  { code: 'it', iso: 'it-IT', name: 'Italiano', file: 'it.json', dir: 'ltr' },
  { code: 'es', iso: 'es-ES', name: 'Español', file: 'es.json', dir: 'ltr' },
  { code: 'de', iso: 'de-DE', name: 'Deutsch', file: 'de.json', dir: 'ltr' },
  { code: 'pt', iso: 'pt-PT', name: 'Português', file: 'pt.json', dir: 'ltr' },
  { code: 'nl', iso: 'nl-NL', name: 'Nederlands', file: 'nl.json', dir: 'ltr' }
];

export const localesList = locales.map((l) => l.code);

export const localesListCode = locales.map(({ code, iso }) => ({ code, iso }));

/**
 * Get default locale
 * @returns {{code, iso, name}}
 */
export const getConfDefaultLocale = () => locales[1];

const i18nConfig = {
  baseUrl: environment().env !== 'development' ? environment().HOST_NUXT : undefined,
  langDir: '~/locales/',
  defaultDirection: 'ltr',
  locales,
  detectBrowserLanguage: false,
  defaultLocale: 'fr',
  strategy: 'prefix_and_default',
  sortRoutes: true,
  lazy: true,
  vuex: {
    moduleName: 'i18n',
    syncRouteParams: true
  },
  vueI18nLoader: true,
  skipNuxtState: true
};
export default i18nConfig;
